// material-ui
import { Grid, TablePagination } from '@mui/material';

// project imports

import SubCard from 'components/cards/SubCard';

import _ from 'lodash';
import { useState, useEffect } from 'react';
import NotificationApi from 'services/api/notificationApi';
import { gridSpacing } from 'store/constant';
import NotificationList from 'core/layout/MainLayout/Header/NotificationSection/NotificationList';
import { navigatePath } from 'utils/utils';
import { IMetaData } from 'types/query/filter';

import EmptyData from 'components/errors/empty-data';
import { useNavigate } from 'react-router-dom';
import { AUTH_LOCAL_STORAGE_KEY, Roles } from 'config';

const NavigatePath = ['Request relief', 'assign relief ticket', 'CM provide option', 'Reject relief', 'update relief status'];
// ==============================|| PROFILE 3 - PROFILE ||============================== //

const ViewAllNotification = () => {
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [pageOptions, setPageOption] = useState({
        page: 1,
        take: 10,
        total: 0
    });
    const [metaData, setMetaData] = useState<IMetaData>({});
    const [noti, setNoti] = useState<any>([]);
    useEffect(() => {
        handleGetNoti();
    }, [pageOptions]);

    const handleGetNoti = async () => {
        const { dataList, error, data } = await NotificationApi.list(pageOptions);
        if (!error) {
            const newData: any = _.orderBy(dataList, (item) => item.createdAt, ['desc']);
            setNoti(newData);
            if (data) setMetaData(data.meta);
        }
    };
    const handleReadNoti = async (item: any) => {
        if (!item.status) {
            NotificationApi.readNotification({
                status: true,
                idNotification: item.id
            });
        }
        // if (NavigatePath.includes(item.urlRef)) navigate('/staff/relief-list', { state: { idRef: item.idRef }, replace: true });
        // else if (item.urlRef === 'Assign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
        // else if (item.urlRef === 'Unassign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
        // else if (item.urlRef === 'Replicate') navigatePath(`/user/my-plan?idClass=${item.idRef}&date=${item.fromDate}`);
        // else if (item.urlRef === 'Add Absence') navigatePath(`/staff/absence-list?idLeave=${item.idRef}`);
        if (
            userRole === Roles.Admin ||
            userRole === Roles.Principals ||
            userRole === Roles.HrAdmin ||
            userRole === Roles.Cluster ||
            userRole === Roles.HrIc
        ) {
            if (NavigatePath.includes(item.urlRef)) navigate('/staff/relief-list', { state: { idRef: item.idRef }, replace: true });
            else if (item.urlRef === 'Assign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
            else if (item.urlRef === 'Unassign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
            else if (item.urlRef === 'Add Absence') navigatePath(`/staff/absence-list?idLeave=${item.idRef}`);
        } else {
            navigatePath(`/user/my-plan?idClass=${item.idRef}&date=${item.fromDate}`);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageOption({ ...pageOptions, page: newPage + 1 });
    };

    // handle read all
    const handleReadAll = async () => {
        const listID = noti?.map((item: any) => item?.id);
        const response = await NotificationApi.readAllNotification({ idsNotification: listID });
        if (response.data === 200) {
            await handleGetNoti();
        }
    };

    return (
        <Grid container spacing={gridSpacing} justifyContent="center">
            <Grid item sm={8} md={6}>
                <SubCard title="All Notification" contentSX={{ textAlign: 'center' }} isReadAll onClickReadAll={handleReadAll}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} spacing={1}>
                            {noti.length ? <NotificationList data={noti} onClickItem={handleReadNoti} isViewAll /> : <EmptyData />}
                        </Grid>
                    </Grid>
                    {noti.length ? (
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={pageOptions.take}
                            page={pageOptions.page - 1}
                            onPageChange={handleChangePage}
                        />
                    ) : (
                        ''
                    )}
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ViewAllNotification;
