import * as React from 'react';

// material-ui
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
    Box,
    Button,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import EmptyData from 'components/errors/empty-data';
import { StyledTableCell } from 'components/Table/TableCustom';
import { DEFAULT_REQUEST, GET_ALL_REQUEST, Order, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi, UserApi } from 'services/api';
import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { UserKechoProfile } from 'types/kecho/user-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { v4 as uuidv4 } from 'uuid';
import ViewDetail from './components/ViewDetail';
// import moment from 'moment';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: true,
        label: 'Username',
        align: 'left'
    },
    {
        id: 'email',
        numeric: true,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'jobTitle',
        numeric: false,
        label: 'Job title',
        align: 'left'
    },
    {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const UserList = () => {
    const classes = useStyles();
    const [selected] = React.useState<string[]>([]);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [userList, setUserList] = React.useState<UserKechoProfile[]>([]);
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    React.useEffect(() => {
        handleGetCentreList();
    }, []);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetUserList();
        }, 800);

        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetUserList = async () => {
        const { dataList, meta } = await UserApi.listUser(filter);
        if (dataList) setUserList(dataList);
        if (meta) setMetaData(meta);
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList) {
            dataList?.unshift({ id: 'All', nameCenter: 'All' });
        }
        setCentreData(dataList);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, page: 1, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {};

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const handleShowDetail = (user: any) => {
        UIManager.showModal({
            content: (onClose: any) => <ViewDetail onClose={onClose} userDetail={user} />,
            stylesModal: {
                minWidth: '500px'
            }
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="User List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                            style={{ marginRight: '8px' }}
                        />
                        <FormControl style={{ width: 'auto', minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-label">Center</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="centreId"
                                label="Center"
                                value={filter.centreId}
                                defaultValue="All"
                                onChange={(e) => {
                                    setFilter({ ...filter, centreId: e.target.value, page: 1 });
                                }}
                                size="small"
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }}
                            >
                                {centreData?.map((centre) => (
                                    <MenuItem key={uuidv4()} value={centre.id}>
                                        {centre.nameCenter}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Create a user" placement="top">
                                <Button variant="contained" color="secondary" size="large" onClick={() => {}}>
                                    <AddIcon sx={{ fontSize: '1.3rem' }} />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                </Grid>
            </CardContent>

            {/* table */}
            {userList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={userList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {userList &&
                                    userList.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.name);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell>{row?.name}</TableCell>
                                                <TableCell>{row?.email}</TableCell>
                                                <TableCell>{row?.jobTitle || 'N/A'}</TableCell>
                                                <TableCell align="left">{row?.role?.roleName}</TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip title="View detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                                                onClick={() => handleShowDetail(row)}
                                                            >
                                                                <VisibilityTwoToneIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                View
                                                            </Button>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <EmptyData />
                        <br />
                        {filter.query !== '' && <div>Sorry we couldn't find any matches for {filter.query}</div>}
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default UserList;
