import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';

export interface IColumnTable<T> {
    field: keyof T | ''; // typeof T field
    headerName: string;
    width?: number;
    isCheckBox?: boolean;
    render?: (row: T) => React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: T) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, row: T) => void;
}

interface ITableProps<T> {
    rows: T[] | undefined;
    // keyTable: keyof T;
    columns: IColumnTable<T>[];
    onRowClick?: (row: T) => void;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#303972',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '&:hover': {
        backgroundColor: '#F0F2FF !important'
    }
}));

const TableCustom = <T,>({ rows, columns, onRowClick }: ITableProps<T>) => {
    return (
        <TableContainer sx={{ borderRadius: '6px' }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <StyledTableCell key={String(col.field)} style={{ minWidth: col.width || 70 }}>
                                {col.headerName}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows &&
                        rows.map((row, rowIndex) => (
                            <StyledTableRow
                                hover
                                key={rowIndex}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (onRowClick) {
                                        onRowClick(row);
                                    }
                                }}
                            >
                                {columns.map((col, colIndex) => (
                                    <StyledTableCell align="left" key={colIndex}>
                                        {col.render ? (
                                            col.render(row)
                                        ) : (
                                            <Typography
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (col.onChange) {
                                                        col.onChange(event, row);
                                                    }
                                                }}
                                                onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
                                                    if (col.onClick) {
                                                        col.onClick(event, row);
                                                    }
                                                }}
                                            >
                                                {col.field && row[col.field]}
                                            </Typography>
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableCustom;
