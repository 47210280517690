import { PromiseApi } from 'types/kecho/api-promise';
import { IInputListAttendance } from 'types/kecho/class-kecho';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'staff-attendance/';

const staffAttendanceApi = {
    listAttendance(params: IInputListAttendance): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    exportAttendance(params: IInputListAttendance): Promise<PromiseApi> {
        const base = `${BaseApi}export-csv`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    }
};
export default staffAttendanceApi;
