import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import SettingTabs from '../staff/SettingTabs';

// const ClusterList = Loadable(lazy(() => import('./ClusterList')));
const ClusterDetail = Loadable(lazy(() => import('./ClusterDetail')));

class ClusterModule {
    constructor() {
        addRoutes([
            {
                path: '/cluster/cluster-list',
                element: (
                    <AuthGuard role={[Roles.Admin]}>
                        <SettingTabs tab="1" />
                        {/* <ClusterList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/cluster/cluster-details/:id',
                element: (
                    <AuthGuard role={[Roles.Admin]}>
                        <ClusterDetail />
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new ClusterModule();
