import centerModule from './main/center';
import accountModule from './main/account';
import staffModule from './main/staff';
import userModule from './main/user';
import classModule from './main/class';
import authModule from './pages/auth';
import dashboardModule from './main/dashboard';
import log from 'services/log';
import clusterModule from './main/cluster';

const loaderModules = () => {
    const modules = [centerModule, accountModule, staffModule, userModule, classModule, clusterModule, authModule, dashboardModule];
    log.info(modules);
};

export default loaderModules;
