// material-ui
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import MainCard from 'components/cards/MainCard';

// project imports
import { GenericCardProps } from 'types';

// ============================|| ROUND ICON CARD ||============================ //

interface Props {
    primary: string;
    secondary: string;
    content: string;
    iconPrimary: GenericCardProps['iconPrimary'];
    color: string;
    bgcolor: string;
    day: string;
    type: string;
}

const CardTotal = ({ primary, secondary, content, iconPrimary, color, bgcolor, type, day }: Props) => {
    const IconPrimary = iconPrimary!;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

    return (
        <MainCard style={{ height: 250 }}>
            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Stack spacing={1}>
                        <Typography variant="h4" color="inherit">
                            {primary}
                        </Typography>
                        <Typography variant="h1">{secondary}</Typography>
                        <Typography variant="subtitle1" color="inherit">
                            % of days ratio is respected
                        </Typography>
                        <Typography variant="subtitle1" color="inherit">
                            {primary === 'Brand' ? `(${type})` : `(${type}) - (ECDA)`}
                        </Typography>
                        <Typography variant="subtitle1" color="inherit">
                            Number of days: {day}
                        </Typography>
                    </Stack>
                </Grid>
                {/* <Grid item>
                    <IconButton sx={{ bgcolor, color, '& .MuiSvgIcon-root': { fontSize: '1.5rem' } }} size="large">
                        {primaryIcon}
                    </IconButton>
                </Grid> */}
            </Grid>
        </MainCard>
    );
};

export default CardTotal;
