// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, Grid, Typography, CardContent } from '@mui/material';

// project imports
import { StaffKecho } from 'types/kecho/staff-kecho';
import { StaffApi } from 'services/api';
import ModalCustom from 'components/Modal/ModalCustom';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    hricEdit?: StaffKecho;
}

const DeleteHricModal = (props: IProps) => {
    const { onClose, callback, hricEdit } = props;

    const handleDeleteHric = async () => {
        if (hricEdit?.id) {
            const { data } = await StaffApi.deleteStaff(hricEdit?.id);
            if (data) callback();
            onClose();
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteHric}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure to delete hric &quot;{hricEdit?.nameStaff}&quot;?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteHricModal;
