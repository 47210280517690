import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';
import { CreateClusterKecho } from 'types/kecho/centre-kecho';

const BaseApi = 'cluster/';

const clusterApi = {
    listCluster(params?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    createCluster(body: CreateClusterKecho): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    getDetailsClusterById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    listStaffOfCentre(id: string, params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}${id}/list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    deleteCluster(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },
    updateCluster(id: string, body: CreateClusterKecho): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    }
};
export default clusterApi;
