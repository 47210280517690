import * as React from 'react';

import { Link, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
// import Chip from 'components/extended/Chip';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

import { EnhancedTableHeadProps, HeadCell, EnhancedTableToolbarProps } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { DEFAULT_REQUEST, Order } from 'config';
import { upperCaseTextName } from 'utils/common/upperCaseText';
import { upperFirst } from 'lodash';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import centreApi from 'services/api/centreApi';
import EmptyData from 'components/errors/empty-data';
import { StyledTableCell } from 'components/Table/TableCustom';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'littleLiveId',
        numeric: false,
        label: 'Little Live ID',
        align: 'center'
    },
    {
        id: 'nameCenter',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'hoursFrom',
        numeric: true,
        label: 'Operating hours (From) ',
        align: 'left'
    },
    {
        id: 'hoursTo',
        numeric: true,
        label: 'Operating hours (To) ',
        align: 'left'
    },
    {
        id: 'ECDA_ID',
        numeric: false,
        label: 'ECDA ID',
        align: 'left'
    },
    {
        id: 'address',
        numeric: false,
        label: 'Address',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx={{ '.MuiTableSortLabel-icon': { opacity: 1 } }}
                                hideSortIcon={false}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const CentreList = () => {
    const theme = useTheme();
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [centre, setCentre] = React.useState<CentreKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const navigate = useNavigate();

    const [selected] = React.useState<string[]>([]);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetDataCentre();
        }, 800);
        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetDataCentre = async () => {
        const { dataList, meta } = await centreApi.listCentre(filter);
        setCentre(dataList || []);
        setMetaData(meta || {});
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        // if (event.target.checked) {
        //     const newSelectedId = rows.map((n) => n.name);
        //     setSelected(newSelectedId);
        //     return;
        // }
        // setSelected([]);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Centre List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {centre.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={centre.length}
                                selected={selected}
                            />
                            <TableBody>
                                {centre &&
                                    centre.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.nameCenter);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    // onClick={(event) => handleClick(event, row?.id)}
                                                    sx={{ cursor: 'pointer' }}
                                                    align="center"
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {' '}
                                                        {row?.littleLiveId}{' '}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{upperCaseTextName(row?.nameCenter)}</TableCell>
                                                <TableCell>{row.hoursFrom}</TableCell>
                                                <TableCell align="left">{row.hoursTo}</TableCell>
                                                <TableCell align="left">{row.ECDA_ID}</TableCell>

                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    {upperFirst(row?.address)}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip title="View detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                                                                onClick={() => {
                                                                    navigate(`/centre/centre-details/${row?.id}`);
                                                                }}
                                                                // href={`/centre/centre-details/${row?.id}`}
                                                            >
                                                                <img
                                                                    src={ViewIcon}
                                                                    alt="view"
                                                                    style={{ width: '22px', height: '22px', marginRight: '5px' }}
                                                                />{' '}
                                                                View
                                                            </Button>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* table pagination */}

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default CentreList;
