import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import StaffPlanningTabs from '../staff/StaffPlanningTabs';

// const ClassList = Loadable(lazy(() => import('./ClassList')));
const ClassDetail = Loadable(lazy(() => import('./ClassDetail')));

class ClassModule {
    constructor() {
        addRoutes([
            {
                path: '/class/class-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <StaffPlanningTabs tab="4" />
                        {/* <ClassList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/class/class-details/:id',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <ClassDetail />
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new ClassModule();
