import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { useRef } from 'react';
import { ClassKecho } from 'types/kecho/class-kecho';
import Detail from '../../../DetailPickerManual';

export interface IInputSaveDetail {
    idRatio?: string;
    idStaff1?: string;
    idStaff2?: string;
    fromHourStaff1?: number;
    toHourStaff1?: number;
    fromHourStaff2?: number;
    toHourStaff2?: number;
}
interface IProps {
    rows: any;
    date?: string;
    centerId?: string;
    handleGetData: () => void;
}

export default function ClassTable({ rows, date, centerId, handleGetData }: IProps) {
    const refDetail = useRef();

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{ maxHeight: 'max-content', overflowY: 'hidden' }}>
                    {rows?.length > 0 &&
                        rows.map((row: ClassKecho) => {
                            const rowStaffIds = row?.staff ? row?.staff.map((item) => item.id).join('') : '';
                            const componentKey = row.idClass + rowStaffIds;
                            return (
                                <Detail
                                    detailClass={row}
                                    ref={refDetail}
                                    key={componentKey}
                                    date={date}
                                    callback={handleGetData}
                                    centreId={centerId}
                                />
                            );
                        })}
                </Box>
            </Paper>
        </>
    );
}
