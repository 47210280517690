import React, { useState, useEffect } from 'react';

// material-ui
import { useTheme, styled, Theme } from '@mui/material/styles';
import { Box, CardContent, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UIManager from 'core/UIManager';
import AskClusterModal from 'modules/main/staff/Relief/components/AskClusterModal';
import Chip from 'components/extended/Chip';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import UserDetails from './UserDetails';
import ChartHistory from './ChartHistory';
import { chatOptions } from 'assets/_mockApis/chat';
import AvatarStatus from './AvatarStatus';
import { openDrawer } from 'store/slices/kecho/core/menu';
import MainCard from 'components/cards/MainCard';
import Avatar from 'components/extended/Avatar';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';
import { UserProfile } from 'types/template/user-profile';
import { useDispatch, useSelector } from 'store';
import { getUser, getUserChats, insertChat } from 'store/slices/template/chat';
import { useSearchParams } from 'react-router-dom';

// assets
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
// types
import { ChatOptions, History as HistoryProps, OptionMessage } from 'types/template/chat';
import { reliefApi } from 'services/api';
import { AUTH_LOCAL_STORAGE_KEY, ReliefStatus, Roles } from 'config';
import { IProfileKecho } from 'types/kecho/profile-kecho';
import userApi from 'services/api/userApi';
import WakyuLogo from 'assets/images/users/wakyu.png';
import AskHrIcModal from 'modules/main/staff/Relief/components/AskHrIcModal';
import AskAssignReliefModal from 'modules/main/staff/Relief/components/AskAssignReliefModal';
import AssignTemporaryReliefModal from 'modules/main/staff/Relief/components/AssignTemporaryReliefModal';
import { navigatePath } from 'utils/utils';

const avatarImage = WakyuLogo;

// drawer content element
const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(
    ({ theme, open }: { theme: Theme; open: boolean }) => ({
        flexGrow: 1,
        paddingLeft: 0,
        // paddingLeft: open ? theme.spacing(3) : 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 20,
        // marginLeft: `-${drawerWidth}px`,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            marginLeft: 0
        },
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: 0
        })
    })
);

// ==============================|| APPLICATION CHAT ||============================== //

const ChatMainPage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // handle right sidebar dropdown menu

    const [profileData, setProfileData] = useState<IProfileKecho>({});
    useEffect(() => {
        handleGetProfileData();
    }, []);
    const handleGetProfileData = async () => {
        const { data } = await userApi.currentUser();
        if (data) {
            setProfileData(data);
        }
    };

    // set chat details page open when user is selected from sidebar
    const [emailDetails, setEmailDetails] = React.useState(false);
    const handleUserChange = (event: React.SyntheticEvent) => {
        setEmailDetails((prev) => !prev);
    };

    // toggle sidebar
    const [openChatDrawer, setOpenChatDrawer] = React.useState(true);

    // close sidebar when widow size below 'md' breakpoint
    React.useEffect(() => {
        setOpenChatDrawer(!matchDownSM);
    }, [matchDownSM]);
    const [params] = useSearchParams();
    const idParam: any = params.get('idRelief');
    const d = new Date();
    const startDate: any = params.get('startDate');
    const endDate: any = params.get('endDate');

    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [user, setUser] = useState<UserProfile>({});
    const [data, setData] = useState<HistoryProps[]>([]);
    const [chatOption, setChatOption] = useState<ChatOptions[]>([]);
    const [step, setStep] = useState<string>('Greeting');
    const chatState = useSelector((state) => state.chat);

    React.useEffect(() => {
        setUser(chatState.user);
    }, [chatState.user]);

    React.useEffect(() => {
        switch (step) {
            case 'greeting': {
                setChatOption(chatOptions.optionFirstStep);
                break;
            }
            case OptionMessage.ASK_CLUSTER_MANAGER: {
                setChatOption(chatOptions.optionAskCluster);
                break;
            }
            case OptionMessage.FIND_INSIDE: {
                setData([...data, ...chatOptions.optionsInsideCluster]);
                break;
            }
            case OptionMessage.FIND_OUTSIDE: {
                setData([...data, ...chatOptions.optionsOutInsideCluster]);
                break;
            }
            default: {
                userRole === Roles.Cluster ? setChatOption(chatOptions.optionSecondStepForCM) : setChatOption(chatOptions.optionFirstStep);
            }
        }
    }, [step]);
    React.useEffect(() => {
        setData(chatState.chats);
    }, [chatState.chats]);

    React.useEffect(() => {
        // hide left drawer when email app opens
        dispatch(openDrawer(false));
        dispatch(getUser(1));
        getReliefById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        dispatch(getUserChats(user.name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // handle new message form
    const handleOnSend = (value: string) => {
        const newMessage = {
            from: 'User1',
            to: user.name,
            text: value,
            time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        };
        setData((prevState) => [...prevState, newMessage]);
        dispatch(insertChat(newMessage));

        if (userRole === Roles.Principals && value === OptionMessage.PLEASE_HELP) {
            onSubmitReliefToCluster();
        }
    };

    const onSubmitReliefToCluster = async () => {
        const res = await reliefApi.UpdateReliefById(idParam, ReliefStatus.OPEN);
        if (res.data) {
            const newMessage = {
                id: 6,
                from: 'Wakyu',
                text: "I'm looking for staff in your cluster, the relief will submit to the cluster manager for approval.",
                type: 'text',
                time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            };
            setData((prevState) => [...prevState, newMessage]);
            dispatch(insertChat(newMessage));
        }
    };

    // const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    //     if (event?.key !== 'Enter') {
    //         return;
    //     }
    //     handleOnSend();
    // };
    const handleClickMessage = (value: string) => {
        if (value === OptionMessage.ASK_CLUSTER_MANAGER) {
            UIManager.showModal({
                content: (onClose: any) => <AskClusterModal onClose={onClose} reliefId={idParam} />
            });
        } else if (value === OptionMessage.ASK_HR_IC) {
            UIManager.showModal({
                content: (onClose: any) => <AskHrIcModal onClose={onClose} reliefId={idParam} />
            });
        }
        handleOnSend(value);
        setStep(value);
    };
    const handleClickMessageRelief = () => {
        UIManager.showModal({
            content: (onClose: any) => <AskAssignReliefModal onClose={onClose} reliefId={idParam} roleAction="principal" />
        });
        handleOnSend('Ask assign relief');
    };
    const handleSelectTemporaryStaff = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <AssignTemporaryReliefModal
                    onClose={onClose}
                    callback={onClose}
                    reliefId={idParam}
                    roleAction={isClusterManager() ? 'cluster' : 'principal'}
                />
            ),
            stylesModal: {
                minWidth: '900px'
            }
        });
        handleOnSend('Ask assign temporary relief');
    };

    const isClusterManager = () => {
        return userRole === Roles.Cluster;
    };

    const getReliefById = async () => {
        setIsLoading(true);
        const res = await reliefApi.getReliefById(idParam);

        setIsLoading(false);
        const hiFirstname = profileData.firstName ? `Hi ${profileData.firstName},` : '';

        let dataTablePrint: any = [];
        let dataTableCM: any = [];
        if (res && res?.data?.staffList && isClusterManager()) {
            // dataTableCM = res?.data?.staffList?.staffListInCluster;
            dataTableCM = res?.data?.staffList?.staffListInCluster.concat(res?.data?.staffList?.staffListInAllCenter);
        } else {
            dataTablePrint = res?.data?.staffList;
        }

        if (dataTableCM?.length > 0 || dataTablePrint?.length > 0) {
            const textPrincipal = `${hiFirstname} I am WAKYU. I'm here to help. I have well received you request of hiring relief from 
            ${startDate} to ${endDate} it’s going to cost money FUIYO ! 
            It’s going to cost up-to ${res?.data?.price}$, let’s see if we can optimize.
           `;

            const textCluster = `Hi, I am WAKYU. Principal of ${res?.data[0]?.center?.nameCenter} is sending you a relief to help finding staffs .
            The relief period is from   ${startDate} to ${endDate}, with a target cost of ${res?.data?.price}$. 
            Click button "Ask HR-IC" to find suitable staffs`;

            const subTextCluster = {
                id: 5,
                from: 'Wakyu',
                text: `Within your cluster, there may be staffs available to take on this relief . 
                Please select one from your cluster, or if you cannot find a suitable candidate, 
                you can click the 'Ask HR-IC' button to help finding staffs from outside your cluster`,
                type: 'text',
                value: `Within your cluster, there may be staffs available to take on this relief . 
                Please select one from your cluster, or if you cannot find a suitable candidate, 
                you can click the 'Ask HR-IC' button to help finding staffs from outside your cluster`,
                time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            };

            const newMessage = [
                {
                    id: 4,
                    from: 'Wakyu',
                    text: isClusterManager() ? textCluster : textPrincipal,
                    type: 'text',
                    value: isClusterManager() ? textCluster : textPrincipal,
                    time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                },

                {
                    id: 6,
                    from: 'Wakyu',
                    text: '',
                    type: 'data',
                    value: isClusterManager() ? dataTableCM : dataTablePrint,
                    time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }
            ];

            isClusterManager() && newMessage.splice(1, 0, subTextCluster);
            setData(newMessage);
            setStep(isClusterManager() ? OptionMessage.ASK_HR_IC : OptionMessage.ASK_CLUSTER_MANAGER);

            // }
        } else {
            const textNotFoundForPrincipal = `There is no staff match your relief. Click "Ask cluster manager".`;
            const textNotFoundForCM = `There is no staff match your relief. Click "Ask HR-IC".`;
            const notFoundMessage = [
                {
                    id: 4,
                    from: 'Wakyu',
                    text: isClusterManager() ? textNotFoundForCM : textNotFoundForPrincipal,
                    type: 'text',
                    value: '',
                    time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }
            ];
            setStep(isClusterManager() ? OptionMessage.ASK_HR_IC : OptionMessage.ASK_CLUSTER_MANAGER);
            setData(notFoundMessage);
        }
    };

    const handleBack = () => {
        navigatePath('/staff/relief-list');
    };

    if (!user) return <Typography>Loading...</Typography>;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
            {isLoading ? (
                <CircularProgress size="280px" />
            ) : (
                <Main theme={theme} open={openChatDrawer}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs zeroMinWidth sx={{ display: emailDetails ? { xs: 'none', sm: 'flex' } : 'flex' }}>
                            <MainCard
                                sx={{
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                                }}
                            >
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0.5}>
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Avatar alt={user.name} src={avatarImage} />
                                                    </Grid>
                                                    <Grid item sm zeroMinWidth>
                                                        <Grid container spacing={0} alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" component="div">
                                                                    WAKYU{' '}
                                                                    {user.online_status && <AvatarStatus status={user.online_status} />}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm zeroMinWidth />
                                        </Grid>
                                        <Divider sx={{ mt: theme.spacing(2) }} />
                                    </Grid>

                                    <PerfectScrollbar
                                        style={{ width: '100%', height: 'calc(100vh - 440px)', overflowX: 'hidden', minHeight: 525 }}
                                    >
                                        <CardContent>
                                            <ChartHistory theme={theme} user={user} data={data} reliefId={idParam} userRole={userRole} />
                                        </CardContent>
                                    </PerfectScrollbar>

                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <IconButton onClick={handleBack} size="large">
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs zeroMinWidth>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    style={{
                                                        background: theme.palette.primary.main,
                                                        padding: '10px',
                                                        borderRadius: '8px'
                                                    }}
                                                >
                                                    <Chip
                                                        label={isClusterManager() ? 'Assign relief' : 'Request to assign'}
                                                        chipcolor="secondary"
                                                        onClick={() =>
                                                            isClusterManager() ? handleSelectTemporaryStaff() : handleClickMessageRelief()
                                                        }
                                                    />
                                                    {chatOption.map((option, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={option.label}
                                                            chipcolor="secondary"
                                                            onClick={() => handleClickMessage(option.value)}
                                                        />
                                                    ))}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        {emailDetails && (
                            <Grid item sx={{ margin: { xs: '0 auto', md: 'initial' } }}>
                                <Box sx={{ display: { xs: 'block', sm: 'none', textAlign: 'right' } }}>
                                    <IconButton onClick={handleUserChange} sx={{ mb: -5 }} size="large">
                                        <HighlightOffTwoToneIcon />
                                    </IconButton>
                                </Box>
                                <UserDetails user={user} />
                            </Grid>
                        )}
                    </Grid>
                </Main>
            )}
        </Box>
    );
};

export default ChatMainPage;
