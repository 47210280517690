import React, { useCallback, useEffect, useRef } from 'react';

// material-ui
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Theme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { UserProfile } from 'types/template/user-profile';
import { History } from 'types/template/chat';
import { IconCopy } from '@tabler/icons';
import UIManager from 'core/UIManager';
import ChooseStaffModal from 'modules/main/staff/Relief/components/ConfirmChooseModal';
import { Roles } from 'config';
// ==============================|| CHAT MESSAGE HISTORY ||============================== //

interface ChartHistoryProps {
    data: History[];
    theme: Theme;
    user: UserProfile;
    reliefId?: string;
    userRole?: string;
}

export const StaffTable = (props: any) => {
    const { data, reliefId, userRole } = props;

    const onChooseStaff = async (email: string) => {
        const reliefStaff = data.filter((item: any) => item.email === email)[0];
        UIManager.showModal({
            content: (onClose: any) => <ChooseStaffModal onClose={onClose} relief={reliefId} staff={reliefStaff} />
        });
    };

    const isClusterManager = () => {
        return userRole === Roles.Cluster;
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Certificate</TableCell>
                        {isClusterManager() && <TableCell align="left">Cluster manager</TableCell>}
                        {isClusterManager() && <TableCell align="left">Center</TableCell>}
                        {isClusterManager() && <TableCell align="left">Distance (km)</TableCell>}
                        <TableCell align="left">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data?.length > 0 &&
                        data?.map((item: any, index: number) => {
                            return (
                                <>
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                                        <TableCell align="left">{item?.name}</TableCell>
                                        <TableCell align="left">
                                            <span style={{ marginRight: '20px' }}>{item?.email}</span>{' '}
                                            <IconCopy onClick={() => copyToClipBoard(item?.email)} style={{ cursor: 'pointer' }} />
                                        </TableCell>
                                        <TableCell align="left">{item?.certificate?.map((it: any) => it?.cert?.diploma)}</TableCell>
                                        {isClusterManager() && <TableCell align="left">{item?.clusterManager}</TableCell>}
                                        {isClusterManager() && <TableCell align="left">{item?.centerName}</TableCell>}
                                        {isClusterManager() && <TableCell align="left">{item?.distance}</TableCell>}
                                        <TableCell align="left">
                                            <Button onClick={() => onChooseStaff(item?.email)}>Choose</Button>
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
    UIManager.showAlert({
        message: 'Copy email successfully!',
        alert: {
            color: 'success'
        }
    });
};

const ChartHistory = ({ data, theme, user, reliefId, userRole }: ChartHistoryProps) => {
    // scroll to bottom when new message is sent or received
    const wrapper = useRef(document.createElement('div'));

    // const el = wrapper.current;
    // const scrollToBottom = useCallback(() => {
    //     el.scrollIntoView(false);
    // }, [el]);

    // useEffect(() => {
    //     scrollToBottom();
    // }, [data.length, scrollToBottom]);

    return (
        <Grid item xs={12}>
            <Grid container spacing={gridSpacing} ref={wrapper}>
                {data?.map((history, index) => (
                    <React.Fragment key={index}>
                        {history.from !== user.name ? (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={2} />
                                    <Grid item xs={10}>
                                        <Card
                                            sx={{
                                                display: 'inline-block',
                                                float: 'right',
                                                bgcolor: theme.palette.mode === 'dark' ? 'grey.600' : theme.palette.primary.light
                                            }}
                                        >
                                            <CardContent sx={{ p: 2, pb: '16px !important', width: 'fit-content', ml: 'auto' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2" color={theme.palette.mode === 'dark' ? 'dark.900' : ''}>
                                                            {history.text}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography
                                                            align="right"
                                                            variant="subtitle2"
                                                            color={theme.palette.mode === 'dark' ? 'dark.900' : ''}
                                                        >
                                                            {history.time}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={7}>
                                        <Card
                                            sx={{
                                                display: 'inline-block',
                                                float: 'left',
                                                background:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark[900] : theme.palette.secondary.light
                                            }}
                                        >
                                            <CardContent sx={{ p: 2, pb: '16px !important' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        {history.type === 'data' ? (
                                                            <StaffTable data={history.value} reliefId={reliefId} userRole={userRole} />
                                                        ) : (
                                                            <Typography variant="body2">{history.text}</Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align="right" variant="subtitle2">
                                                            {history.time}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
};

export default ChartHistory;
