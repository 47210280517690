export const searchByName = (listData: any[], searchValue: string) => {
    if (searchValue.length > 0) {
        const searchResult = listData.filter((item) => item.companyName.toLocaleLowerCase().match(searchValue.toLowerCase()));
        return searchResult;
    }
    return listData;
};

export const getRoute = (pathname: string) => {
    const str = pathname.split('/');
    return `/${str[1]}`;
};

export const navigatePath = (path: string) => {
    window.location.href = `${window.location.protocol}//${window.location.host}${path}`;
};
