import { useNavigate } from 'react-router-dom';

// project imports
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { AUTH_LOCAL_STORAGE_KEY } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
        if (token) navigate('/centre/centre-list');
    }, [navigate]);

    return children;
};

export default GuestGuard;
