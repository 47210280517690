import React from 'react';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import defaultApi from 'services/api/defaultApi';
import UIManager from 'core/UIManager';
import RejectTimesheetModal from './RejectTimesheetModal';
import ModalCustom from 'components/Modal/ModalCustom';

interface ConfirmApproveRejectModalProps {
    onClose: () => void;
    callback: () => void;
    relief: any;
    status: string;
    type: string;
}
const ConfirmApproveRejectModal = ({ onClose, callback, relief, status, type }: ConfirmApproveRejectModalProps) => {
    const handleContinue = async () => {
        if (type === 'approve') {
            await handleApprove();
        } else {
            await handleReject();
        }
    };

    const handleApprove = async () => {
        const param = {
            reliefIds: [relief?.relief?.id],
            status
        };
        const response: any = await defaultApi.updateStatusTimeSheet(param);
        if (response.status === 200) {
            UIManager.showAlert({
                message: 'Approve timesheet successfully!',
                alert: {
                    color: 'success'
                }
            });
            onClose();
            callback();
        }
    };

    const handleReject = async () => {
        UIManager.showModal({
            content: (onCloseReject: any) => (
                <RejectTimesheetModal onCloseReject={onCloseReject} onClose={onClose} relief={relief} callback={callback} type="checkOne" />
            )
        });
    };
    return (
        <ModalCustom
            onSave={handleContinue}
            onClose={onClose}
            onCancel={onClose}
            title={`${type.charAt(0).toUpperCase() + type.slice(1)} confirm`}
            contentCancel="Cancel"
            contentSave="Continue"
        >
            <Grid container>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div">
                        Are you sure you want to {type} this timesheet?
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        This action cannot be reversed.
                    </Typography>
                </Grid>
                {/* <Grid container xs={12} justifyContent="center" style={{ marginTop: 20 }}>
                    <Button variant="outlined" onClick={onClose} style={{ border: '1px solid #3F51B5', color: '#3F51B5', marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleContinue} style={{ backgroundColor: '#3F51B5' }}>
                        Continue
                    </Button>
                </Grid> */}
            </Grid>
        </ModalCustom>
    );
};

export default ConfirmApproveRejectModal;
