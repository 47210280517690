import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, IconButton, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

// animation function
// function TransitionSlideLeft(props: SlideProps) {
//     return <Slide {...props} direction="left" />;
// }

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

// function TransitionSlideRight(props: SlideProps) {
//     return <Slide {...props} direction="right" />;
// }

// function TransitionSlideDown(props: SlideProps) {
//     return <Slide {...props} direction="down" />;
// }

// function GrowTransition(props: SlideProps) {
//     return <Grow {...props} />;
// }

export interface IDataAlertUI {
    id?: string;
    message: string;
    alert: {
        color: 'success' | 'info' | 'warning' | 'error' | 'primary' | 'secondary' | undefined;
        [key: string]: any;
    };
    position?: any;
}

interface IProps {}

interface IStates {
    alerts: IDataAlertUI[];
}

let alertManagerInstance: AlertManager | undefined;

class AlertManager extends React.Component<IProps, IStates> {
    constructor(props: IProps) {
        super(props);
        alertManagerInstance = this;
        this.state = {
            alerts: []
        };
    }

    static get instance() {
        return alertManagerInstance;
    }

    showAlert = (item: any) => {
        const { alerts } = this.state;
        const newPopups = [...alerts, { ...item, id: uuidv4() }];
        this.setState({ alerts: newPopups });
    };

    closeAlert = (item: IDataAlertUI) => {
        const { alerts } = this.state;
        const newData = alerts.filter((alert) => alert.id !== item.id);
        this.setState({ alerts: newData });
    };

    render() {
        const { alerts } = this.state;
        return (
            <>
                {alerts.map((elem: IDataAlertUI) => {
                    const { alert, message, position } = elem;
                    return (
                        <MuiSnackbar
                            TransitionComponent={TransitionSlideUp}
                            anchorOrigin={
                                position || {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            }
                            open
                            autoHideDuration={2000}
                            onClose={() => this.closeAlert(elem)}
                            key={elem.id}
                        >
                            <Alert
                                variant="filled"
                                color={alert.color}
                                action={
                                    <>
                                        <IconButton
                                            sx={{ color: 'background.paper' }}
                                            size="small"
                                            aria-label="close"
                                            onClick={() => this.closeAlert(elem)}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                }
                                sx={{
                                    ...(alert.variant === 'outlined' && {
                                        bgcolor: 'background.paper'
                                    })
                                }}
                            >
                                <span style={{ color: '#fff' }}>{message}</span>
                            </Alert>
                        </MuiSnackbar>
                    );
                })}
            </>
        );
    }
}

export default AlertManager;
