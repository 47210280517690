// material-ui
import { Chip, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';

// assets
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// =========================|| DATA WIDGET - INCOMING REQUESTS CARD ||========================= //

interface IProps {
    title: string;
    data: any;
}

const NextWeek = ({ title, data }: IProps) => (
    <MainCard title={title} content={false} style={{ height: 250 }} className="box-tag-staff">
        <PerfectScrollbar>
            <List component="nav" aria-label="main mailbox folders">
                {data.map((item: any) => {
                    let labelItem = '';
                    let colorItem: any = '';
                    if (item.type === 'all') {
                        labelItem = 'All';
                        colorItem = 'default';
                    }
                    if (item.type === 'brand') {
                        labelItem = 'Brand';
                        colorItem = 'error';
                    }
                    if (item.type === 'ecda') {
                        labelItem = 'ECDA';
                        colorItem = 'info';
                    }
                    return (
                        <>
                            <ListItemButton key={uuidv4()}>
                                <ListItemIcon style={{ marginRight: 10, width: '15%' }}>
                                    <Chip label={labelItem} color={colorItem} variant="outlined" />
                                </ListItemIcon>
                                <ListItemText primary={`${moment(item.date).format('dddd')} - ${item.status ? 'OK' : 'NOT OK'}`} />
                            </ListItemButton>
                            <Divider />
                        </>
                    );
                })}
            </List>
        </PerfectScrollbar>
    </MainCard>
);

export default NextWeek;
