import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useContext, useState } from 'react';
import { ArrangementOrder, HeadCell } from 'types';
import { FilterContext } from '..';
import { AttendanceContext } from './CollapseAttendance';
import StatusAttendance from './StatusAttendance';

const headCells: HeadCell[] = [
    {
        id: 'staffName',
        numeric: true,
        label: 'Staff name',
        align: 'left'
    },
    {
        id: 'checkIn',
        numeric: true,
        label: 'Check - in',
        align: 'left'
    },
    {
        id: 'checkOut',
        numeric: false,
        label: 'Check - out',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: ArrangementOrder;
    orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {headCells.map((headCell) => (
                    <TableCell
                        width="30%"
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const TableAttendance = () => {
    const contextAttendance = useContext(AttendanceContext);
    const { filter, setFilter } = useContext(FilterContext);
    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('id');

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setFilter({
            ...filter,
            order: isAsc ? 'desc' : 'asc',
            sortColumn: property
        });
    };

    function formatTime(time: string) {
        if (!time) {
            return '';
        }
        const timeParts = time.split(':');
        const formattedTime = `${timeParts[0]}:${timeParts[1]}`;

        return formattedTime;
    }

    const rows = contextAttendance?.attendances || [];

    return (
        <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                    {rows &&
                        rows.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell>{row?.staff?.nameStaff}</TableCell>
                                    <TableCell>{formatTime(row?.checkInTime)}</TableCell>
                                    <TableCell>{formatTime(row?.checkOutTime)}</TableCell>
                                    <TableCell align="left">
                                        <StatusAttendance status={row?.status} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableAttendance;
