import { UserProfile } from 'types/template/user-profile';

export type History = {
    from?: string;
    to?: string;
    time?: string;
    text?: string;
    type?: string;
    value?: any;
};

export type ChatOptions = {
    label: string;
    value: string;
    type: string;
};

export interface ChatHistory {
    id?: number;
    from?: string;
    to?: string;
    text: string;
    time?: string;
    type?: string;
    value?: any;
}

export interface ChatStateProps {
    chats: ChatHistory[];
    user: UserProfile;
    users: UserProfile[];
    error: object | string | null;
}

export enum OptionMessage {
    FIND_INSIDE = 'Find inside cluster',
    FIND_OUTSIDE = 'Find outside cluster',
    FIND_All = 'Find all',
    SUBMIT_REQUEST = 'Submit request',
    OPTIMIZE = 'Optimize',
    PLEASE_HELP = 'Please help',
    ASK_CLUSTER_MANAGER = 'Ask cluster manager',
    ASK_HR_IC = 'Ask HR-IC'
}
