// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { ReliefStatus } from 'config';
import UIManager from 'core/UIManager';
import ModalCustom from 'components/Modal/ModalCustom';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    reliefId: string;
    roleAction: string;
}

const AskAssignReliefModal = (props: IProps) => {
    const { onClose, reliefId, roleAction } = props;
    const navigate = useNavigate();

    const handleSubmitStaff = async () => {
        if (roleAction === 'principal') {
            const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.PENDING_APPROVAL);
            if (res) {
                UIManager.showAlert({
                    message: 'Ask assign relief successfully',
                    alert: {
                        color: 'success'
                    }
                });
                onClose();
                navigate(`/staff/relief-list`, { state: { idRef: reliefId }, replace: true });
            }
        }
        // await reliefApi.UpdateReliefById(reliefId, ReliefStatus.APPROVED_ASSIGN_RELIEF);
        // navigate(`/staff/relief-list`, { state: { idRef: reliefId }, replace: true });
    };

    return (
        <ModalCustom
            onSave={handleSubmitStaff}
            onCancel={() => {}}
            onClose={onClose}
            title="Request approval to directly assign a relief"
            contentSave="OK"
        >
            <>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Typography gutterBottom component="div">
                        {/* I'm looking for staff in your cluster, the relief will submit to the cluster manager for approval. */}
                        The request will submit to the cluster manager for approval.
                    </Typography>
                </Grid>
            </>
        </ModalCustom>
    );
};

export default AskAssignReliefModal;
