import React, { useEffect } from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from '@mui/material';
import defaultApi from 'services/api/defaultApi';
import moment from 'moment';
import { Roles, TIMESHEET_STATUS } from 'config';
import UIManager from 'core/UIManager';
import EmptyData from 'components/errors/empty-data';
import ModalCustom from 'components/Modal/ModalCustom';

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    // padding: '0px 15px',
    backgroundColor: '#fff',
    '& .timesheet-content': {
        width: '100%',
        height: 'auto',
        // padding: '20px 0px',
        '& .top-content': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '20px',
            '& .top-name': {
                display: 'flex',
                alignItems: 'center',
                '& span': {
                    fontSize: '16px',
                    fontWeight: 600
                }
            },
            '& .top-action': {
                display: 'flex',
                alignItems: 'center',
                '& button': {
                    color: '#3F51B5',
                    border: '1px solid #3F51B5'
                },
                '& .editing': {
                    fontSize: '16px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    width: '85px',
                    marginRight: 10
                }
            }
        },
        '& .table-content': {
            '& .MuiTableCell-head': {
                borderRight: '1px solid #eeeeee'
            },
            '& .MuiTableCell-body': {
                borderRight: '1px solid #eeeeee'
            }
        }
    },
    '& .action': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0px'
    }
}));

interface ICheckInOut {
    onClose: any;
    relief: any;
    userRole: string;
}

const DetailTimeSheet = ({ onClose, relief, userRole }: ICheckInOut) => {
    const [reliefAttendances, setReliefAttendances] = React.useState<any>([]);
    const [isEdit, setIsEdit] = React.useState(false);
    const [timeCheckIn, setTimeCheckIn] = React.useState<any>();
    const [timeCheckOut, setTimeCheckOut] = React.useState<any>();

    useEffect(() => {
        getTimeSheet();
    }, [relief]);

    const getTimeSheet = async () => {
        try {
            const response: any = await defaultApi.getTimeSheetRelief(relief?.relief?.id);
            if (response.status === 200) {
                setReliefAttendances(response?.data?.reliefAttendances);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetTimeCheckIn = (e: any, row: any) => {
        const time = e.target.value;
        setTimeCheckIn({ id: row.id, checkIn: time || row.checkIn });
    };

    const handleGetTimeCheckOut = (e: any, row: any) => {
        const time = e.target.value;
        setTimeCheckOut({ id: row.id, checkOut: time || row.checkOut });
    };

    const handleSubmitting = async () => {
        const timesheetID = relief?.id;
        const dataSubmit: any = reliefAttendances.map((item: any) => {
            const data = {
                id: item.id,
                checkIn: timeCheckIn?.id === item.id ? timeCheckIn?.checkIn : item.checkIn,
                checkOut: timeCheckOut?.id === item.id ? timeCheckOut?.checkOut : item.checkOut,
                date: item.date
            };
            return data;
        });
        const response: any = await defaultApi.updateTimeSheetTime({ timesheetId: timesheetID, reliefAttendances: dataSubmit });
        if (response.status === 200) {
            UIManager.showAlert({
                message: 'Update time successfully',
                alert: {
                    color: 'success'
                }
            });
            getTimeSheet();
            setIsEdit(false);
            // onClose();
        }
    };

    const handleEdit = () => {
        setIsEdit(true);
    };

    const isCheckEdit = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin || userRole === Roles.HrIc || userRole === Roles.Principals;
    };
    return (
        <>
            <ModalCustom
                onSave={handleSubmitting}
                onClose={onClose}
                onCancel={onClose}
                title="View timesheet"
                contentCancel="Close"
                contentSave={isEdit ? 'Save' : undefined}
            >
                <ContentWrapper>
                    <div className="timesheet-content">
                        <div className="top-content">
                            <div className="top-name">
                                <span style={{ marginRight: 8 }}>Relief name: </span>
                                <span>{relief?.relief?.reliefStaff?.nameStaff}</span>
                            </div>
                            <div className="top-action">
                                {isEdit && (
                                    <>
                                        <span className="editing">
                                            Editing <span className="dot-flashing" />
                                        </span>
                                    </>
                                )}
                                {isCheckEdit() && !isEdit && relief?.status !== TIMESHEET_STATUS.APPROVED && (
                                    <Button variant="outlined" onClick={handleEdit}>
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className="table-content">
                            <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                                <Table sx={{ minWidth: 750, border: '1px solid #eeeeee' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="25%" sx={{ borderRight: '1px solid #eeeeee' }}>
                                                Date
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                In time
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                Out time
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                Total hours
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reliefAttendances?.length ? (
                                            reliefAttendances.map((row: any) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell width="25%">{moment(row?.date).format('DD-MM-YYYY')}</TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {!isEdit && (
                                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                                                {row?.checkIn || '-'}
                                                            </span>
                                                        )}

                                                        {isEdit && (
                                                            <TextField
                                                                type="time"
                                                                defaultValue={row?.checkIn}
                                                                onChange={(e) => handleGetTimeCheckIn(e, row)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {!isEdit && (
                                                            <span style={{ display: 'inline-block', width: '100%' }}>
                                                                {row?.checkOut || '-'}
                                                            </span>
                                                        )}
                                                        {isEdit && (
                                                            <TextField
                                                                type="time"
                                                                defaultValue={row?.checkOut}
                                                                onChange={(e) => handleGetTimeCheckOut(e, row)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {row?.total || '-'}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                                                <Grid item xs={12} className="empty-icon">
                                                    <EmptyData />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </ContentWrapper>
            </ModalCustom>
        </>
    );
};

export default DetailTimeSheet;
