import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import {
    Box,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { DEFAULT_REQUEST, Order } from 'config';
import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { upperFirst } from 'lodash';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import AddIcon from '@mui/icons-material/Add';
import UIManager from 'core/UIManager';
import { ClusterKecho } from 'types/kecho/centre-kecho';
import { clusterApi } from 'services/api';
import EmptyData from 'components/errors/empty-data';
import ClusterModal from '../AddClusterModal';
import DeleteClusterModal from '../DeleteClusterModal';
import moment from 'moment';
import { StyledTableCell } from 'components/Table/TableCustom';
// table header options
const headCells: HeadCell[] = [
    // {
    //     id: 'name',
    //     numeric: false,
    //     label: 'Id',
    //     align: 'left'
    // },
    {
        id: 'nameCluster',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'centreKecho',
        numeric: false,
        label: 'Centers',
        align: 'left'
    },
    {
        id: 'clusterManager',
        numeric: false,
        label: 'Cluster Manager',
        align: 'left'
    },
    {
        id: 'email',
        numeric: false,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'Created Date',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const ClusterList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [clusterList, setClusterList] = React.useState<ClusterKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    const [selected] = React.useState<string[]>([]);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetClusterList();
        }, 500);
        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetClusterList = async () => {
        const { dataList, meta } = await clusterApi.listCluster(filter);
        if (dataList) {
            setClusterList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {};

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const goToDetailCenter = (id: string) => {
        navigate(`/centre/centre-details/${id}`);
    };

    const handleOpenModalAddCluster = () => {
        UIManager.showModal({
            content: (onClose: any) => <ClusterModal onClose={onClose} callback={handleGetClusterList} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };
    const handleOpenModalEditCluster = (cluster: any) => {
        UIManager.showModal({
            content: (onClose: any) => <ClusterModal onClose={onClose} callback={handleGetClusterList} clusterEdit={cluster} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };
    const handleOpenDeleteModalCluster = (cluster: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteClusterModal onClose={onClose} callback={handleGetClusterList} clusterEdit={cluster} />
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Cluster List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Add new cluster" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenModalAddCluster}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {clusterList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={clusterList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {/* stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                {clusterList &&
                                    clusterList.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;

                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                                                <TableCell width="25%">{row?.nameCluster}</TableCell>

                                                <TableCell align="left" sx={{ pr: 3 }} width="40%">
                                                    {row?.center?.map((item: any) => (
                                                        <Chip
                                                            label={upperFirst(item?.nameCenter)}
                                                            onClick={() => goToDetailCenter(item?.id)}
                                                            sx={{ mb: 1, mr: 0.5 }}
                                                        />
                                                    ))}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }} width="40%">
                                                    {row?.staffs?.map((item: any) => (
                                                        <Chip label={upperFirst(item?.nameStaff)} color="primary" sx={{ mb: 1, mr: 0.5 }} />
                                                    ))}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }} width="40%">
                                                    {row?.staffs?.map((item: any) => item.user.email)}
                                                </TableCell>

                                                <TableCell align="left" sx={{ pr: 3 }} width="25%">
                                                    {moment(row?.createdAt).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        {/* <Tooltip placement="top" title="View detail">
                                                            <IconButton
                                                                component={Link}
                                                                color="primary"
                                                                size="large"
                                                                to={`/class/class-details/${row?.id}`}
                                                            >
                                                                <VisibilityTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                        <Tooltip title="Edit detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{
                                                                    color: '#3F51B5',
                                                                    border: '1px solid #3F51B5',
                                                                    marginRight: 10
                                                                }}
                                                                onClick={() => handleOpenModalEditCluster(row)}
                                                            >
                                                                <EditIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                Edit
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Remove">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                                                onClick={() => handleOpenDeleteModalCluster(row)}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                                />{' '}
                                                                Remove
                                                            </Button>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* table pagination */}

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take || 10}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <EmptyData />
                        <br />
                        {filter.query !== '' && <div>Sorry we couldn't find any matches for {filter.query}</div>}
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ClusterList;
