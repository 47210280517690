import { PromiseApi } from 'types/kecho/api-promise';
import { buildUrl } from '../../utils/buildApi';
import { IFilterRequest } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import {
    IInputEditRatio,
    IInputRatioSaveClass,
    IRatioBranchClass,
    ITableRatioCenterId,
    IInputListAttendance
} from 'types/kecho/class-kecho';

const BaseRatioApi = 'ratio/';

const ratioApi = {
    listClass(params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseRatioApi}list-ratio`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    listStaff(params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseRatioApi}list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    listStaffByCenter(params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseRatioApi}list-staff-center`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    saveRatio(body: IInputRatioSaveClass): Promise<PromiseApi> {
        const url = `${BaseRatioApi}save-ratio`;
        return axiosAuthClient.post(url, body);
    },
    listRatio(body: IRatioBranchClass): Promise<PromiseApi> {
        const base = `${BaseRatioApi}list-ratio-branch`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    editRatio(centerId: string, body: IInputEditRatio): Promise<PromiseApi> {
        let url = '';
        if (centerId) {
            url = `${BaseRatioApi}center/${centerId}`;
        } else {
            url = `${BaseRatioApi}branch`;
        }
        return axiosAuthClient.patch(url, body);
    },
    resetRatio(centerId: string): Promise<PromiseApi> {
        const url = `${BaseRatioApi}center/${centerId}`;
        return axiosAuthClient.delete(url);
    },
    tableRatio(body?: ITableRatioCenterId): Promise<PromiseApi> {
        const base = `${BaseRatioApi}table-ratio`;
        const url = buildUrl(base, body);
        return axiosAuthClient.get(url);
    },
    checkDuplicateRatio(body: IInputRatioSaveClass): Promise<PromiseApi> {
        const url = `${BaseRatioApi}check-duplicates`;
        return axiosAuthClient.post(url, body);
    },
    saveDuplicateRatio(body: IInputRatioSaveClass): Promise<PromiseApi> {
        const url = `${BaseRatioApi}save-duplicate-ratio2`;
        return axiosAuthClient.post(url, body);
    },
    clearDuplicateRatio(body: IInputRatioSaveClass): Promise<PromiseApi> {
        const url = `${BaseRatioApi}clear-ratio`;
        return axiosAuthClient.put(url, body);
    },
    listAttendance(params: IInputListAttendance): Promise<PromiseApi> {
        const base = `${BaseRatioApi}staff-attendance`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    checkLeave(): Promise<PromiseApi> {
        const base = `${BaseRatioApi}check-leave`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    }
};
export default ratioApi;
