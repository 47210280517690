// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import AvatarStatus from './AvatarStatus';

import { gridSpacing } from 'store/constant';
import { UserProfile } from 'types/template/user-profile';

// assets

import WakyuLogo from 'assets/images/users/wakyu.png';

const avatarImage = WakyuLogo;

// ==============================|| USER PROFILE / DETAILS ||============================== //

interface UserDetailsProps {
    user: UserProfile;
}

const UserDetails = ({ user }: UserDetailsProps) => {
    const theme = useTheme();

    return (
        <Grid container spacing={gridSpacing} sx={{ width: '100%', maxWidth: 300 }}>
            <Grid item xs={12}>
                <Card>
                    <CardContent
                        sx={{
                            textAlign: 'center',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Avatar
                                    alt={user.name}
                                    src={avatarImage}
                                    sx={{
                                        m: '0 auto',
                                        width: 130,
                                        height: 130,
                                        border: '1px solid',
                                        borderColor: theme.palette.primary.main,
                                        p: 1,
                                        bgcolor: 'transparent'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AvatarStatus status={user.online_status!} />
                                <Typography variant="caption" component="div">
                                    {user?.online_status!.replaceAll('_', ' ')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="div">
                                    {user.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default UserDetails;
