import { useNavigate } from 'react-router-dom';

// project imports
import { AUTH_LOCAL_STORAGE_KEY } from 'config';
import { useEffect } from 'react';
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children
 * @param {PropTypes.string} role element/node
 */

const checkPermission = (userRole: string, roles: any) => {
    if (roles[0] === '*' || roles.includes(userRole)) {
        return true;
    }
    return false;
};

const AuthGuard = ({ children, role }: GuardProps) => {
    const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    const userInfo = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO);
    const roleName = userInfo ? JSON.parse(userInfo).role.roleName : '';
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            navigate('login', { replace: true });
        }
        if (role && !checkPermission(roleName, role)) {
            navigate('/user/my-plan');
        }
    }, [token, navigate]);

    return children;
};

export default AuthGuard;
