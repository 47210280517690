import React from 'react';
import { Button, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    '& .action': {
        marginTop: '20px'
    }
}));

interface ICheckInOut {
    onCloseSuccess: any;
    getTimeSheet: any;
}

const TimeSheetSubmittedSuccess = ({ onCloseSuccess, getTimeSheet }: ICheckInOut) => {
    const handleClose = () => {
        onCloseSuccess();
        getTimeSheet();
    };
    return (
        <>
            <ContentWrapper>
                <CheckCircleIcon color="success" fontSize="large" />
                <div>Timesheet has been submitted to Principal</div>
                <div className="action">
                    <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default TimeSheetSubmittedSuccess;
