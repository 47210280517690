import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';

const MyPlan = Loadable(lazy(() => import('./MyPlan')));
const Profile = Loadable(lazy(() => import('./Profile')));

class AccountModule {
    constructor() {
        addRoutes([
            {
                path: '/user/profile',
                element: <Profile />
            },
            {
                path: '/user/my-plan',
                element: <MyPlan />
            }
        ]);
    }
}

export default new AccountModule();
