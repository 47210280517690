import { Box, FormHelperText, Grid, Paper } from '@mui/material';
import HeaderModal, { IHeaderModalProps } from './HeaderModal';
import FooterModal, { IFooterModalProps } from './FooterModal';

interface IModalProps extends IFooterModalProps, IHeaderModalProps {
    children: React.ReactElement;
    sx?: React.CSSProperties;
    msgHelper?: string;
    styleHelper?: React.CSSProperties;
    typeHelper?: 'error' | 'warning' | '';
}
const ModalCustom = (props: IModalProps) => {
    const {
        children,
        title,
        iconSave,
        iconCancel,
        onClose,
        isLoading = false,
        contentCancel,
        contentSave,
        sx,
        msgHelper,
        typeHelper,
        styleHelper,
        disabled: isSubmitting,
        onCancel,
        onSave
    } = props;
    function renderFormHelperColor() {
        switch (typeHelper) {
            case 'error':
                return '#F44336';
            case 'warning':
                return '#ff9800';
            default:
                return '#fff';
        }
    }
    return (
        <Paper>
            <HeaderModal title={title} onClose={onClose} />
            <Box sx={{ padding: '20px', ...sx }}>
                {children}
                {msgHelper && (
                    <FormHelperText
                        style={{
                            color: renderFormHelperColor(),
                            fontSize: 16,
                            textAlign: 'left',
                            marginTop: '5px',
                            ...styleHelper
                        }}
                    >
                        {msgHelper}
                    </FormHelperText>
                )}
            </Box>
            <FooterModal
                isLoading={isLoading}
                disabled={isSubmitting}
                onCancel={onCancel}
                onSave={onSave}
                iconCancel={iconCancel}
                iconSave={iconSave}
                contentCancel={contentCancel}
                contentSave={contentSave}
            />
        </Paper>
    );
};

export default ModalCustom;
