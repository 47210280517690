export const optionsChart: any = (categories: any) => ({
    chart: {
        id: 'new-stack-chart',
        // sparkline: {
        //     enabled: true
        // },

        type: 'bar'
    },
    dataLabels: {
        enabled: true
    },
    plotOptions: {
        bar: {
            barHeight: '80%',
            horizontal: true
        }
    },
    xaxis: {
        crosshairs: {
            width: 1
        },
        categories
    },
    tooltip: {
        fixed: {
            enabled: false
        },
        x: {
            show: false
        },
        y: {
            title: {
                formatter: (seriesName: any) => 'Hours - '
            }
        },
        marker: {
            show: false
        }
    },
    grid: {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        }
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    }
});
