// routing
import Routes from 'core/routes';

// project imports
import Snackbar from 'components/extended/Snackbar';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import NavigationScroll from 'core/layout/NavigationScroll';
import ThemeCustomization from 'core/themes';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'services/contexts/FirebaseContext';
import { ViewUIManager } from 'core/UIManager';

import { LocalizationProvider } from '@mui/x-date-pickers';
// third party
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { getTokenfb2 } from 'firebase';
import { AUTH_LOCAL_STORAGE_KEY, Roles } from 'config';
import { useNavigate } from 'react-router-dom';
import PublicLayout from 'core/layout/PublicLayout';
import Attendance from 'modules/main/staff/Attendance';
import SelectCenter from 'modules/main/account/SelectCenter';

const App = () => {
    const navigate = useNavigate();
    const dataUserLocal = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO);
    const accessToken = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN) ?? null;
    const selectionCenterId = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? '';
    const userRole = dataUserLocal ? JSON.parse(dataUserLocal || '')?.role?.roleName : '';
    const dataCenters = dataUserLocal ? JSON.parse(dataUserLocal || '')?.staff?.center : [];
    const [isCheckLayout, setIsCheckLayout] = useState(false);
    const [isShowPage, setIsShowPage] = useState(false);
    const urlHref = window.location.href;
    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            getToken();
            setIsCheckLayout(false);
            return;
        }

        if (!accessToken && urlHref.includes('/staff/attendance')) {
            setIsCheckLayout(true);
            setIsShowPage(true);
            return;
        }

        if (accessToken && urlHref.includes('/center/select-center')) {
            setIsCheckLayout(true);
            setIsShowPage(false);
            return;
        }

        if (accessToken && urlHref.includes('/staff/attendance')) {
            setIsCheckLayout(false);
            return;
        }
        if (accessToken && !selectionCenterId && userRole === Roles.Principals && dataCenters?.length > 1) {
            navigate('/center/select-center');
            setIsCheckLayout(true);
        }
    }, [accessToken, urlHref, selectionCenterId]);

    const getToken = async () => {
        const token = await getTokenfb2();
        if (token) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.FCM_TOKEN, token);
    };
    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <>
                                {isCheckLayout ? (
                                    <PublicLayout>
                                        {isShowPage && <Attendance typeLoadPage="public" />}
                                        {!isShowPage && <SelectCenter />}
                                        <ViewUIManager />
                                    </PublicLayout>
                                ) : (
                                    <>
                                        <Routes />
                                        <Snackbar />
                                        <ViewUIManager />
                                    </>
                                )}
                            </>
                        </LocalizationProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
