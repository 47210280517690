// material-ui
import { Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// assets
import CampaignIcon from '@mui/icons-material/Campaign';
import moment from 'moment';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

interface IProps {
    data: any;
    onClickItem: any;
    isViewAll?: boolean;
}

const NotificationList = ({ data, onClickItem, isViewAll }: IProps) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    return (
        <List
            sx={{
                width: '100%',
                py: 0,
                overflowX: 'hidden',
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {data?.map((item: any) => {
                return (
                    <div key={item.id}>
                        <ListItemWrapper onClick={() => onClickItem(item)}>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <CampaignIcon fontSize="large" />
                                </ListItemAvatar>
                                <span style={{ fontWeight: 500, width: '150px' }}> {item.title}</span>
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {moment(item.createdAt).format('DD/MM HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography textAlign="start" variant="subtitle2">
                                        {item.body}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {/* <Grid item>
                                            <Chip label={item.urlRef} sx={chipErrorSX} />
                                        </Grid> */}
                                        {!item.status && (
                                            <Grid item>
                                                <Chip style={{ cursor: 'pointer' }} label="New" sx={chipWarningSX} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </div>
                );
            })}
        </List>
    );
};

export default NotificationList;
