import { addRoutes } from 'store/slices/kecho/core/system';
// import Loadable from 'components/Loadable';
// import { lazy } from 'react';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import SettingTabs from '../staff/SettingTabs';

// const UserList = Loadable(lazy(() => import('./UserList')));

class UserModule {
    constructor() {
        addRoutes([
            {
                path: '/user/user-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin]}>
                        <SettingTabs tab="2" />
                        {/* <UserList /> */}
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new UserModule();
