// third-party
import { createSlice } from '@reduxjs/toolkit';
import { TypeRouter } from 'config';
import { IRoute } from 'types/kecho/system';

// project imports
import { dispatch } from '../../../index';

// ----------------------------------------------------------------------

interface ISystemState {
    routesMain: IRoute[];
    routesTemplate: IRoute[];
    routesAuth: IRoute[];
}

const initialState: ISystemState = {
    routesMain: [],
    routesTemplate: [],
    routesAuth: []
};

const slice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        // routes
        addRoutes(state, action) {
            const { routes, type } = action.payload;
            if (type === TypeRouter.main) state.routesMain = state.routesMain.concat(routes);
            if (type === TypeRouter.template) state.routesTemplate = state.routesTemplate.concat(routes);
            if (type === TypeRouter.auth) state.routesAuth = state.routesAuth.concat(routes);
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const addRoutes = (routes: IRoute[], type = TypeRouter.main) => {
    dispatch(slice.actions.addRoutes({ routes, type }));
};
