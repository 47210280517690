// material-ui
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';

// project imports
import CloseIcon from '@mui/icons-material/Close';

// assets

interface DetailUserProps {
    userDetail: any;
    onClose: () => void;
}

const Detail = ({ userDetail, onClose }: DetailUserProps) => {
    // const theme = useTheme();

    return (
        <Paper style={{ padding: '0px 20px' }}>
            <Grid container py={1} alignItems="center">
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h3" gutterBottom component="div" mb={0} ml={6}>
                        User detail
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    alignItems="center"
                    justifyContent="center"
                    onClick={onClose}
                    style={{ cursor: 'pointer', display: 'flex' }}
                >
                    <CloseIcon />
                </Grid>
            </Grid>
            <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />
            <Stack style={{ padding: '20px 0' }}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1">Username:</Typography>
                    <Typography variant="body2">{userDetail?.name || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1">Email:</Typography>
                    <Typography variant="body2">{userDetail?.email || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1">Job title:</Typography>
                    <Typography variant="body2">{userDetail?.jobTitle || '-'}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle1">User role:</Typography>
                    <Typography variant="body2">{userDetail?.role?.roleName || '-'}</Typography>
                </Stack>
            </Stack>
            <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />
            <Grid item xs={12} textAlign="center" style={{ padding: 10 }}>
                <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={onClose}>
                    Close
                </Button>
            </Grid>
        </Paper>
    );
};

export default Detail;
