import React from 'react';

// material-ui

// project imports

// assets

// generate random
// function rand() {
//     return Math.round(Math.random() * 20) - 10;
// }

// modal position

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 4,
    marginBottom: 4
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

interface IProps {
    onClose: () => void;
    onUpload: (file: any) => Promise<boolean>;
    callback: any;
}

const UploadFile = (props: IProps) => {
    const { onClose, onUpload, callback } = props;

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1
    });

    const files = acceptedFiles.map((file: any) => (
        <li key={file.name}>
            <div>
                {file.name} - {file.size} bytes
            </div>
        </li>
    ));

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        const res = await onUpload(acceptedFiles[0]);
        if (res) {
            onClose();
            callback();
        }
    };

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <section className="container">
            <Box {...getRootProps({})} sx={style}>
                <input {...getInputProps()} />
                <p>Drag &lsquo;n&lsquo; drop file here, or click to select file</p>
                <em>(Only *.xlsx excel file will be accepted)</em>
            </Box>
            <aside>
                <ul>{files}</ul>
            </aside>
            <Box sx={{ textAlign: 'end' }}>
                <Button variant="contained" type="button" onClick={uploadFile}>
                    Upload File
                </Button>
            </Box>
        </section>
    );
};

export default UploadFile;
