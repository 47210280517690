import React from 'react';
import { Button, styled } from '@mui/material';
import { TIMESHEET_STATUS } from 'config';
import defaultApi from 'services/api/defaultApi';
import UIManager from 'core/UIManager';
import TimeSheetSubmittedSuccess from './TimeSheetSubmittedSuccess';

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    '& .action': {
        marginTop: '20px'
    }
}));

interface ICheckInOut {
    onClose: any;
    reliefId: string;
    getTimeSheet: any;
}

const TimeSheetSubmiting = ({ onClose, reliefId, getTimeSheet }: ICheckInOut) => {
    const handleNext = async () => {
        const param = {
            reliefIds: [reliefId],
            status: TIMESHEET_STATUS.WAITING_APPROVAL
        };
        const response: any = await defaultApi.updateStatusTimeSheet(param);
        if (response.status === 200) {
            onClose();
            UIManager.showModal({
                content: (onCloseSuccess: any) => <TimeSheetSubmittedSuccess onCloseSuccess={onCloseSuccess} getTimeSheet={getTimeSheet} />
            });
            UIManager.showAlert({
                message: 'Submit timesheet successfully!',
                alert: {
                    color: 'success'
                }
            });
        } else {
            UIManager.showAlert({
                message: response?.data?.message || 'Submit timesheet failed!',
                alert: {
                    color: 'error'
                }
            });
        }
    };
    return (
        <>
            <ContentWrapper>
                <div>Are you sure to submit the timesheet to Principal?</div>
                <div className="action">
                    <Button variant="outlined" style={{ color: '#3F51B5', borderColor: '#3F51B5', marginRight: 10 }} onClick={onClose}>
                        Back
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={handleNext}>
                        Next
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default TimeSheetSubmiting;
