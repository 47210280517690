import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
    Typography,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    // TablePagination,
    TextField,
    Grid,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { IFilterRequest } from 'types/query/filter';
import { DEFAULT_REQUEST, ReliefStatus } from 'config';
import EmptyData from 'components/errors/empty-data';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import UIManager from 'core/UIManager';
import { reliefApi } from 'services/api';
import staffApi from 'services/api/staffApi';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

interface IProps {
    onClose: any;
    callback: any;
    reliefId: string;
    roleAction: string;
}

export default function AssignTemporaryRelieftModal(props: IProps) {
    const navigate = useNavigate();
    const { reliefId, onClose, callback, roleAction } = props;
    const [selectTicket, setSelectTicket] = useState<ReliefTicketKecho>();

    const [reliefTickets, setReliefTicket] = useState<ReliefTicketKecho[]>([]);
    const [filter, setFilter] = useState<IFilterRequest>(DEFAULT_REQUEST);

    useEffect(() => {
        handleGetClassData();
    }, [filter]);

    const handleGetClassData = async () => {
        const { dataList } = await staffApi.list({ ...filter, isTemporary: 'true' });
        if (dataList) {
            setReliefTicket(dataList);
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchStaff = debounce(onChange, 500);

    const handleAssignTemporaryRelief = async () => {
        if (!selectTicket) {
            UIManager.showAlert({
                message: 'Please select tickets to assign',
                alert: {
                    color: 'error'
                }
            });

            return;
        }
        if (roleAction === 'principal') {
            const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.PRIN_COMPLETED, selectTicket, undefined, undefined);
            if (res) {
                UIManager.showAlert({
                    message: 'Assigned successfully',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
                return;
            }
        }
        const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.CM_ASSIGNED_RELIEF, selectTicket, undefined, undefined);
        if (res) {
            UIManager.showAlert({
                message: 'Assign staff successfully',
                alert: {
                    color: 'success'
                }
            });
            callback();
            onClose();
            navigate(`/staff/relief-list`, { state: { idRef: reliefId }, replace: true });
        }
    };

    const renderTableStaff = () => {
        return (
            <Paper>
                {reliefTickets.length ? (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ minWidth: 70 }}>Name</TableCell>
                                        <TableCell style={{ width: 50 }}>Email Address</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Qualification</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Cost</TableCell>
                                        <TableCell style={{ minWidth: 50 }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reliefTickets.map((row: ReliefTicketKecho) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={uuidv4()}
                                                style={{ backgroundColor: row?.isDisabled ? '#e1e1e1' : '', cursor: 'pointer' }}
                                                onClick={() =>
                                                    row?.id === selectTicket?.id ? setSelectTicket(undefined) : setSelectTicket(row)
                                                }
                                            >
                                                <TableCell>{row?.nameStaff}</TableCell>
                                                <TableCell>{row?.email}</TableCell>
                                                <TableCell>
                                                    {row?.staffCert?.map((item: any) => {
                                                        return <Chip label={item?.cert?.diploma} key={item?.id} />;
                                                    })}
                                                </TableCell>
                                                <TableCell>{row?.cost}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={row?.id === selectTicket?.id}
                                                        onChange={(e) =>
                                                            e.target.checked ? setSelectTicket(row) : setSelectTicket(undefined)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    return (
        <Paper>
            <Grid container py={1} alignItems="center">
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h3" gutterBottom component="div" mb={0} ml={6}>
                        Please select staff
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    alignItems="center"
                    justifyContent="center"
                    onClick={onClose}
                    style={{ cursor: 'pointer', display: 'flex' }}
                >
                    <CloseIcon />
                </Grid>
            </Grid>
            {/* <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" /> */}
            <div style={{ padding: 20 }}>
                <Grid item xs={4}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearchStaff}
                        placeholder="Search by name"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </div>

            {renderTableStaff()}

            <Grid item xs={11} textAlign="center" style={{ padding: 10 }}>
                <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={handleAssignTemporaryRelief}>
                    Assign Temporary Relief
                </Button>
            </Grid>
        </Paper>
    );
}
