import React from 'react';
import ModalManager, { IDataUI } from './ModalManager';
import AlertManager, { IDataAlertUI } from './AlertManager';

class ControllerUI {
    showModal = (item: IDataUI) => {
        ModalManager.instance?.showModal(item);
    };

    showAlert = (item: IDataAlertUI) => {
        AlertManager.instance?.showAlert(item);
    };
}

const UIManager = new ControllerUI();

export default UIManager;

export function ViewUIManager() {
    return (
        <>
            <ModalManager />
            <AlertManager />
        </>
    );
}
