// project imports
import MainLayout from 'core/layout/MainLayout';
import { useSelector } from 'store';

const MainRoutes = () => {
    const systemState = useSelector((state) => state.system);
    const { routesMain } = systemState;

    return {
        path: '/',
        element: <MainLayout />,
        children: routesMain
    };
};

export default MainRoutes;
