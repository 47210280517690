import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest, IFilterRequestWithOutPaging } from 'types/query/filter';
import { StaffKechoRegister, VisibilityStaff } from 'types/kecho/staff-kecho';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'staff/';

const staffApi = {
    list(params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    listStaffTemporary(params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}list-staff/temporary`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    listAllStaffByCenter(params: IFilterRequestWithOutPaging): Promise<PromiseApi> {
        const base = `${BaseApi}list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    getDetailsById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}detail/${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    createStaff(body: StaffKechoRegister): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    deleteStaff(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}delete/${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },
    updateStaff(id: string, body: StaffKechoRegister): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    },
    listCert(): Promise<PromiseApi> {
        const base = `${BaseApi}cert`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    myPlanStaff(): Promise<PromiseApi> {
        const base = `${BaseApi}my-plan`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },

    planStaff(idStaff: string, month: number, year: number): Promise<PromiseApi> {
        const base = `${BaseApi}plan-staff/${idStaff}?month=${month}&year=${year}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    getReliefHistory(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}/relief-history`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    visibilityStaff(body: VisibilityStaff): Promise<PromiseApi> {
        const base = `${BaseApi}visibility`;
        const url = buildUrl(base);
        return axiosAuthClient.put(url, body);
    }
};
export default staffApi;
