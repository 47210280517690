const env = process.env.NODE_ENV;

class Log {
    info: any;
    error: any;
    constructor() {
        this.info = this.buildLog(console.log);
        this.error = this.buildLog(console.error);
    }

    buildLog = (log: any) => {
        return (...message: any) => {
            if (env === 'development') log(...message);
        };
    };
}

export default new Log();
