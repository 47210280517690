import React from 'react';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import UIManager from 'core/UIManager';
import defaultApi from 'services/api/defaultApi';
import RejectTimesheetModal from './RejectTimesheetModal';

interface MoreApproveRejectModalProps {
    onClose: () => void;
    callback: () => void;
    selected: any;
    status: string;
    type: string;
}

const MoreApproveRejectModal = ({ onClose, callback, selected, status, type }: MoreApproveRejectModalProps) => {
    const handleContinue = async () => {
        if (type === 'approve') {
            await handleApprove();
        } else {
            await handleReject();
        }
    };

    const handleApprove = async () => {
        const param = {
            reliefIds: selected.map((item: any) => item.relief.id),
            status
        };
        const response: any = await defaultApi.updateStatusTimeSheet(param);
        if (response.status === 200) {
            UIManager.showAlert({
                message: `Approve ${selected.length} timesheet successfully!`,
                alert: {
                    color: 'success'
                }
            });
            onClose();
            callback();
        }
    };

    const handleReject = async () => {
        UIManager.showModal({
            content: (onCloseReject: any) => (
                <RejectTimesheetModal
                    onCloseReject={onCloseReject}
                    onClose={onClose}
                    relief={selected}
                    callback={callback}
                    type="checkAll"
                />
            )
        });
    };

    return (
        <CardContent>
            <Grid container>
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div">
                        One or more of the selected timesheets are already in other state.
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        You can only {type} timesheets that are currently waiting for approval.
                    </Typography>
                </Grid>
                <Grid container xs={12} justifyContent="center" style={{ marginTop: 20 }}>
                    <Button variant="outlined" onClick={onClose} style={{ border: '1px solid #3F51B5', color: '#3F51B5', marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleContinue} style={{ backgroundColor: '#3F51B5' }}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default MoreApproveRejectModal;
