import React from 'react';
import { Button, styled } from '@mui/material';
import { IconCircleX } from '@tabler/icons';

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    '& .action': {
        marginTop: '20px'
    },
    '& svg': {
        stroke: '#fff',
        fill: '#dd3434'
    }
}));

interface ICheckInOut {
    onClose: any;
}

const CheckInOutSuccess = ({ onClose }: ICheckInOut) => {
    return (
        <>
            <ContentWrapper>
                <IconCircleX stroke={1.5} size="40px" />
                <div>
                    <p>You are encountering an error.</p>
                    <span>Please try again!</span>
                </div>
                <div className="action">
                    <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={onClose}>
                        Close
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default CheckInOutSuccess;
