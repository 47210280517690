import { useEffect, useState } from 'react';
// project imports

// assets

import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project imports
import ModalCustom from 'components/Modal/ModalCustom';
import { Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi, UserApi, clusterApi } from 'services/api';

// assets

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    clusterEdit?: any;
}

const ClusterModal = (props: IProps) => {
    const classes = useStyles();
    const { clusterEdit, callback, onClose } = props;
    const theme = useTheme();
    const centerIds = clusterEdit
        ? clusterEdit.center.map((el: any) => {
              return el.id;
          })
        : [];
    const [userList, setUserList] = useState<any>([]);
    const [centerList, setCenterList] = useState<any>([]);
    useEffect(() => {
        handleGetUserList();
        handleGetCenterList();
    }, []);

    const handleSubmitQualification = async (obj: any, config: any) => {
        const managerObj = userList.find((item: any) => item.id === obj.manager);
        const payload = {
            nameCluster: obj.name,
            centerIds: selectedNames.join(','),
            user: managerObj
        };
        if (clusterEdit) {
            const id = clusterEdit.id;
            const { data } = await clusterApi.updateCluster(id, payload);
            if (data) {
                UIManager.showAlert({
                    message: 'Update successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        } else {
            const { data } = await clusterApi.createCluster(payload);
            if (data) {
                UIManager.showAlert({
                    message: 'Create successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };
    const handleGetUserList = async () => {
        const param = {
            role: Roles.Cluster,
            haveCluster: clusterEdit ? 'true' : 'false'
        };
        const { dataList } = await UserApi.listUser(param);
        if (dataList) setUserList(dataList);
    };
    const handleGetCenterList = async () => {
        const param = {
            haveCluster: 'false'
        };
        const { dataList } = clusterEdit ? await CentreApi.listCentre() : await CentreApi.listCentre(param);
        if (dataList) {
            setCenterList(dataList);
        }
    };

    const [selectedNames, setSelectedNames] = useState(centerIds);
    return (
        <div style={{ width: '30vw' }}>
            <Formik
                initialValues={{
                    name: clusterEdit?.nameCluster,
                    manager: clusterEdit?.staffs[0]?.user?.id,
                    centers: selectedNames
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    manager: Yup.string().max(255).required('Manager is required')
                    // centers: Yup.array().min(1).required('Center is required'),
                })}
                onSubmit={handleSubmitQualification}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                    <ModalCustom
                        onSave={submitForm}
                        disabled={isSubmitting}
                        onClose={onClose}
                        onCancel={() => {}}
                        title="Create a Cluster"
                        contentSave={!clusterEdit ? 'Create' : 'Save'}
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2} columns={16}>
                                <Grid item xs={16} sm={8}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.name && errors.name)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-name-register"> Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-name-register"
                                            type="text"
                                            value={values.name}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(touched.manager && errors.manager)}>
                                        <InputLabel id="demo-simple-select-label" style={{ top: '15px' }}>
                                            Manager
                                        </InputLabel>
                                        <Select
                                            style={{ lineHeight: '1.4375em', height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="manager"
                                            value={values.manager}
                                            label="Manager"
                                            onChange={handleChange}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {userList?.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.email}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.manager && errors.manager && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.manager}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl fullWidth error={Boolean(touched.centers && errors.centers)}>
                                <InputLabel id="demo-simple-select-label" style={{ top: '15px' }}>
                                    Center
                                </InputLabel>
                                <Select
                                    style={{ lineHeight: '1.4375em', minHeight: '62px', height: 'auto', marginTop: '8px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="centers"
                                    value={selectedNames}
                                    label="Centers"
                                    multiple
                                    onChange={(e: any) => {
                                        setSelectedNames(e.target.value);
                                    }}
                                    MenuProps={{
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }}
                                    input={<OutlinedInput label="Multiple Select" />}
                                >
                                    {centerList.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nameCenter}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </div>
    );
};

export default ClusterModal;
