import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../header.module.scss';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// icons
import { IconSettings, IconChevronRight, IconChevronDown } from '@tabler/icons';
import { ReactComponent as ClusterIcon } from 'assets/images/icons/topbars/tabler-icon-users.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/topbars/tabler-icon-user.svg';
import { ReactComponent as RatioIcon } from 'assets/images/icons/topbars/tabler-icon-list.svg';
import { ReactComponent as AbsenceIcon } from 'assets/images/icons/topbars/tabler-icon-calendar-clock.svg';
import { ReactComponent as QualificationIcon } from 'assets/images/icons/topbars/tabler-icon-certificate.svg';
import { ReactComponent as HricIcon } from 'assets/images/icons/topbars/tabler-icon-bag.svg';
import { RedirectPath, Roles } from 'config';

// ==============================|| SEARCH INPUT - MEGA MENu||============================== //
interface IProps {
    userRole: string;
    path: string;
}
const SettingsSection = (props: IProps) => {
    const { userRole, path } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <>
            <div className="wrapper-menu">
                <div className="menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className="group-menu">
                        <IconSettings stroke={1.5} size="20px" />
                        <span style={{ display: 'inline-block', margin: '0px 5px' }}>Settings</span>{' '}
                        {isDropdownVisible ? <IconChevronDown stroke={1.5} size="20px" /> : <IconChevronRight stroke={1.5} size="20px" />}
                    </div>
                    {isDropdownVisible && userRole !== Roles.User && (
                        <div className="dropdown-menu">
                            <ul>
                                <List>
                                    {userRole === Roles.Admin && (
                                        <ListItemButton
                                            disableRipple
                                            component={Link}
                                            to="/cluster/cluster-list"
                                            className={path === RedirectPath.CLUSTER_MANAGEMENT ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <ClusterIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Cluster Management" style={{ whiteSpace: 'nowrap' }} />
                                        </ListItemButton>
                                    )}
                                    {(userRole === Roles.Admin || userRole === Roles.HrAdmin) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/user/user-list"
                                            className={path === RedirectPath.USER_MANAGEMENT ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <UserIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="User Management" />
                                        </ListItemButton>
                                    )}
                                    <ListItemButton
                                        component={Link}
                                        to="/staff/list-ratio"
                                        className={path === RedirectPath.RATIO_LIST ? styles.activeItem : styles.item}
                                    >
                                        <ListItemIcon>
                                            <RatioIcon className={styles.item} />
                                        </ListItemIcon>
                                        <ListItemText primary="List ratio" />
                                    </ListItemButton>
                                    {(userRole === Roles.Admin || userRole === Roles.Cluster || userRole === Roles.Principals) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/absence-list"
                                            className={path === RedirectPath.ABSENCE_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <AbsenceIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Absence" />
                                        </ListItemButton>
                                    )}
                                    {(userRole === Roles.Admin || userRole === Roles.HrAdmin) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/hric-management"
                                            className={path === RedirectPath.HRIC_MANAGEMENT ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <HricIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="HR-IC Management" />
                                        </ListItemButton>
                                    )}
                                    {(userRole === Roles.Admin || userRole === Roles.Cluster || userRole === Roles.Principals) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/qualification-list"
                                            className={path === RedirectPath.QUALIFICATION_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <QualificationIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Qualification" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SettingsSection;
