// project imports

// assets
// import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import { reliefApi } from 'services/api';
import { useEffect, useState } from 'react';
import { formatDateOnly } from 'utils/common/formatDate';
import ModalCustom from 'components/Modal/ModalCustom';
import { convertTime } from 'config';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    relief: string;
}

const marginBottom = {
    marginBottom: '8px'
};

const RejectReliefModal = (props: IProps) => {
    const { onClose, relief } = props;
    const [reliefData, setReliefData] = useState<any>();

    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefApi.getReliefDetail(relief);
            if (res) {
                setReliefData(res.data);
            }
        };

        getRelief();
    }, []);

    return (
        <ModalCustom
            sx={{ width: '40vh' }}
            onSave={() => {}}
            onClose={onClose}
            onCancel={onClose}
            title="View Relief Detail"
            contentCancel="Close"
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Center Name: </b>
                        {reliefData?.center?.nameCenter}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Start Date: </b>
                        {formatDateOnly(reliefData?.startDate)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>End Date: </b>
                        {formatDateOnly(reliefData?.endDate)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Start Time: </b>
                        {convertTime(reliefData?.startHour)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>End Time: </b>
                        {convertTime(reliefData?.endHour)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Absent Staff: </b>
                        {reliefData?.absentStaff?.nameStaff}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Reason: </b>
                        {reliefData?.reason}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Qualification: </b>
                        {reliefData?.qualification?.title}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Candidate: </b>
                        {reliefData?.reliefStaff?.email || reliefData?.reliefTicket?.email}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Status: </b>
                        {reliefData?.status}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Reject Reason: </b>
                        {reliefData?.rejectReason}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Cluster Manager: </b>
                        {reliefData?.clusterManager?.user?.name}
                    </Typography>
                </Grid>
            </Grid>
        </ModalCustom>
    );
};

export default RejectReliefModal;
