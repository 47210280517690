import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';
import { CheckInOutRelief } from 'types/kecho/relief';
import { IFilterRequest } from 'types/query/filter';

const BaseApi = '/';

const defaultApi = {
    checkInOutRelief(body: CheckInOutRelief): Promise<PromiseApi> {
        const base = `${BaseApi}relief-attendance`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    getTimeSheetRelief(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}timesheet/${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    updateStatusTimeSheet(body: { reliefIds: string[]; status?: string; rejectReason?: string }): Promise<PromiseApi> {
        const base = `${BaseApi}timesheet`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    },
    getTimeSheetListApproval(params?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}timesheet/list`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    updateTimeSheetTime(body: { timesheetId: string; reliefAttendances: any }): Promise<PromiseApi> {
        const base = `${BaseApi}timesheet/time`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    }
};
export default defaultApi;
