import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'class/';

const classApi = {
    list(params?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}list-class`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    getDetailsById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    }
};
export default classApi;
