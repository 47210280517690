import { LoadingButton } from '@mui/lab';
import { Box, Button, CardContent, Grid, Icon, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { ReactNode, useState } from 'react';
import warningIcon from 'assets/images/pages/warning-icon.svg';

interface IProps {
    onClose: () => void;
    callback: any;
    title: ReactNode | string;
    bodyContent: ReactNode | string;
}

const RatioWarningModal = (props: IProps) => {
    const { onClose, callback, title, bodyContent } = props;
    const [isTLoading, setIsTLoading] = useState<boolean>(false);

    const handleEditRatio = async () => {
        setIsTLoading(true);
        callback && (await callback());
        setIsTLoading(false);
        onClose();
    };

    return (
        <CardContent style={{ padding: '8px 0 10px 0' }}>
            <Grid container>
                <Grid
                    marginBottom="14px"
                    paddingX="20px"
                    display="flex"
                    maxWidth="100%"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box sx={{ display: 'flex' }}>
                        <Icon sx={{ width: '45px', height: '39px' }}>
                            <img style={{ height: '100%', width: '100%' }} src={warningIcon} alt="Warning icon" />
                        </Icon>
                        <Box sx={{ paddingLeft: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant="h2" component="div" style={{ lineHeight: '20px', marginBottom: '8px' }}>
                                Warning!
                            </Typography>
                            <Typography
                                gutterBottom
                                component="div"
                                style={{ fontFamily: 'Inter', lineHeight: '20px', color: '#555555', fontSize: '15px', marginBottom: '0' }}
                            >
                                {title}
                            </Typography>
                        </Box>
                    </Box>

                    <IconButton onClick={onClose} size="small" edge="end" sx={{ height: '30px', width: '30px' }}>
                        <GridCloseIcon />
                    </IconButton>
                </Grid>
                <Grid padding="30px 32px" width="100%" borderTop="1px solid #E3E6EB" borderBottom="1px solid #E3E6EB">
                    <Typography fontFamily="inter" fontSize="15px" fontWeight="500" marginBottom="22px" color="#222222" component="div">
                        {bodyContent}
                    </Typography>
                    <Typography fontFamily="inter" fontSize="15px" fontWeight="500" color="#F44336" component="div">
                        Are you sure you want to continue?
                    </Typography>
                </Grid>
                <Grid paddingX="20px" marginTop="15px" container justifyContent="flex-end">
                    <Button variant="outlined" onClick={onClose} style={{ border: '1px solid #3f51b5', color: '#3f51b5', marginRight: 10 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isTLoading}
                        variant="contained"
                        onClick={handleEditRatio}
                        style={{ backgroundColor: '#3f51b5' }}
                    >
                        Yes
                    </LoadingButton>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default RatioWarningModal;
