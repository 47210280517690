import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Box,
    Button,
    CardContent,
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EmptyData from 'components/errors/empty-data';
import { AUTH_LOCAL_STORAGE_KEY, Order, Roles } from 'config';
import UIManager from 'core/UIManager';
import { upperFirst } from 'lodash';
import { StaffApi } from 'services/api';
import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { upperCaseTextName } from 'utils/common/upperCaseText';
import { DeleteHricModal, HricModal } from './components';
// import PreviewIcon from '@mui/icons-material/Preview';
// import { v4 as uuidv4 } from 'uuid';
import { StyledTableCell } from 'components/Table/TableCustom';
import moment from 'moment';

// const Option = { Select };
// table header options
const headCells: HeadCell[] = [
    {
        id: 'nameStaff',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'centreKecho',
        numeric: false,
        label: 'Centers',
        align: 'left'
    },

    {
        id: 'email',
        numeric: true,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'createdDate',
        numeric: false,
        label: 'Created Date',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const HricList = () => {
    const theme = useTheme();

    const [selected] = React.useState<string[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [hricList, setHricList] = React.useState<StaffKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({
        order: Order.DESC,
        page: 1,
        take: 10,
        sortColumn: '',
        query: '',
        role: Roles.HrIc
    });
    const navigate = useNavigate();

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetHricList();
        }, 500);
        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetHricList = async () => {
        const { dataList, meta } = await StaffApi.list(filter);
        if (dataList) setHricList(dataList);
        if (meta) setMetaData(meta);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenModalEditHric = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <HricModal onClose={onClose} callback={handleGetHricList} hricEdit={staff} />
        });
    };

    const handleOpenCreateModalHric = () => {
        UIManager.showModal({
            content: (onClose: any) => <HricModal onClose={onClose} callback={handleGetHricList} />
        });
    };

    const handleOpenDeleteModalHric = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteHricModal onClose={onClose} callback={handleGetHricList} hricEdit={staff} />
        });
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        console.log(take);
        setFilter({ ...filter, page: 1, take });
    };

    // const isShowCenterFilter = () => {
    //     return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    // };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="HR-IC management" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder="Search by name"
                                value={filter.query}
                                size="small"
                                style={{ marginRight: '8px' }}
                            />
                        </div>
                    </Grid>
                    <Grid
                        style={{
                            display: userRole === Roles.HrAdmin ? 'block' : 'none'
                        }}
                        item
                        xs={12}
                        sm={6}
                        sx={{ textAlign: 'right' }}
                    >
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Create an HR-IC" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenCreateModalHric}
                                    startIcon={<AddIcon sx={{ fontSize: '1.3rem' }} />}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {hricList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={hricList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {hricList &&
                                    hricList.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.name);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell>{upperCaseTextName(row?.nameStaff)}</TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }} width="40%">
                                                    {row?.centers?.map((item: any) => (
                                                        <Chip label={upperFirst(item?.nameCenter)} sx={{ mb: 1, mr: 0.5 }} />
                                                    ))}
                                                </TableCell>
                                                <TableCell align="left">{row?.email}</TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    {moment(row?.createdAt).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip title="Edit detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{
                                                                    color: '#3F51B5',
                                                                    border: '1px solid #3F51B5',
                                                                    marginRight: 10,
                                                                    display: userRole === Roles.HrAdmin ? 'inline-flex' : 'none'
                                                                }}
                                                                onClick={() => handleOpenModalEditHric(row)}
                                                            >
                                                                <EditIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                Edit
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="View detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                                                onClick={() => {
                                                                    navigate(`/staff/staff-details/${row?.id}`);
                                                                }}
                                                            >
                                                                <VisibilityTwoToneIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                View
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Delete">
                                                            <Button
                                                                variant="outlined"
                                                                style={{
                                                                    color: '#ff0000',
                                                                    border: '1px solid #ff0000',
                                                                    display: userRole === Roles.HrAdmin ? 'inline-flex' : 'none'
                                                                }}
                                                                onClick={() => handleOpenDeleteModalHric(row)}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                                />{' '}
                                                                Remove
                                                            </Button>
                                                        </Tooltip>
                                                        {/* <DeleteStaffKechoModal pagination={pagination} name={row?.nameStaff} staffId={row?.id} /> */}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* table pagination */}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default HricList;
