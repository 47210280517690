// assets
import CloseIcon from '@mui/icons-material/Close';

import { Grid, Typography, CardContent } from '@mui/material';
import UploadFile from '../UploadFile';

interface IProps {
    onClose: () => void;
    onUpload: (file: any) => Promise<boolean>;
    callback: any;
}
// material-ui
const UploadFileModal = (props: IProps) => {
    const { onClose, onUpload, callback } = props;
    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div">
                        Upload File
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer' }}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <UploadFile onClose={onClose} callback={callback} onUpload={(data) => onUpload(data)} />
        </CardContent>
    );
};

export default UploadFileModal;
