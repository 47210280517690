// material-ui
import { Button, CardActions, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// =========================|| DATA WIDGET - INCOMING REQUESTS CARD ||========================= //

interface IProps {
    title: string;
    data: any;
}

const IncomingRequests = ({ title, data }: IProps) => {
    return (
        <MainCard title={title} content={false} style={{ height: 250 }}>
            <PerfectScrollbar>
                <List component="nav" aria-label="main mailbox folders">
                    {data.map((item: any) => {
                        return (
                            <>
                                <ListItemButton key={uuidv4()}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ color: 'success.main' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Staff ${item.staff.nameStaff} - is on leave ${moment(item.dateLeave).format(
                                            'DD-MM-YYYY'
                                        )}`}
                                    />
                                </ListItemButton>
                                <Divider />
                            </>
                        );
                    })}
                </List>
            </PerfectScrollbar>
        </MainCard>
    );
};

export default IncomingRequests;
