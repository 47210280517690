// material-ui
import { Button, CardContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TablePagination, TextField } from '@mui/material';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';

// project imports

import { useSearchParams } from 'react-router-dom';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ClassKecho, IInputRatioSaveClass } from 'types/kecho/class-kecho';
import { IFilterRequest } from 'types/query/filter';
// import icons
// assets
// import './styles.scss';
import { IconDownload } from '@tabler/icons';
import EmptyData from 'components/errors/empty-data';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { CentreApi, RatioApi } from 'services/api';
import staffAttendanceApi from 'services/api/staffAttendance';
import { v4 as uuidv4 } from 'uuid';
import CollapseAttendance from './Components/CollapseAttendance';
import ExportModal from './Components/ExportModal';

const convertData = (data: any[]): any[] => {
    return data.map((item: any) => ({ ...item, key: uuidv4() }));
};

export interface IAttendees {
    id: string;
    staffName: string;
    checkInTime: string;
    checkOutTime: string;
    status: string;
    staff: {
        nameStaff: string;
    };
}

export interface IAttendance {
    date: string;
    attendances: IAttendees[];
}

interface IFilterContext {
    filter: IFilterAttendance;
    setFilter: Dispatch<SetStateAction<IFilterAttendance>>;
}

export interface IFilterAttendance extends IFilterRequest {
    fromDate: string;
    toDate: string;
    centerId: string;
}

export const DEFAULT_REQUEST_ATTENDANCE = {
    ...DEFAULT_REQUEST,
    centerId: '',
    fromDate: moment().format('YYYY/MM/DD'),
    toDate: moment().format('YYYY/MM/DD')
};

export const FilterContext = createContext<IFilterContext>({
    filter: DEFAULT_REQUEST_ATTENDANCE,
    setFilter: () => {}
});

const AttendanceList = () => {
    const classes = useStyles();
    const [params] = useSearchParams();
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;
    const centerId: any = params.get('centerId');
    const date: any = params.get('date');

    // const defaultDate = moment().format(formatDate.DATE_DEFAULT);
    const [attendanceData, setAttendanceData] = useState<IAttendance[]>([]);

    const [filter, setFilter] = useState<IFilterAttendance>({
        ...DEFAULT_REQUEST_ATTENDANCE,
        fromDate: date || moment().format('YYYY/MM/DD'),
        toDate: date || moment().add(1, 'days').format('YYYY/MM/DD')
    });

    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const [total, setTotal] = useState(0);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    useEffect(() => {
        handleGetCentreList();
    }, []);

    useEffect(() => {
        handleGetAttendanceList();
    }, [filter]);

    // get data list attendance
    const handleGetAttendanceList = async () => {
        if (!filter.fromDate || !filter.toDate) return;

        if (filter.centerId || userCenterID || userSelectCenterID) {
            const { data } = await staffAttendanceApi.listAttendance({
                centerId: filter.centerId || userCenterID || userSelectCenterID,
                fromDate: filter.fromDate,
                toDate: filter.toDate,
                take: filter.take,
                page: filter.page
            });
            if (data) {
                setAttendanceData(data?.data);
                setTotal(data?.totalItems);
            }
        }
    };
    // get data list center
    const handleGetCentreList = async () => {
        if (isShowCenterFilter()) {
            const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
            if (dataList) {
                setCentreData(dataList);
                if (centerId) {
                    setFilter({
                        ...filter,
                        centerId
                    });
                } else {
                    setFilter({
                        ...filter,
                        centerId: dataList[0]?.id
                    });
                }
            }
        } else {
            setFilter({
                ...filter,
                centerId: userCenterID
            });
        }
    };

    // action modal
    const handleExport = (dateFrom: string, dateTo: string, check: false, classSelect: ClassKecho) => {
        const classSelectId = classSelect?.map((item: any) => item.idClass);
        const input: IInputRatioSaveClass = {
            classIds: classSelectId,
            dateFrom,
            dateTo
        };
        if (check) return RatioApi.checkDuplicateRatio(input);
        return RatioApi.saveDuplicateRatio(input);
    };

    const handleOpenModalExportAttendance = () => {
        UIManager.showModal({
            content: (onClose: any) => <ExportModal onClose={onClose} onSave={handleExport} listAttendance={attendanceData} />,
            stylesModal: {
                minWidth: isShowCenterFilter() ? 'none' : '600px',
                maxWidth: 'none'
            }
        });
    };

    const renderFilter = () => {
        return (
            <>
                <CardContent style={{ padding: '24px', width: '100%', margin: 0 }}>
                    <Grid container spacing={2} style={{ height: '100%', margin: 0, width: '100%' }}>
                        <Grid item sm={8} style={{ padding: 0 }}>
                            <Grid container>
                                {isShowCenterFilter() && (
                                    <Grid item xs={5} sm={5} sx={{ marginRight: '10px' }}>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="centerId"
                                                label="Center"
                                                displayEmpty
                                                size="small"
                                                value={String(filter.centerId)}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFilter((prev) => {
                                                        return {
                                                            ...prev,
                                                            centerId: value,
                                                            page: 1
                                                        };
                                                    });
                                                }}
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.selectMenu
                                                    }
                                                }}
                                            >
                                                {centreData?.map((centre) => (
                                                    <MenuItem key={uuidv4()} value={centre.id}>
                                                        {centre.nameCenter}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item>
                                    <DateRangeLocalDesktopDatePicker
                                        isRequired
                                        dateSx={{ width: '100%' }}
                                        fromDateValue={filter.fromDate}
                                        toDateValue={filter.toDate}
                                        views={['year', 'month', 'day']}
                                        renderInput={(props: any) => <TextField sx={{ width: '100%' }} size="small" {...props} />}
                                        onChange={(value) => {
                                            if (value.errorText) {
                                                setAttendanceData([]);
                                                setTotal(0);
                                            }
                                            setFilter((prev) => {
                                                return { ...prev, fromDate: value.fromDate, toDate: value.toDate };
                                            });
                                        }}
                                        onError={() => {
                                            setAttendanceData([]);
                                            setTotal(0);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={4} style={{ textAlign: 'right', padding: 0 }}>
                            <Button
                                variant="outlined"
                                style={{ backgroundColor: '#3F51B5', outline: 'none', color: 'white' }}
                                onClick={handleOpenModalExportAttendance}
                            >
                                <IconDownload style={{ width: 22, marginRight: 8 }} /> Export
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        );
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const renderAttendanceList = () => {
        return (
            <FilterContext.Provider
                value={{
                    filter,
                    setFilter
                }}
            >
                <CardContent style={{ paddingTop: 0 }}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2} style={{ width: '100%', marginLeft: 0 }}>
                        <Grid item xs={12} sm={12} style={{ paddingLeft: 0 }}>
                            {attendanceData && attendanceData?.length > 0 ? (
                                <>
                                    {attendanceData.map((attendance: IAttendance, index: number) => {
                                        return (
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }} key={index}>
                                                <CollapseAttendance attendance={attendance} key={attendance.date} />
                                            </Paper>
                                        );
                                    })}
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={total}
                                        rowsPerPage={filter.take}
                                        page={filter.page - 1}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : (
                                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <EmptyData />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </FilterContext.Provider>
        );
    };

    return (
        <Grid className="container">
            <div style={{ backgroundColor: 'white' }}>
                {renderFilter()}
                {renderAttendanceList()}
            </div>
        </Grid>
    );
};

export default AttendanceList;
