import { useState } from 'react';
import { Link } from 'react-router-dom';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import styles from '../header.module.scss';

// icons
import { IconChevronDown, IconChevronRight } from '@tabler/icons';
import { ReactComponent as StaffDeployment } from 'assets/images/icons/topbars/StaffDeployment.svg';
import { ReactComponent as StaffList } from 'assets/images/icons/topbars/StaffList.svg';
import { ReactComponent as StaffPlan } from 'assets/images/icons/topbars/StaffPlan.svg';
import { ReactComponent as ReliefPoolIcon } from 'assets/images/icons/topbars/tabler-icon-relief-pool.svg';
import { ReactComponent as CenterList } from 'assets/images/icons/topbars/CenterList.svg';
import { RedirectPath, Roles } from 'config';

// ==============================|| SEARCH INPUT - MEGA MENu||============================== //
interface IProps {
    userRole: string;
    path: string;
}

const StaffPlanningSection = (props: IProps) => {
    const { userRole, path } = props;

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };
    return (
        <>
            <div className="wrapper-menu">
                <div className="menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className="group-menu">
                        <span>
                            <StaffPlan />
                        </span>
                        <span style={{ display: 'inline-block', margin: '0px 5px' }}>Staff Planning</span>{' '}
                        {isDropdownVisible ? <IconChevronDown stroke={1.5} size="20px" /> : <IconChevronRight stroke={1.5} size="20px" />}
                    </div>
                    {isDropdownVisible && userRole !== Roles.User && (
                        <div className="dropdown-menu">
                            <ul>
                                <List>
                                    {userRole !== Roles.User && (
                                        <>
                                            <ListItemButton
                                                disableRipple
                                                component={Link}
                                                to="/staff/cal-staff-ratio"
                                                className={path === RedirectPath.STAFF_DEPLOYMENT ? styles.activeItem : styles.item}
                                            >
                                                <ListItemIcon>
                                                    <StaffDeployment className={styles.item} />
                                                </ListItemIcon>
                                                <ListItemText primary="Staff Deployment Plan" style={{ whiteSpace: 'nowrap' }} />
                                            </ListItemButton>
                                            <ListItemButton
                                                component={Link}
                                                to="/staff/staff-list"
                                                className={path === RedirectPath.STAFF_LIST ? styles.activeItem : styles.item}
                                            >
                                                <ListItemIcon>
                                                    <StaffList className={styles.item} />
                                                </ListItemIcon>
                                                <ListItemText primary="Staff List" />
                                            </ListItemButton>
                                        </>
                                    )}
                                    {userRole !== Roles.Principals && (
                                        <ListItemButton
                                            component={Link}
                                            to="/centre/centre-list"
                                            className={path === RedirectPath.CENTRE_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <CenterList className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Center List" />
                                        </ListItemButton>
                                    )}
                                    {userRole !== Roles.User && (
                                        <ListItemButton
                                            component={Link}
                                            to="/class/class-list"
                                            className={path === RedirectPath.CLASS_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <StaffPlan className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Class List" />
                                        </ListItemButton>
                                    )}
                                    {userRole !== Roles.User && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/attendance-list"
                                            className={path === RedirectPath.STAFF_ATTENDANCE_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <ReliefPoolIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Attendance List" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default StaffPlanningSection;
