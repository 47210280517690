// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Input, Typography } from '@mui/material';
import { TIMESHEET_STATUS } from 'config';
import UIManager from 'core/UIManager';
import { useRef, useState } from 'react';
import defaultApi from 'services/api/defaultApi';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onCloseReject: () => void;
    onClose: () => void;
    callback: any;
    relief: any;
    type: string;
}

const RejectTimesheetModal = (props: IProps) => {
    const { onCloseReject, onClose, relief, callback, type } = props;
    const [reasons, setReasons] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOk = async () => {
        if (reasons === '') {
            UIManager.showAlert({
                message: 'Can not reject the timesheet!',
                alert: {
                    color: 'error'
                }
            });
            inputRef.current?.focus();
            return;
        }
        const param = {
            reliefIds: type === 'checkAll' ? relief?.map((item: any) => item?.relief?.id) : [relief?.relief?.id],
            status: TIMESHEET_STATUS.REJECTED,
            rejectReason: reasons
        };
        const res = await defaultApi.updateStatusTimeSheet(param);
        if (res) {
            UIManager.showAlert({
                message: 'Reject timesheet successfully!',
                alert: {
                    color: 'success'
                }
            });
            onCloseReject();
            onClose();
            callback();
        }
    };
    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div" style={{ paddingLeft: 20 }}>
                        Please specify the reason for rejection to help the relief adjust their timesheet accurately.
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onCloseReject} style={{ cursor: 'pointer', position: 'relative', top: '-20px', right: '-20px' }}>
                    <CloseIcon />
                </Grid>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Input style={{ width: '100%' }} placeholder="Reasons" onChange={(e) => setReasons(e.target.value)} ref={inputRef} />
                </Grid>
                <Grid container xs={12} justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleOk} style={{ backgroundColor: '#3F51B5' }}>
                        OK
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default RejectTimesheetModal;
