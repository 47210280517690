// material-ui
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';

type ProductCreateDataType = { sales: string; product: string; price: string; colorClass: string };

// table data
const createData = (sales: string, product: string, price: string, colorClass: string = '') => ({ sales, product, price, colorClass });
const formatDecimal = (num: number) => {
    if (num % 1 !== 0) {
        return num.toFixed(2);
    }
    return num;
};
const rows: ProductCreateDataType[] = [
    createData('2136', 'HeadPhone', '$ 926.23'),
    createData('2546', 'Iphone 6', '$ 485.85'),
    createData('2681', 'Jacket', '$ 786.4'),
    createData('2756', 'HeadPhone', '$ 563.45'),
    createData('8765', 'Sofa', '$ 769.45'),
    createData('3652', 'Iphone 7', '$ 754.45'),
    createData('7456', 'Jacket', '$ 743.23'),
    createData('6502', 'T-Shirt', '$ 642.23')
];

// ===========================|| DATA WIDGET - PRODUCT SALES CARD ||=========================== //

interface IProps {
    data: any;
    title: string;
    type: string;
}

const CentreList = ({ data, title, type }: IProps) => (
    <MainCard title={title} content={false} style={{ height: 250 }}>
        <PerfectScrollbar style={{ padding: 0, paddingBottom: 70 }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>Best {type}</TableCell>
                            <TableCell>Brand Ratio (Average)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any, index: number) => (
                            <TableRow hover key={index}>
                                <TableCell sx={{ pl: 3 }}>
                                    <span className={row.colorClass}>{row.nameCenter || row.nameCluster}</span>
                                </TableCell>
                                {row.ratioBrandCenter >= 0 ? (
                                    <TableCell>{formatDecimal(row.ratioBrandCenter)}</TableCell>
                                ) : (
                                    <TableCell>{formatDecimal(row.ratioBrandCluster)}</TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </PerfectScrollbar>
    </MainCard>
);

export default CentreList;
