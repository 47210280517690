// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Typography } from '@mui/material';

// third-party
import Chart, { Props as ChartProps } from 'react-apexcharts';

// project imports
import MainCard from 'components/cards/MainCard';

// assets
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { optionsChart } from './optionsChart';
import { useEffect, useState } from 'react';

// =========================|| CONVERSIONS CHART CARD ||========================= //
interface IProps {
    data?: any;
    title: string;
    type?: string;
    height?: number;
    month?: any;
}

const ChartCard = ({ data, title, type, height, month }: IProps) => {
    // const theme = useTheme();
    const [page, setPage] = useState<number>(1);
    const [seriesChart, setSeriesChart] = useState<any>([]);
    const [optionChart, setOptionChart] = useState<any>({});
    const [isDisabledPrev, setIsDisabledPrev] = useState<boolean>(false);
    const [isDisabledNext, setIsDisabledNext] = useState<boolean>(false);

    const chartRow = 5;
    const totalPage = data?.length / chartRow;
    const dataChart = data?.slice((page - 1) * chartRow, page * chartRow);
    const series = [
        {
            data: dataChart?.map((item: any) =>
                type === 'under'
                    ? item?.sumUnderStaff || item?.numberRatioUnderStaff || 0
                    : item?.sumOverStaff || item?.numberRatioOverStaff || 0
            )
        }
    ];

    const options = optionsChart(dataChart?.map((item: any) => item?.nameClass || item?.nameCenter));

    useEffect(() => {
        setSeriesChart(series);
        setOptionChart(options);

        if (page === 1) {
            setIsDisabledPrev(true);
        } else {
            setIsDisabledPrev(false);
        }
        if (page === Math.ceil(totalPage)) {
            setIsDisabledNext(true);
        } else {
            setIsDisabledNext(false);
        }
    }, [page]);

    const handleNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
        }
    };
    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <MainCard content={false} style={{ padding: 4, width: '100%', height: 600, overflow: 'hidden', overflowY: 'auto' }}>
            <Box sx={{ p: 3, pb: 0 }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h4">({month})</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Chart height={height} series={series ?? seriesChart} options={options ?? optionChart} type="bar" />
            <Box sx={{ '& button': { m: 1 } }}>
                <div className="pagination" style={{ display: data.length > 5 ? 'flex' : 'none', justifyContent: 'end' }}>
                    <Button variant="outlined" className="btn btn-primary btn-sm" onClick={handlePrev} disabled={isDisabledPrev}>
                        Prev
                    </Button>
                    <Button variant="outlined" className="btn btn-primary btn-sm" onClick={handleNext} disabled={isDisabledNext}>
                        Next
                    </Button>
                </div>
            </Box>
        </MainCard>
    );
};

export default ChartCard;
