import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled
} from '@mui/material';
import { Box } from '@mui/system';
import roundedArrowUp from 'assets/images/pages/rounded-arrow-up.svg';
import moment from 'moment';
import React from 'react';
import '../styles.scss';

const CollapseWrapper = styled('div')(({ theme }) => ({
    marginBottom: '10px',
    border: '1px solid #E8EAF6',
    borderRadius: '8px',
    '& .collapse-header': {
        width: '100%',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#303972',
        padding: '0px 16px 0px 0px',
        borderRadius: '7px',
        '& .MuiAccordionSummary-expandIconWrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '30px',
            height: '30px',
            border: '1px solid transparent',
            borderRadius: '50%',
            color: '#3F51B5',
            background: 'white'
        }
    }
}));

interface SizingTableProps {
    data: any;
}
const SizingTableClassBreakdown = ({ data }: SizingTableProps) => {
    const { date, centers } = data;
    return (
        <>
            <div>
                <Typography style={{ fontSize: '18px', fontWeight: 600, color: '#3F51B5', margin: '24px 12px' }}>
                    {moment(date).format('dddd DD/MM/YYYY ')}
                </Typography>
                {centers &&
                    centers.map((center: any) => {
                        return (
                            <React.Fragment key={center.name}>
                                <CollapseWrapper>
                                    <Accordion defaultExpanded>
                                        <div className="collapse-header">
                                            <AccordionSummary
                                                expandIcon={
                                                    <img
                                                        src={roundedArrowUp}
                                                        alt="&#129169;"
                                                        style={{
                                                            width: '14px',
                                                            height: '8px',
                                                            transform: 'rotate(180deg)'
                                                        }}
                                                    />
                                                }
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                sx={{
                                                    width: 'auto'
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    width: '100%',
                                                    fontSize: '16px',
                                                    lineHeight: '19px',
                                                    fontWeight: 600,
                                                    color: '#fff'
                                                }}
                                            >
                                                {center.name}
                                            </Typography>
                                        </div>

                                        <AccordionDetails sx={{ padding: '10px' }}>
                                            <Box component="div" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px' }}>
                                                {center.classes &&
                                                    center.classes.map((classItem: any, index: number) => (
                                                        <Box
                                                            key={`Class-${index + 1}-${classItem.level}-${classItem.student}`}
                                                            component="div"
                                                            sx={{
                                                                overflow: 'hidden',
                                                                border: '1px solid #E3E6EB',
                                                                borderRadius: '8px'
                                                            }}
                                                        >
                                                            <Box
                                                                component="div"
                                                                sx={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '13px 12px',
                                                                    marginRight: 10,
                                                                    borderRadius: '8px',
                                                                    backgroundColor: '#E8EAF6'
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                        fontWeight: 600,
                                                                        color: '#3F51B5',
                                                                        marginLeft: '12px'
                                                                    }}
                                                                >
                                                                    {classItem.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '15px',
                                                                        fontWeight: 500,
                                                                        color: '#3F51B5',
                                                                        lineHeight: '19px',
                                                                        padding: '3.5px 10px',
                                                                        border: '1px solid #3F51B5',
                                                                        borderRadius: '5px',
                                                                        marginLeft: '12px'
                                                                    }}
                                                                >
                                                                    {classItem.level}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '15px',
                                                                        fontWeight: 500,
                                                                        color: '#3F51B5',
                                                                        lineHeight: '19px',
                                                                        padding: '3.5px 10px',
                                                                        border: '1px solid #3F51B5',
                                                                        borderRadius: '5px',
                                                                        marginLeft: '12px'
                                                                    }}
                                                                >
                                                                    {classItem.student} Students
                                                                </Typography>
                                                            </Box>
                                                            {classItem?.sizing.length > 0 && (
                                                                <>
                                                                    <Box>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 600,
                                                                                color: '#3F51B5',
                                                                                margin: '16.5px 20px'
                                                                            }}
                                                                        >
                                                                            Wakyu Optimization
                                                                        </Typography>
                                                                    </Box>
                                                                    <TableContainer
                                                                        className="table-sizing-class-breakdown-page-container"
                                                                        component={Paper}
                                                                        sx={{ minHeight: '274px' }}
                                                                    >
                                                                        <Table
                                                                            aria-labelledby="tableTitle"
                                                                            className="table-sizing-class-breakdown"
                                                                        >
                                                                            <TableHead className="table-sizing-class-breakdown-page-head">
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '33%'
                                                                                        }}
                                                                                        align="left"
                                                                                    >
                                                                                        Teacher Diploma
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            borderLeft: '1px solid #E3E6EB',
                                                                                            borderRight: '1px solid #E3E6EB',
                                                                                            width: '33%'
                                                                                        }}
                                                                                        align="left"
                                                                                    >
                                                                                        Ratio Type
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '33%'
                                                                                        }}
                                                                                        align="left"
                                                                                    >
                                                                                        Wakyu Calculation
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody className="table-sizing-class-breakdown-page-body">
                                                                                <React.Fragment key={classItem.name}>
                                                                                    {classItem?.sizing?.map(
                                                                                        (sizingItem: any, sizingIndex: number) => (
                                                                                            <React.Fragment
                                                                                                key={`${classItem.name}-${sizingItem.diploma}-${sizingIndex}`}
                                                                                            >
                                                                                                {sizingItem.ratios.map(
                                                                                                    (
                                                                                                        ratioItem: any,
                                                                                                        ratioIndex: number
                                                                                                    ) => (
                                                                                                        <React.Fragment
                                                                                                            key={`${classItem.name}-${ratioItem.type}-${ratioIndex}`}
                                                                                                        >
                                                                                                            <TableRow
                                                                                                            // sx={{
                                                                                                            //     backgroundColor:
                                                                                                            //         ratioIndex % 2 !== 0
                                                                                                            //             ? '#F5F5F5'
                                                                                                            //             : '#FFF' // Change the background color for even values
                                                                                                            // }}
                                                                                                            >
                                                                                                                {ratioIndex % 2 === 0 && (
                                                                                                                    <TableCell
                                                                                                                        align="left"
                                                                                                                        rowSpan={2}
                                                                                                                    >
                                                                                                                        <Typography variant="body2">
                                                                                                                            {
                                                                                                                                sizingItem?.diploma
                                                                                                                            }
                                                                                                                        </Typography>
                                                                                                                    </TableCell>
                                                                                                                )}
                                                                                                                <TableCell
                                                                                                                    align="left"
                                                                                                                    sx={{
                                                                                                                        borderLeft:
                                                                                                                            '1px solid #E3E6EB',
                                                                                                                        borderRight:
                                                                                                                            '1px solid #E3E6EB',

                                                                                                                        backgroundColor:
                                                                                                                            ratioIndex %
                                                                                                                                2 !==
                                                                                                                            0
                                                                                                                                ? '#F5F5F5'
                                                                                                                                : '#FFF' // Change the background color for even values
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography variant="body2">
                                                                                                                        {ratioItem?.type}
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                                <TableCell
                                                                                                                    align="left"
                                                                                                                    sx={{
                                                                                                                        backgroundColor:
                                                                                                                            ratioIndex %
                                                                                                                                2 !==
                                                                                                                            0
                                                                                                                                ? '#F5F5F5'
                                                                                                                                : '#FFF', // Change the background color for even values
                                                                                                                        borderLeft:
                                                                                                                            '1px solid #E3E6EB',
                                                                                                                        borderRight:
                                                                                                                            '1px solid #E3E6EB'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Typography variant="body2">
                                                                                                                        {ratioItem?.wakyu}
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                                </React.Fragment>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </>
                                                            )}
                                                            {classItem?.deployment.length > 0 && (
                                                                <>
                                                                    <Box>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 600,
                                                                                color: '#3F51B5',
                                                                                width: '100%',
                                                                                padding: '16.5px 20px',
                                                                                borderTop: '1px solid #E3E6EB',
                                                                                marginTop: '10px'
                                                                            }}
                                                                        >
                                                                            Actual deployment
                                                                        </Typography>
                                                                    </Box>
                                                                    <TableContainer
                                                                        className="table-sizing-class-breakdown-page-container"
                                                                        component={Paper}
                                                                    >
                                                                        <Table
                                                                            aria-labelledby="tableTitle"
                                                                            className="table-sizing-class-breakdown"
                                                                        >
                                                                            <TableHead className="table-sizing-class-breakdown-page-head">
                                                                                <TableRow>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            width: '50%'
                                                                                        }}
                                                                                        align="left"
                                                                                    >
                                                                                        Teacher Diploma
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            borderLeft: '1px solid #E3E6EB',
                                                                                            width: '50%'
                                                                                        }}
                                                                                        align="left"
                                                                                    >
                                                                                        No.of teachers
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody className="table-sizing-class-breakdown-page-body">
                                                                                <React.Fragment key={classItem.name}>
                                                                                    {classItem?.deployment?.map(
                                                                                        (deploymentItem: any, deploymentIndex: number) => (
                                                                                            <React.Fragment
                                                                                                key={`${classItem.name}-${deploymentItem.diploma}-${deploymentIndex}`}
                                                                                            >
                                                                                                <TableRow
                                                                                                    sx={{
                                                                                                        backgroundColor:
                                                                                                            deploymentIndex % 2 !== 0
                                                                                                                ? '#F5F5F5'
                                                                                                                : '#FFF',

                                                                                                        ...(classItem.deployment.length >
                                                                                                            1 && {
                                                                                                            '&:last-child': {
                                                                                                                '.MuiTableCell-root': {
                                                                                                                    borderBottom: 'none'
                                                                                                                }
                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    <TableCell
                                                                                                        align="left"
                                                                                                        sx={{
                                                                                                            borderRight: '1px solid #E3E6EB'
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography variant="body2">
                                                                                                            {deploymentItem?.diploma}
                                                                                                        </Typography>
                                                                                                    </TableCell>

                                                                                                    <TableCell align="left">
                                                                                                        <Typography variant="body2">
                                                                                                            {deploymentItem?.teacher}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                                </React.Fragment>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </>
                                                            )}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </CollapseWrapper>
                            </React.Fragment>
                        );
                    })}
            </div>
        </>
    );
};

export default SizingTableClassBreakdown;
