import { format } from 'date-fns';

const formatDate = (date?: Date | string) => {
    return date ? format(new Date(date), 'd MMM yyyy HH:mm') : format(new Date(), 'd MMM yyyy HH:mm');
};

export const formatDateCustomFormat = (date?: Date | string, formatType?: string) => {
    if (formatType === undefined) return date;
    return date ? format(new Date(date), formatType) : format(new Date(), formatType);
};

export const formatDateOnly = (date?: Date | string) => {
    return date ? format(new Date(date), 'd MMM yyyy') : format(new Date(), 'd MMM yyyy');
};

export default formatDate;
