import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { TypeRouter } from 'config';

const Login = Loadable(lazy(() => import('./Login')));

class AuthModule {
    constructor() {
        addRoutes(
            [
                {
                    path: '/login',
                    element: <Login />
                }
            ],
            TypeRouter.auth
        );
    }
}

export default new AuthModule();
