import React from 'react';
import { Button, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
// import dayjs from 'dayjs';

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    '& .action': {
        marginTop: '20px'
    }
}));

interface ICheckInOut {
    onClose: any;
    dateChecked: any;
    timeChecked: any;
    typeCheck: string;
}

const CheckInOutSuccess = ({ onClose, dateChecked, timeChecked, typeCheck }: ICheckInOut) => {
    const date = moment(dateChecked).format('DD-MM-YYYY');
    const time = moment(timeChecked).format('hh:mm A');
    // const date = dayjs(dateTimeChecked).format('DD-MM-YYYY');
    // const time = dayjs(dateTimeChecked).format('H:mm A');
    return (
        <>
            <ContentWrapper>
                <CheckCircleIcon color="success" fontSize="large" />
                <div>
                    You have {typeCheck === 'check-in' ? 'checked-in' : 'checked-out'} successfully at {date} {time}
                </div>
                <div className="action">
                    <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={onClose}>
                        Close
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default CheckInOutSuccess;
