// assets

import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ModalCustom from 'components/Modal/ModalCustom';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface IProps {
    onClose: () => void;
    onUpload: (file: FormData, isApprove: boolean) => Promise<boolean>;
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 4,
    marginBottom: 4
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
// material-ui
const UploadFileModal = (props: IProps) => {
    const { onClose, onUpload } = props;
    const [approve, setApprove] = useState(true);

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1
    });

    const files = acceptedFiles.map((file: File) => (
        <li key={file.name}>
            <div>
                {file.name} - {file.size} bytes
            </div>
        </li>
    ));

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        const res = await onUpload(formData, approve);
        if (res) onClose();
    };

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );
    return (
        <ModalCustom
            onSave={uploadFile}
            onClose={onClose}
            onCancel={onClose}
            title="Upload File"
            contentCancel="Cancel"
            contentSave="Upload file"
        >
            <>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={approve} onChange={(e) => setApprove(e.target.checked)} />}
                        label="File Approved"
                    />
                </FormGroup>
                <section className="container">
                    <Box {...getRootProps({})} sx={style}>
                        <input {...getInputProps()} />
                        <p>Drag &lsquo;n&lsquo; drop file here, or click to select file</p>
                        <em>(Only *.xlsx excel file will be accepted)</em>
                    </Box>
                    <aside>
                        <ul>{files}</ul>
                    </aside>
                </section>
            </>
        </ModalCustom>
    );
};

export default UploadFileModal;
