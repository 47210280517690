import React from 'react';
import { Box, Button, Tab, TextField, styled } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { StaticDateTimePicker, TimePicker } from '@mui/x-date-pickers';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import dayjs from 'dayjs';
import UIManager from 'core/UIManager';
import CheckInOutSuccess from './CheckInOutSuccess';
import CheckInOutError from './CheckInOutError';
import defaultApi from 'services/api/defaultApi';
import moment from 'moment';

const ToptitleWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '50px',
    padding: '0px 15px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    '& button': {
        width: 'auto',
        justifyContent: 'flex-start',
        padding: '0px',
        border: '1px solid transparent',
        color: '#3f51b5',
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid transparent'
        }
    },
    '& h1': {
        width: '50%',
        marginLeft: '-25px',
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '1.25rem',
        fontWeight: '400'
    }
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '0px 15px',
    backgroundColor: '#fff',
    '& .select-datetime': {
        width: '100%',
        height: 'auto',
        '& .css-hlj6pa-MuiDialogActions-root': {
            display: 'none'
        },
        '& .css-12mho0m-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator': {
            display: 'inline-flex',
            alignItems: 'center'
        }
    },
    '& .show-datetime': {
        width: '100%',
        height: 'auto',
        padding: '20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& .title-datetime': {
            width: '100%',
            height: 'auto',
            marginBottom: '20px',
            fontSize: '1rem',
            fontWeight: '400',
            textTransform: 'uppercase'
        },
        '& .date-time': {
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .show-date, .show-time': {
                width: '50%',
                height: 'auto',
                fontSize: '1rem',
                fontWeight: '400',
                textAlign: 'center'
            }
        }
    },
    '& .action': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0px'
    }
}));

interface ICheckInOut {
    handleRedirectStep: (step: number) => void;
    typeCheck: string;
    reliefId: string;
}
const CheckInOut = ({ handleRedirectStep, typeCheck, reliefId }: ICheckInOut) => {
    const [tab, setTab] = React.useState('1');
    const [dateSelected, setDateSelected] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [timeSelected, setTimeSelected] = React.useState(new Date());
    const [dateChecked, setDateChecked] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [timeChecked, setTimeChecked] = React.useState(moment(new Date()).format('HH:mm:ss'));
    // const [dateTimeChecked, setDateTimeChecked] = React.useState(new Date()); // [date, time]
    // const [dateChecked, setDateChecked] = React.useState(dayjs(new Date()).format('YYYY-MM-DD'));
    // const [timeChecked, setTimeChecked] = React.useState(dayjs(new Date()).format('HH:mm:ss'));

    const [dateStartRelief, setDateStartRelief] = React.useState(); // [date, time]
    const [dateEndRelief, setDateEndRelief] = React.useState(); // [date, time]

    React.useEffect(() => {
        getDetailRelief();
    }, []);
    const getDetailRelief = async () => {
        const res = await defaultApi.getTimeSheetRelief(reliefId);
        if (!res) {
            UIManager.showAlert({
                message: 'Cannot get detail relief!',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        const data = res.data;
        setDateStartRelief(data?.relief?.startDate);
        setDateEndRelief(data?.relief?.endDate);
    };

    const handleBack = () => {
        handleRedirectStep(0);
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    const handleSubmit = async () => {
        const startDate = moment(dateStartRelief).format('YYYY-MM-DD');
        const endDate = moment(dateEndRelief).format('YYYY-MM-DD');
        const data = {
            date: dateChecked,
            reliefId
        };
        const dataCheckIn = {
            ...data,
            checkIn: timeChecked
        };
        const dataCheckOut = {
            ...data,
            checkOut: timeChecked
        };
        if (dateChecked < startDate || dateChecked > endDate) {
            UIManager.showAlert({
                message: `Only submit ${typeCheck} with date in the range of ${startDate} and ${endDate} of the relief ticket`,
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        const res = await defaultApi.checkInOutRelief(typeCheck === 'check-in' ? dataCheckIn : dataCheckOut);
        if (!res) {
            UIManager.showModal({
                content: (onClose: any) => <CheckInOutError onClose={onClose} />
            });
            return;
        }
        UIManager.showModal({
            content: (onClose: any) => (
                <CheckInOutSuccess onClose={onClose} dateChecked={dateSelected} timeChecked={timeSelected} typeCheck={typeCheck} />
            )
        });
    };

    const handleGetDate = (date: any) => {
        setDateSelected(date);
        const dateSelect = moment(date).format('YYYY-MM-DD');
        setDateChecked(dateSelect);
    };
    const handleGetTime = (time: any) => {
        setTimeSelected(time);
        const timeSelect = moment(time).format('HH:mm:ss');
        setTimeChecked(timeSelect);
    };
    // const handleGetDateTime = (date: any) => {
    //     setDateTimeChecked(date);
    //     const dateSelect = moment(date).format('YYYY-MM-DD');
    //     const timeSelect = moment(date).format('HH:mm:ss');
    //     setDateChecked(dateSelect);
    //     setTimeChecked(timeSelect);
    // };

    return (
        <>
            <ToptitleWrapper>
                <Button variant="outlined" onClick={handleBack}>
                    <ArrowBackIcon />
                </Button>
                <h1>{typeCheck === 'check-in' ? 'Check-in' : 'Check-out'}</h1>
            </ToptitleWrapper>
            <ContentWrapper>
                <div className="show-datetime">
                    <span className="title-datetime">Select date & time</span>
                    <div className="date-time">
                        <span className="show-date">
                            <strong>{moment(dateChecked).format('YYYY MMM DD')}</strong>
                        </span>
                        <span className="show-time">
                            <strong>{moment(timeSelected).format('hh:mm A')}</strong>
                        </span>
                    </div>
                </div>
                <div className="select-datetime">
                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Select Date" value="1" />
                                <Tab label="Select Time" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Select date"
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(props: any) => <TextField fullWidth {...props} />}
                                    value={dateSelected}
                                    onChange={handleGetDate}
                                />
                            </LocalizationProvider>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StaticDateTimePicker defaultValue={dayjs(new Date())} onChange={handleGetDateTime} />
                            </LocalizationProvider> */}
                        </TabPanel>
                        <TabPanel value="2">
                            <TimePicker
                                label="Select time"
                                value={timeSelected}
                                renderInput={(props: any) => <TextField fullWidth {...props} />}
                                onChange={handleGetTime}
                            />
                        </TabPanel>
                    </TabContext>
                </div>
                <div className="action">
                    <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default CheckInOut;
