import * as React from 'react';

// import { Link } from 'react-router-dom';
// material-ui
import {
    Button,
    Box,
    CardContent,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    InputBase,
    Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import styles from './reliefTicket.module.scss';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';

import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles } from 'config';
import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';

import { upperFirst } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { CentreApi } from 'services/api';
import { upperCaseTextName } from 'utils/common/upperCaseText';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { CreateReliefModal, UploadFileModal, DeleteReliefTicketModal } from './components';
import EmptyData from 'components/errors/empty-data';
import UIManager from 'core/UIManager';
import reliefTicketApi from 'services/api/reliefTicketApi';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from 'components/Table/TableCustom';

const headers = [
    { label: 'Status', key: 'status' },
    { label: 'Name', key: 'name' },
    { label: 'Interview Date', key: 'interviewDate' },
    { label: 'Mobile No.', key: 'phoneNumber' },
    { label: 'Email Address', key: 'email' },
    { label: 'Qualifications (FCECCE/ EY1/ EY2/ L1/ L2)', key: 'certification[diploma]' },
    { label: 'Relief Type (RT/ RTA)', key: 'reliefType' },
    { label: 'Availability', key: 'availability' },
    { label: 'Residential Area', key: 'area' },
    { label: 'Preferred Working Location', key: 'preferWorkLocation' },
    { label: 'Note', key: 'note' },
    { label: 'Salary (Per Hour)', key: 'salary' },
    { label: 'Commencement Date', key: 'commencementDate' },
    { label: 'Cessation Date', key: 'cessationDate' },
    { label: 'Deployed To', key: 'deployedTo' },
    { label: 'Deployment Period', key: 'deloymentPeriod' },
    { label: 'NRIC', key: 'NRIC' },
    { label: 'Bank', key: 'bank' },
    { label: 'Application Form', key: 'applicationForm' },
    { label: 'Educational Certificates', key: 'educationalCertificates' },
    { label: 'Medical', key: 'medical' },
    { label: 'Contract Signed', key: 'contractSigned' },
    { label: 'Declaration Form', key: 'declarationForm' }
];

const headCells: HeadCell[] = [
    {
        id: 'nameStaff',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'email',
        numeric: true,
        label: 'Email Address',
        align: 'left'
    },

    {
        id: 'mobile',
        numeric: true,
        label: 'Mobile',
        align: 'left'
    },
    {
        id: 'qualification',
        numeric: false,
        label: 'Qualification',
        align: 'left'
    },
    {
        id: 'availability',
        numeric: false,
        label: 'Availability',
        align: 'left'
    },
    {
        id: 'preferred',
        numeric: false,
        label: 'Preferred',
        align: 'left'
    },
    {
        id: 'workingLocation',
        numeric: false,
        label: 'Working Location',
        align: 'left'
    },
    {
        id: 'salary',
        numeric: false,
        label: 'Salary (Per Hour)',
        align: 'left'
    },
    {
        id: 'deployedTo',
        numeric: false,
        label: 'Deployed To',
        align: 'left'
    },
    {
        id: 'deploymentPeriod',
        numeric: false,
        label: 'Deployment Period',
        align: 'left'
    },
    {
        id: 'agent',
        numeric: false,
        label: 'Agent',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const ReliefPool = () => {
    const theme = useTheme();
    const location = useLocation();
    const idRef = location.state?.idRef;
    const [selected] = React.useState<string[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [staffList, setStaffList] = React.useState<ReliefTicketKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [valueSearch, setValueSearch] = React.useState<string>();

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        handleGetReliefTicketList();
    }, [filter]);

    const handleGetReliefTicketList = async () => {
        const { dataList, meta } = await reliefTicketApi.getReliefTicketList(filter);
        if (dataList) {
            setStaffList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setFilter({ ...filter, centreId: dataList[0]?.id });
            setCentreData(dataList);
        }
    };

    const handleSearchInput = () => {
        setFilter({ ...filter, query: valueSearch });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenCreateModalStaff = () => {
        UIManager.showModal({
            content: (onClose: any) => <CreateReliefModal onClose={onClose} callback={handleGetReliefTicketList} />
        });
    };

    const handleOpenModalEditStaff = (reliefTicket: ReliefTicketKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <CreateReliefModal onClose={onClose} callback={handleGetReliefTicketList} reliefTicketEdit={reliefTicket} />
            )
        });
    };

    const handleUploadFileModal = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <UploadFileModal onClose={onClose} onUpload={handleUploadFile} callback={handleGetReliefTicketList} />
            )
        });
    };

    const handleUploadFile = async (file: any) => {
        const { data } = await reliefTicketApi.importReliefTicket({ file });
        if (data) {
            return true;
        }
        return false;
    };

    const handleOpenDeleteModalStaff = (reliefTicket: ReliefTicketKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <DeleteReliefTicketModal onClose={onClose} callback={handleGetReliefTicketList} reliefTicketEdit={reliefTicket} />
            )
        });
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        console.log(take);
        setFilter({ ...filter, page: 1, take });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin;
    };
    const statusColors: { [key: string]: 'default' | 'primary' | 'error' | 'success' } = {
        Active: 'success',
        Pending: 'error'
    };
    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Relief Pool" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className="group-filter" style={{ display: 'flex', marginBottom: 0 }}>
                            <Paper
                                component="form"
                                sx={{
                                    p: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 400,
                                    border: '1px solid #bdbdbd'
                                }}
                                style={{ marginRight: '8px', height: '40px', borderRadius: '8px' }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search by Name, Email, Salary, Qualifications..."
                                    onChange={(e) => {
                                        setValueSearch(e.target.value);
                                    }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchInput}>
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            {isShowCenterFilter() ? (
                                <FormControl style={{ width: 400 }}>
                                    <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="centerId"
                                        label="Center"
                                        value={String(filter.centreId)}
                                        onChange={(e) => {
                                            setFilter({ ...filter, centreId: e.target.value });
                                        }}
                                        size="small"
                                    >
                                        {centreData?.map((centre) => (
                                            <MenuItem key={uuidv4()} value={centre.id}>
                                                {centre.nameCenter}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                ''
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <div className="group-action">
                                <Stack direction="row" spacing={2}>
                                    <Tooltip title="Export CSV" placement="top">
                                        <Button variant="contained" color="secondary" size="large" style={{ padding: '0px' }}>
                                            <CSVLink
                                                data={staffList}
                                                headers={headers}
                                                filename="Relief-Pool.csv"
                                                className={styles.importCsv}
                                            >
                                                <SystemUpdateAltIcon sx={{ fontSize: '1.3rem' }} />
                                            </CSVLink>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Upload CSV" placement="top">
                                        <Button variant="contained" color="secondary" size="large" onClick={handleUploadFileModal}>
                                            <UploadFileIcon sx={{ fontSize: '1.3rem' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Create a relief pool" placement="top">
                                        <Button variant="contained" color="secondary" size="large" onClick={handleOpenCreateModalStaff}>
                                            <AddIcon sx={{ fontSize: '1.3rem' }} />
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {staffList?.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={staffList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {staffList &&
                                    staffList?.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.name);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={row?.id === idRef}
                                            >
                                                <TableCell>{upperCaseTextName(row?.name)}</TableCell>
                                                <TableCell>{upperFirst(row?.email)}</TableCell>
                                                <TableCell>{upperFirst(row?.phoneNumber)}</TableCell>
                                                <TableCell>{upperFirst(row?.certification?.diploma)}</TableCell>
                                                <TableCell>{upperFirst(row?.availability)}</TableCell>
                                                <TableCell>{upperFirst(row?.preferWorkLocation)}</TableCell>
                                                <TableCell>{upperFirst(row?.area)}</TableCell>
                                                <TableCell>{upperFirst(row?.salary)}</TableCell>
                                                <TableCell>{upperFirst(row?.deployedTo)}</TableCell>
                                                <TableCell align="left">{row?.deloymentPeriod}</TableCell>
                                                <TableCell>{upperFirst(row?.agent)}</TableCell>
                                                <TableCell align="left">
                                                    <Chip
                                                        key={index}
                                                        color={statusColors[upperFirst(row?.status)] || 'default'}
                                                        label={row?.status}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip title="Edit detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{
                                                                    color: '#3F51B5',
                                                                    border: '1px solid #3F51B5',
                                                                    marginRight: 10
                                                                }}
                                                                onClick={() => handleOpenModalEditStaff(row)}
                                                            >
                                                                <EditIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                Edit
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Delete">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                                                onClick={() => handleOpenDeleteModalStaff(row)}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                                />{' '}
                                                                Remove
                                                            </Button>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* table pagination */}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ReliefPool;
