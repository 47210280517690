import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';
import { ReliefTicketFilter, ReliefTicketParams } from 'types/kecho/relief-ticket';
import axiosClient from 'utils/axios/axiosClient';

const BaseApi = 'relief-ticket/';

const reliefTicketApi = {
    createReliefTicket(body: ReliefTicketParams): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    getReliefTicketById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    UpdateReliefTicketById(id: string, body: ReliefTicketParams): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    },
    getReliefTicketList(filter: ReliefTicketFilter): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, filter);
        return axiosAuthClient.get(url);
    },
    deleteReliefTicket(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },
    importReliefTicket(body: any): Promise<PromiseApi> {
        const base = `${BaseApi}import`;
        const url = buildUrl(base);
        return axiosClient.post(url, body);
    },
    exportReliefTicket(): Promise<PromiseApi> {
        const base = `${BaseApi}export`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    }
};
export default reliefTicketApi;
