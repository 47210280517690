import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import WarningConflict from 'assets/images/pages/warning-icon.svg';
import { formatDate } from 'config';
import moment from 'moment';

interface IProps {
    onClose: any;
    staffLeave: any[];
    date?: string;
    onSave?: any;
}
export default function WarningConflictModal({ onClose, staffLeave, date, onSave }: IProps) {
    const handleConfirm = async () => {
        if (onSave) await onSave();
        onClose();
    };
    return (
        <Box className="warning-conflict-modal">
            <Card variant="outlined">
                <CardContent className="card-content">
                    <div className="icon">
                        <img src={WarningConflict} alt="warning" />
                    </div>
                    <div className="title">
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: '#222222' }}>Warning!</Typography>
                        <Typography variant="body2" sx={{ mb: 0, color: '#555555', fontWeight: 400 }}>
                            Deployment conflict(s) detected
                        </Typography>
                    </div>
                </CardContent>
                <CardContent className="content">
                    <Typography variant="body2" sx={{ mb: 2, color: '#222222' }}>
                        The teacher(s) <strong> {staffLeave?.map((staff) => staff.nameStaff).join(', ')} </strong> applied for leave(s) on
                        their corresponding deployment time of the date <strong>{moment(date).format(formatDate.DATE_DAY)}</strong>
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: '#222222', fontWeight: 500 }}>
                        Deploying teachers with leave will mark them as present for the day
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: '#F44336' }}>
                        Are you sure you want to continue?
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'end' }}>
                    <Button variant="outlined" onClick={onClose} style={{ border: '1px solid #3f51b5', color: '#3f51b5' }}>
                        No
                    </Button>
                    <Button variant="contained" onClick={handleConfirm} style={{ backgroundColor: '#3f51b5' }}>
                        Yes
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
}
