export function buildUrl(url: any, parameters?: any) {
    let qs = '';
    for (const key in parameters) {
        if (parameters[key] && parameters[key] !== 'ALL') {
            const value = parameters[key];
            qs += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
        }
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1);
        url = `${url}?${qs}`;
    }
    return url;
}

export function appendParamsArray(url: any, params: any) {
    let newUrl = url;
    if (params.length) {
        params.forEach((param: string) => {
            newUrl = `${newUrl}&centerId=${param}`;
        });
    }
    return newUrl;
}
