import { useEffect, useState } from 'react';
// project imports

// assets

import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project imports
import ModalCustom from 'components/Modal/ModalCustom';
import UIManager from 'core/UIManager';
import { qualificationApi } from 'services/api';

// assets

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    qualificationEdit?: any;
}

const QualificationModal = (props: IProps) => {
    const { onClose, callback, qualificationEdit } = props;

    const theme = useTheme();

    const [certification, setCertification] = useState<any[]>([]);
    useEffect(() => {
        getCertification();
    }, []);

    const getCertification = async () => {
        const { data } = await qualificationApi.listCertification();
        if (data) {
            setCertification(data);
        }
    };

    const handleSubmitQualification = (obj: any, config: any) => {
        const payload = {
            title: obj.name,
            price: parseFloat(obj.price),
            certifications: obj.Diplomas?.map((item: any) => ({ id: item }))
        };

        if (!qualificationEdit) {
            qualificationApi.createQualification(payload).then((res) => {
                if (res) {
                    UIManager.showAlert({
                        message: 'Create successfully!',
                        alert: {
                            color: 'success'
                        }
                    });
                    callback();
                    onClose();
                }
            });
        } else {
            qualificationApi.updateQualification(qualificationEdit.id, payload).then((res) => {
                if (res) {
                    UIManager.showAlert({
                        message: 'Update successfully!',
                        alert: {
                            color: 'success'
                        }
                    });
                    callback();
                    onClose();
                }
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    name: qualificationEdit?.title,
                    price: qualificationEdit?.price,
                    Diplomas: qualificationEdit?.qualificationCert?.map((item: any) => item?.cert?.id)
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    price: Yup.number()
                        .min(1, 'Price must be greater than or equal to 1')
                        .max(30, 'Price must be less than or equal to 30')
                        .required('Price is required'),
                    Diplomas: Yup.array().min(1, 'Diplomas is required').required('Diplomas is required')
                })}
                onSubmit={handleSubmitQualification}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                    <ModalCustom
                        onSave={submitForm}
                        onClose={onClose}
                        onCancel={() => {}}
                        title={!qualificationEdit ? 'Create Qualification' : 'Edit Qualification'}
                        contentSave={!qualificationEdit ? 'Create' : 'Save'}
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl fullWidth error={Boolean(touched.name && errors.name)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel htmlFor="outlined-adornment-name-register"> Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-name-register"
                                    type="text"
                                    value={values.name}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.name && errors.name && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.name}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.Diplomas && errors.Diplomas)} style={{ marginTop: 20 }}>
                                <InputLabel id="demo-simple-select-label" style={{ width: 600, marginTop: 0 }}>
                                    Diplomas
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="Diplomas"
                                    label="Diplomas"
                                    onBlur={handleBlur}
                                    multiple
                                    value={Array.isArray(values.Diplomas) ? values.Diplomas : []}
                                    onChange={handleChange}
                                >
                                    {certification?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.title} - {item.diploma}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.Diplomas && errors.Diplomas && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.Diplomas}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.price && errors.price)}
                                sx={{ ...theme.typography.customInput }}
                                style={{ marginTop: 20 }}
                            >
                                <InputLabel htmlFor="outlined-adornment-email-register"> Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="number"
                                    value={values.price}
                                    name="price"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.price && errors.price && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.price}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </>
    );
};

export default QualificationModal;
