// types
import { MenuProps } from 'types/template/menu';
import { createSlice } from '@reduxjs/toolkit';

const drawerOpenItem = localStorage.getItem('drawerOpen');
const drawerOpen = drawerOpenItem !== null ? JSON.parse(drawerOpenItem) : true;

// initial state
const initialState: MenuProps = {
    selectedItem: ['dashboard'],
    drawerOpen
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
            localStorage.setItem('drawerOpen', action.payload);
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer } = menu.actions;
