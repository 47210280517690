import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
    Typography,
    InputAdornment,
    TableContainer,
    Table,
    // TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    // TablePagination,
    TextField,
    Grid
} from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import TeacherIcon from 'assets/images/icons/teacher.svg';
import AssistantIcon from 'assets/images/icons/assistant.svg';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { IFilterRequest } from 'types/query/filter';
import { RatioApi } from 'services/api';
import { ClassKecho } from 'types/kecho/class-kecho';
import moment from 'moment';
import { DEFAULT_PAGINATION } from 'config';
import EmptyData from 'components/errors/empty-data';
import { debounce } from 'lodash';

const convertData = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        name: item.nameStaff,
        level: item.cert?.diploma,
        numberChild: 12
    }));
};

interface IProps {
    onClose: any;
    onSelectStaff: any;
    selectedStaff: any;
    detailClass: ClassKecho;
    // type: string;
    date?: string;
    centreId: string;
}

export default function StaffTable(props: IProps) {
    const { onClose, onSelectStaff, selectedStaff, detailClass, date, centreId } = props;
    const [selectStaff, setSelectStaff] = useState<any>(selectedStaff);

    const [StaffData, setStaffData] = useState<StaffKecho[]>([]);
    const [filter, setFilter] = useState<IFilterRequest>({
        date: moment(date).format('yyyy-MM-DD'),
        centreId,
        level: detailClass.level?.nameLevel,
        ...DEFAULT_PAGINATION
    });

    useEffect(() => {
        handleGetClassData();
    }, [filter]);

    const handleGetClassData = async () => {
        const { dataList } = await RatioApi.listStaff(filter);
        if (dataList) {
            setStaffData(dataList);
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchStaff = debounce(onChange, 500);

    const handleApplyStaff = () => {
        onSelectStaff(selectStaff);
        onClose();
    };

    const renderTableStaff = () => {
        return (
            <Paper>
                {convertData(StaffData).length ? (
                    <>
                        <TableContainer sx={{ maxHeight: 300 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableBody>
                                    {convertData(StaffData).map((row: StaffKecho) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={uuidv4()}
                                                style={{ backgroundColor: row?.isDisabled ? '#e1e1e1' : '', cursor: 'pointer' }}
                                                onClick={() =>
                                                    !row?.isDisabled && row?.id !== selectStaff?.id
                                                        ? setSelectStaff(row)
                                                        : setSelectStaff(null)
                                                }
                                            >
                                                <TableCell align="left">
                                                    <Typography
                                                        component="div"
                                                        className="mb-1"
                                                        style={{ fontWeight: 600, marginBottom: 5 }}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {row?.type === 'teacher' && (
                                                                <img
                                                                    src={TeacherIcon}
                                                                    alt="teacher"
                                                                    style={{ marginRight: 5, width: 20 }}
                                                                />
                                                            )}
                                                            {row?.type === 'assistant' && (
                                                                <img
                                                                    src={AssistantIcon}
                                                                    alt="assistant"
                                                                    style={{ marginRight: 5, width: 20 }}
                                                                />
                                                            )}
                                                            {row.nameStaff}
                                                        </div>
                                                    </Typography>
                                                    <Typography component="div">Email:</Typography>
                                                    <Typography component="div">Diplomas:</Typography>
                                                    <Typography component="div">Title:</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography component="div" style={{ marginBottom: 5, opacity: 0 }}>
                                                        {row.nameStaff}
                                                    </Typography>
                                                    <Typography component="div">{row.email || '-'}</Typography>
                                                    <Typography component="div">
                                                        {row?.staffCert?.map((item: any) => item?.cert?.diploma).join(', ') || '-'}
                                                    </Typography>
                                                    <Typography component="div">
                                                        {row?.staffCert?.map((item: any) => item?.cert?.title).join(', ') || '-'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" className="select-check-staff">
                                                    <Checkbox
                                                        checked={row?.id === selectStaff?.id}
                                                        onChange={(e) =>
                                                            e.target.checked ? setSelectStaff(row) : setSelectStaff(undefined)
                                                        }
                                                        disabled={row?.isDisabled}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} className="empty-icon">
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    return (
        <Paper sx={{ minWidth: '500px', overflow: 'hidden', overflowY: 'auto' }}>
            {/* <Grid container style={{ padding: 10, alignItems: 'center', flexWrap: 'nowrap' }}>
                <Grid item xs={11} textAlign="center" style={{ marginLeft: 50 }}>
                    <Typography variant="h4" gutterBottom component="div" mb={0}>
                        Please select staff for the class
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'end' }}>
                    <CloseIcon />
                </Grid>
            </Grid> */}

            <div style={{ padding: '10px 20px' }}>
                <Grid item xs={12}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearchStaff}
                        placeholder="Search by name"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </div>
            <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />

            {renderTableStaff()}

            <Grid item xs={12} textAlign="center" className="bottom-actions">
                <Button variant="contained" style={{ backgroundColor: '#3F51B5' }} onClick={handleApplyStaff}>
                    Apply
                </Button>
            </Grid>
        </Paper>
    );
}
