/* eslint-disable no-plusplus */
import * as React from 'react';
import styles from '../Relief/reliefList.module.scss';
import './styles.scss';
// material-ui
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
// import MainCard from 'components/cards/MainCard';

// assets
import { IconFileExport } from '@tabler/icons';
import RejectIcon from 'assets/images/icons/page-actions/tabler-icon-ban.svg';
import AcceptIcon from 'assets/images/icons/page-actions/tabler-icon-check.svg';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';

import {
    AUTH_LOCAL_STORAGE_KEY,
    DEFAULT_REQUEST,
    GET_ALL_REQUEST,
    Order,
    RELIEF_AGENT,
    ReasonForRequestRelief,
    Roles,
    TIMESHEET_STATUS,
    formatDate,
    useStyles
} from 'config';

import EmptyData from 'components/errors/empty-data';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { CentreApi } from 'services/api';
import defaultApi from 'services/api/defaultApi';
import { ArrangementOrder, EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { upperCaseTextName } from 'utils/common/upperCaseText';
// import RejectTimesheetModal from './RejectTimesheetModal';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import { StyledTableCell } from 'components/Table/TableCustom';
import { CSVLink } from 'react-csv';
import { useDebounce } from 'services/hooks/useDebounce';
import ConfirmApproveRejectModal from './ConfirmApproveRejectModal';
import DetailTimesheetModal from './DetailTimesheetModal';
import MoreApproveRejectModal from './MoreApproveRejectModal';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'reliefName',
        label: 'Relief Name',
        align: 'left'
    },
    {
        id: 'email',
        label: 'Email',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Timesheet status',
        align: 'left'
    },
    {
        id: 'modifiedBy',
        label: 'Last modified by',
        align: 'left'
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
    selectedRow: any[];
    handleGetList: () => void;
    fromDate: string;
    toDate: string;
    defaultDate: string;
}

interface TimesheetListProps extends EnhancedTableToolbarProps {
    selected: string[];
    selectedRow: any[];
    handleGetList: () => void;
    fromDate: string;
    toDate: string;
    defaultDate: string;
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected,
    selectedRow,
    handleGetList,
    defaultDate,
    fromDate,
    toDate
}: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </StyledTableCell>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            selected={selected}
                            selectedRow={selectedRow}
                            handleGetList={handleGetList}
                            defaultDate={defaultDate}
                            fromDate={fromDate}
                            toDate={toDate}
                        />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| HEADERS EXPORT ||============================== //
const headers = [
    { key: 'reliefID', label: 'Relief ID' },
    { key: 'centre', label: 'Center' },
    { key: 'ptCode', label: 'PT Code' },
    { key: 'cluster', label: 'Cluster' },
    { key: 'reliefAgent', label: 'Relief Agent' },
    { key: 'reliefName', label: 'Name of relief' },
    { key: 'reliefType', label: 'Relief Type (RT/RTA)' },
    { key: 'reliefTypeExt', label: 'Relief Type (Int/Ext)' },
    { key: 'hourlyRate', label: 'Hourly Rate' },
    { key: 'noOfHours', label: 'No. of Hours' },
    { key: 'totalCost', label: 'Total Cost' },
    { key: 'centreHRBudget', label: 'Center/HR Budget' },
    { key: 'reason', label: 'Remarks/Reasons' }
];

// ==============================|| TABLE HEADER TOOLBAR ||============================== //
const EnhancedTableToolbar = ({ numSelected, selected, selectedRow, handleGetList, defaultDate, fromDate, toDate }: TimesheetListProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [csvData, setCsvData] = React.useState<any[]>([{}]);
    const csvLink = React.useRef<any>(null);

    const [isEnableApprove, setIsEnableApprove] = React.useState<boolean>(false);
    const [isEnableReject, setIsEnableReject] = React.useState<boolean>(false);
    const [titleEnableApprove, setTitleEnableApprove] = React.useState<string>('');
    const [titleEnableReject, setTitleEnableReject] = React.useState<string>('');
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    React.useEffect(() => {
        if (selectedRow.length > 1) {
            const isEnable = selectedRow.some((item) => item?.status === TIMESHEET_STATUS.WAITING_APPROVAL);
            const selectItems = selectedRow.filter((item) => item?.status === TIMESHEET_STATUS.WAITING_APPROVAL);
            setIsEnableApprove(isEnable);
            setIsEnableReject(isEnable);
            setTitleEnableApprove(isEnable ? 'Approve' : 'One or more of the selected timesheet are must be state is waiting for approve');
            setTitleEnableReject(isEnable ? 'Reject' : 'One or more of the selected timesheet are must be state is waiting for approve');
            setSelectedItems(selectItems);
        } else {
            setIsEnableApprove(false);
            setIsEnableReject(false);
            setSelectedItems([]);
        }
    }, [selectedRow]);

    const handleApproveTimeSheet = async (status: TIMESHEET_STATUS) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <MoreApproveRejectModal
                    onClose={onClose}
                    status={status}
                    selected={selectedItems}
                    callback={handleGetList}
                    type="approve"
                />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleRejectTimeSheet = (status: TIMESHEET_STATUS) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <MoreApproveRejectModal onClose={onClose} status={status} selected={selectedItems} callback={handleGetList} type="reject" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const [headerExport, setHeaderExport] = React.useState<any[]>(headers);
    const handleExportTimesheet = () => {
        let dataExport: any = [];
        let headersExport: any = [...headers];
        dataExport = selectedRow?.map((row) => {
            const reliefID = row?.relief?.id;
            const centre = row?.relief?.center?.nameCenter || 'N/A';
            const ptCode = row?.relief?.center?.ECDA_ID || 'N/A';
            const cluster = row?.relief?.clusterManager?.nameStaff || 'N/A';
            const reliefAgent = row?.relief?.reliefStaff?.reliefTicket?.agent || 'N/A';
            const reliefName = row?.relief?.reliefStaff?.reliefTicket?.name || 'N/A';
            const reliefType = row?.relief?.reliefStaff?.reliefTicket?.reliefType || 'N/A';
            const reliefTypeExt = row?.relief?.reliefStaff?.reliefTicket?.agent === RELIEF_AGENT.BABILOU ? 'Ext' : 'Int';
            const hourlyRate = row?.relief?.reliefStaff?.reliefTicket?.salary || 'N/A';
            const noOfHours = row?.totalHour || 'N/A';
            const totalCost = row?.relief?.reliefStaff?.reliefTicket?.salary * row?.totalHour || 'N/A';
            const centreHRBudget =
                ReasonForRequestRelief[2].text === row?.relief?.reason || ReasonForRequestRelief[3].text === row?.relief?.reason
                    ? 'HR'
                    : 'Center' || 'N/A';
            const reason = row?.relief?.reason || 'N/A';
            let data: any = {
                reliefID,
                centre,
                ptCode,
                cluster,
                reliefAgent,
                reliefName,
                reliefType,
                reliefTypeExt,
                hourlyRate,
                noOfHours,
                totalCost,
                centreHRBudget,
                reason
            };
            row?.reliefAttendances?.forEach((item: any) => {
                data = { ...data, [moment(item?.date).format('DD/MM/YYYY')]: item?.total || 'N/A' };
            });
            return data;
        });
        const currentDate = fromDate || defaultDate;
        const toDateEx = toDate || defaultDate;
        const dateEx1 = moment(currentDate);
        const dateEx2 = moment(toDateEx);
        for (let i = dateEx1; i.diff(dateEx2, 'days') <= 0; i.add(1, 'days')) {
            headersExport = [...headersExport, { key: i.format('DD/MM/YYYY'), label: i.format('DD/MM/YYYY') }];
        }
        selectedRow?.forEach((item: any, index: number) => {
            const timeItem = item?.reliefAttendances;
            if (currentDate !== toDateEx) {
                const date1 = moment(currentDate);
                const date2 = moment(toDateEx);
                while (date1.diff(date2, 'days') <= 0) {
                    const date = date1.format('DD/MM/YYYY');
                    const itemTime = timeItem.find((it: any) => moment(it?.date).format('DD/MM/YYYY') === date);
                    dataExport[index] = { ...dataExport[index], [date]: itemTime?.total || 'N/A' };
                    date1.add(1, 'days');
                }
            }
        });
        setHeaderExport(headersExport);
        setCsvData(dataExport);
        setTimeout(() => {
            csvLink.current.link.click();
        }, 500);
    };

    return (
        <Toolbar
            sx={{
                p: 0,
                pl: 1,
                pr: 1,
                ...(numSelected > 0 && {
                    color: (theme) => theme.palette.secondary.main
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography color="inherit" variant="h4">
                    {numSelected} Selected
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle">
                    Nutrition
                </Typography>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {numSelected > 1 && (
                <>
                    {(userRole === Roles.Admin ||
                        userRole === Roles.HrIc ||
                        userRole === Roles.HrAdmin ||
                        userRole === Roles.Principals) && (
                        <>
                            <Tooltip title={titleEnableApprove}>
                                <span>
                                    <Button
                                        variant="contained"
                                        style={{ color: 'white', backgroundColor: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                        onClick={() => handleApproveTimeSheet(TIMESHEET_STATUS.APPROVED)}
                                        disabled={!isEnableApprove}
                                    >
                                        Approve
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={titleEnableReject}>
                                <span>
                                    <Button
                                        variant="contained"
                                        style={{ color: 'white', backgroundColor: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                        onClick={() => handleRejectTimeSheet(TIMESHEET_STATUS.REJECTED)}
                                        disabled={!isEnableReject}
                                    >
                                        Reject
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    )}
                    {(userRole === Roles.Admin ||
                        userRole === Roles.HrIc ||
                        userRole === Roles.HrAdmin ||
                        userRole === Roles.Cluster ||
                        userRole === Roles.Principals) && (
                        <>
                            <Tooltip title="Export">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3F51B5', border: '1px solid #3F51B5' }}
                                    onClick={handleExportTimesheet}
                                >
                                    Export
                                </Button>
                            </Tooltip>
                            <CSVLink
                                data={csvData}
                                filename="timesheet_approval.csv"
                                style={{ textDecoration: 'none', color: 'white' }}
                                target="_blank"
                                headers={headerExport}
                                ref={csvLink}
                            />
                        </>
                    )}
                </>
            )}
        </Toolbar>
    );
};

// ==============================|| CUSTOMER LIST ||============================== //
const TimesheetApprovalList = () => {
    const classes = useStyles();
    const [selected, setSelected] = React.useState<string[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
    const [order, setOrder] = React.useState<ArrangementOrder>('desc');
    const [orderBy, setOrderBy] = React.useState<string>('createdAt');
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const defaultDate = moment().format(formatDate.DATE_DEFAULT);
    const [reliefList, setReliefList] = React.useState<any[]>([]);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, order: 'ASC', status: '', centreId: '' });

    // export csv
    const [nameTimeSheet, setNameTimeSheet] = React.useState<string>('');
    const [csvData, setCsvData] = React.useState<any[]>([{}]);
    const csvLink = React.useRef<any>(null);

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        debounceHandleGetList();
    }, [filter]);

    const handleGetList = async () => {
        if (!filter.fromDate || !filter.toDate) return;

        const payload = {
            ...filter,
            page: filter.page,
            limit: filter.limit,
            sortColumn: filter.sortColumn,
            order: filter.order,
            fromDate: filter.fromDate || defaultDate,
            toDate: filter.toDate || defaultDate,
            centreId: filter.centreId || userCenterID || userSelectionCenterID
        };
        const { data } = await defaultApi.getTimeSheetListApproval(payload);
        if (data) {
            setReliefList(data.data);
            setMetaData(data.meta);
            setSelected([]);
            setSelectedRow([]);
        }
    };

    const debounceHandleGetList = useDebounce(handleGetList, 250);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList) {
            setCentreData(dataList);
            setFilter({ ...filter, centreId: '' });
        }
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedId = reliefList.map((n) => n?.relief?.id);
            const newSelected = reliefList.map((n) => n);
            setSelected(newSelectedId);
            setSelectedRow(newSelected);
            return;
        }
        setSelected([]);
        setSelectedRow([]);
    };

    const handleClick = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, row: any) => {
        const id = row?.relief?.id;
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];
        let newSelectedRow: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            newSelectedRow = newSelectedRow.concat(selectedRow, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedRow = newSelectedRow.concat(selectedRow.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, selectedIndex), selectedRow.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        setSelectedRow(newSelectedRow);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    // action Approval, Reject timesheet
    const handleRejectTimesheet = (relief: any, status: TIMESHEET_STATUS) => async () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <ConfirmApproveRejectModal onClose={onClose} relief={relief} callback={handleGetList} status={status} type="reject" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleApproveTimesheet = (relief: any, status: TIMESHEET_STATUS) => async () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <ConfirmApproveRejectModal onClose={onClose} relief={relief} callback={handleGetList} status={status} type="approve" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleOpenModalDetail = (relief: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DetailTimesheetModal onClose={onClose} relief={relief} userRole={userRole} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const [headerExport, setHeaderExport] = React.useState<any[]>(headers);
    const handleExportTimesheet = (relief: any) => async () => {
        const reliefID = relief?.relief?.id;
        const center = relief?.relief?.center?.nameCenter || 'N/A';
        const ptCode = relief?.relief?.center?.ECDA_ID || 'N/A';
        const cluster = relief?.relief?.clusterManager?.nameStaff || 'N/A';
        const reliefAgent = relief?.relief?.reliefStaff?.reliefTicket?.agent || 'N/A';
        const reliefName = relief?.relief?.reliefStaff?.reliefTicket?.name || 'N/A';
        const reliefType = relief?.relief?.reliefStaff?.reliefTicket?.reliefType || 'N/A';
        const reliefTypeExt = reliefAgent === RELIEF_AGENT.BABILOU ? 'Ext' : 'Int';
        const hourlyRate = relief?.relief?.reliefStaff?.reliefTicket?.salary || 'N/A';
        const noOfHours = relief?.totalHour || 'N/A';
        const totalCost = hourlyRate * noOfHours || 'N/A';
        const reason = relief?.relief?.reason || 'N/A';
        const centreHRBudget =
            ReasonForRequestRelief[2].text === reason || ReasonForRequestRelief[3].text === reason ? 'HR' : 'Center' || 'N/A';
        setNameTimeSheet(reliefID);

        const payload: any = {
            reliefID,
            centre: center,
            ptCode,
            cluster,
            reliefAgent,
            reliefName,
            reliefType,
            reliefTypeExt,
            hourlyRate,
            noOfHours,
            totalCost,
            centreHRBudget,
            reason
        };

        let data: any = [{ ...payload }];
        let headersExport: any = [...headers];
        const reliefAttendances = relief?.reliefAttendances;
        const currentDate = filter.fromDate || defaultDate;
        const toDate = filter.toDate || defaultDate;
        const date1 = moment(currentDate);
        const date2 = moment(toDate);

        if (currentDate !== toDate) {
            for (let i = date1; i.diff(date2, 'days') <= 0; i.add(1, 'days')) {
                const date = i.format('DD/MM/YYYY');
                headersExport = [...headersExport, { key: date, label: date }];
                const item = reliefAttendances.find((it: any) => moment(it?.date).format('DD/MM/YYYY') === date);
                data = data.map((it: any) => ({ ...it, [date]: item?.total || 'N/A' }));
            }
        }
        setHeaderExport(headersExport);
        setCsvData(data);
        setTimeout(() => {
            csvLink.current.link.click();
        }, 500);
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const orderSort = filter.order === Order.DESC ? 'asc' : 'desc';
        setFilter({ ...filter, sortColumn, orderSort });
        setOrder(orderSort);
        setOrderBy(sortColumn);
    };

    const renderButtonAction = (relief: any) => {
        const checkStatus = () => {
            return (
                relief?.status === TIMESHEET_STATUS.APPROVED ||
                relief?.status === TIMESHEET_STATUS.REJECTED ||
                relief?.status === TIMESHEET_STATUS.OPEN
            );
        };
        return (
            <>
                {isShowApproveRejectButton() && (
                    <>
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                            disabled={checkStatus()}
                            onClick={handleApproveTimesheet(relief, TIMESHEET_STATUS.APPROVED)}
                        >
                            <img src={AcceptIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Approve
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ color: '#f44336', border: '1px solid #f44336', marginRight: 10 }}
                            disabled={checkStatus()}
                            onClick={handleRejectTimesheet(relief, TIMESHEET_STATUS.REJECTED)}
                        >
                            <img src={RejectIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Reject
                        </Button>
                    </>
                )}
                {isShowExportButton() && (
                    <>
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                            onClick={handleExportTimesheet(relief)}
                        >
                            <IconFileExport size={22} style={{ marginRight: 5 }} /> Export
                        </Button>
                        <CSVLink
                            data={csvData}
                            filename={`timesheet_approval_${nameTimeSheet}.csv`}
                            style={{ textDecoration: 'none', color: 'white' }}
                            target="_blank"
                            headers={headerExport}
                            ref={csvLink}
                        />
                    </>
                )}
            </>
        );
    };

    const renderAction = (relief: any) => {
        return (
            <>
                {userRole !== Roles.User && (
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                        onClick={() => handleOpenModalDetail(relief)}
                    >
                        <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                    </Button>
                )}

                {renderButtonAction(relief)}
            </>
        );
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const isShowApproveRejectButton = () => {
        return userRole === Roles.Admin || userRole === Roles.HrIc || userRole === Roles.HrAdmin || userRole === Roles.Principals;
    };

    const isShowExportButton = () => {
        return (
            userRole === Roles.Admin ||
            userRole === Roles.HrIc ||
            userRole === Roles.HrAdmin ||
            userRole === Roles.Cluster ||
            userRole === Roles.Principals
        );
    };

    const statusColors: { [key: string]: 'default' | 'primary' | 'error' | 'success' | 'info' | 'warning' | 'secondary' } = {
        Open: 'default',
        Waiting: 'warning',
        Rejected: 'error',
        Approved: 'success'
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Timesheet Approval" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={10}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            {isShowCenterFilter() ? (
                                <FormControl style={{ width: 300, marginRight: 8 }}>
                                    <InputLabel size="small" id="demo-simple-select-label">
                                        Center
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="centerId"
                                        label="Center"
                                        size="small"
                                        displayEmpty
                                        value={String(filter.centreId) || 'all'}
                                        onChange={(e: any) => {
                                            setFilter({ ...filter, centreId: e.target.value === 'all' ? '' : e.target.value, page: 1 });
                                        }}
                                        MenuProps={{
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {centreData?.map((centre) => (
                                            <MenuItem key={centre?.id} value={centre.id}>
                                                {centre.nameCenter}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                ''
                            )}
                            <FormControl style={{ height: 40, marginRight: 8 }}>
                                <DateRangeLocalDesktopDatePicker
                                    views={['year', 'month', 'day']}
                                    isRequired
                                    fromDateValue={filter.fromDate}
                                    toDateValue={filter.toDate}
                                    onChange={(value) => {
                                        setFilter((prev) => {
                                            return { ...prev, fromDate: value.fromDate, toDate: value.toDate };
                                        });
                                    }}
                                    onError={() => {
                                        setReliefList([]);
                                        setMetaData({});
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ width: 200 }}>
                                <InputLabel size="small" id="demo-simple-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="status"
                                    size="small"
                                    value={String(filter.status) || 'all'}
                                    label="Status"
                                    onChange={(e) => {
                                        setFilter({ ...filter, status: e.target.value === 'all' ? '' : e.target.value });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.OPEN}>{TIMESHEET_STATUS.OPEN}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.WAITING_APPROVAL}>{TIMESHEET_STATUS.WAITING_APPROVAL}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.APPROVED}>{TIMESHEET_STATUS.APPROVED}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.REJECTED}>{TIMESHEET_STATUS.REJECTED}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {reliefList.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={reliefList.length}
                                selected={selected}
                                selectedRow={selectedRow}
                                handleGetList={handleGetList}
                                defaultDate={defaultDate}
                                fromDate={filter.fromDate || defaultDate}
                                toDate={filter.toDate || defaultDate}
                            />
                            <TableBody>
                                {reliefList &&
                                    reliefList.map((row, index) => {
                                        const isItemSelected = isSelected(row?.relief?.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow hover tabIndex={-1} key={row?.id}>
                                                <TableCell padding="checkbox" sx={{ pl: 3 }} onClick={(event) => handleClick(event, row)}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>{upperCaseTextName(row?.relief?.reliefStaff?.nameStaff) || 'N/A'}</TableCell>
                                                <TableCell>{row?.relief?.reliefStaff?.email || 'N/A'}</TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    <Chip
                                                        key={index}
                                                        color={
                                                            row?.status === TIMESHEET_STATUS.WAITING_APPROVAL
                                                                ? 'warning'
                                                                : statusColors[row?.status] || 'default'
                                                        }
                                                        style={{ marginRight: 8 }}
                                                        label={row?.status}
                                                    />
                                                </TableCell>

                                                <TableCell align="left" className={styles.cell}>
                                                    {row?.modifiedBy || 'N/A'}
                                                </TableCell>
                                                <TableCell align="left">{renderAction(row)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default TimesheetApprovalList;
