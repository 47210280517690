import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import { AUTH_LOCAL_STORAGE_KEY, formatDate, GET_ALL_REQUEST, Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CentreApi } from 'services/api';
import staffAttendanceApi from 'services/api/staffAttendance';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ClassKecho, IInputListAttendance } from 'types/kecho/class-kecho';
import { DEFAULT_REQUEST_ATTENDANCE, IFilterAttendance } from '..';

interface IProps {
    onClose: any;
    onSave: any;
    listAttendance: ClassKecho[];
    callback?: any;
}

export default function ExportModal({ onClose, onSave, listAttendance: listClass, callback }: IProps) {
    const classes = useStyles();
    const [filterExport, setFilterExport] = useState<IFilterAttendance>({
        ...DEFAULT_REQUEST_ATTENDANCE,
        toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
    });

    const [msg, setMsg] = useState<string>('');
    const [isSave, setIsSave] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);

    useEffect(() => {
        handleGetCentreList();
    }, []);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenterName = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center?.nameCenter;

    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const handleCheckValidateTime = async () => {
        if (!filterExport.fromDate || !filterExport.toDate) {
            return;
        }

        setIsSave(true);
        setIsError(false);
        setMsg('Do you want to continue?');
    };

    // get data list center
    const handleGetCentreList = async () => {
        if (isShowCenterFilter()) {
            const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
            if (dataList) {
                setFilterExport({
                    ...filterExport,
                    centerId: dataList[0]?.id
                });
                setCentreData(dataList);
            }
        }
    };

    const handleExport = async () => {
        try {
            setLoading(true);
            const payload: IInputListAttendance = {
                fromDate: filterExport.fromDate,
                toDate: filterExport.toDate,
                centerId: filterExport.centerId || userCenterID || userSelectCenterID,
                take: 365,
                page: filterExport.page
            };

            const res = await staffAttendanceApi.exportAttendance(payload);
            if (!res?.data) {
                UIManager.showAlert({
                    message: 'No data to export',
                    alert: {
                        color: 'error'
                    }
                });
                return;
            }

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nameExport()}_Attendance_${filterExport.fromDate}-${filterExport.toDate}.csv`); // Tên file sẽ được tải xuống
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
            onClose();
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
            setLoading(false);
        }
    };
    const isShowFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const nameExport = () => {
        if (isShowFilter()) {
            return centreData?.filter((item) => item.id === filterExport.centerId)[0]?.nameCenter;
        }
        return userCenterName;
    };

    const renderFilterAttendance = () => {
        return (
            <>
                <Grid container spacing={1} flexWrap="unset">
                    {isShowCenterFilter() && (
                        <Grid item xs={6} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel size="small" id="demo-simple-select-label">
                                    Center
                                </InputLabel>
                                <Select
                                    sx={{
                                        minWidth: '310px'
                                    }}
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="centerId"
                                    label="Center"
                                    displayEmpty
                                    value={String(filterExport.centerId)}
                                    disabled={isSave || !isShowCenterFilter()}
                                    onChange={(e) => {
                                        setFilterExport({
                                            ...filterExport,
                                            centerId: e.target.value
                                        });
                                    }}
                                    MenuProps={{
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }}
                                >
                                    {centreData &&
                                        centreData.map((centre) => (
                                            <MenuItem key={centre.id} value={centre.id}>
                                                {centre.nameCenter}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item style={{ paddingLeft: 10, width: '100%' }}>
                        <DateRangeLocalDesktopDatePicker
                            isRequired
                            isShowErrorMessage={false}
                            sx={{ width: '100%' }}
                            dateSx={
                                !isShowCenterFilter() && {
                                    maxWidth: 'unset',
                                    width: '100%'
                                }
                            }
                            fromDateValue={filterExport.fromDate}
                            toDateValue={filterExport.toDate}
                            views={['year', 'month', 'day']}
                            renderInput={(props: any) => <TextField sx={{ width: '100%' }} size="small" {...props} />}
                            onChange={(value) => {
                                if (value.errorText) {
                                    setIsError(true);
                                    setMsg(value.errorText);
                                } else {
                                    setIsError(false);
                                    setMsg('');
                                }

                                setFilterExport((prev) => {
                                    return { ...prev, fromDate: value.fromDate, toDate: value.toDate };
                                });
                            }}
                            disabled={isSave}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <ModalCustom
            onSave={isSave ? handleExport : handleCheckValidateTime}
            onCancel={isSave && onClose}
            contentSave={isSave ? 'Export' : 'Check'}
            contentCancel={isSave ? 'Cancel' : undefined}
            title="Export Attendance"
            onClose={onClose}
            msgHelper={msg}
            typeHelper={isError ? 'error' : 'warning'}
        >
            {renderFilterAttendance()}
        </ModalCustom>
    );
}
