import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import Badge from '@mui/material/Badge';
import { IconBell } from '@tabler/icons';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_PAGINATION, Roles } from 'config';
import { getTokenfb2 } from 'firebase';
import _ from 'lodash';
import NotificationApi from 'services/api/notificationApi';
import { navigatePath } from 'utils/utils';

// notification status options
const NavigatePath = ['Request relief', 'assign relief ticket', 'CM provide option', 'Reject relief', 'update relief status'];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [noti, setNoti] = useState([]);
    const [unread, setUnread] = useState(0);

    const [open, setOpen] = useState(false);
    const [viewall, setViewall] = useState(false);

    const [notification, setNotification] = useState({ id: '', title: '', body: '' });

    useEffect(() => {
        getToken();
    }, []);

    const getToken = async () => {
        const token = await getTokenfb2();
        if (token) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.FCM_TOKEN, token);
    };

    useEffect(() => {
        // onMessageListener()
        //     .then((payload: any) => {
        //         setNotification(payload?.notification);
        //         UIManager.showAlert({
        //             message: payload?.notification?.title,
        //             alert: {
        //                 color: 'secondary'
        //             },
        //             position: {
        //                 vertical: 'top',
        //                 horizontal: 'right'
        //             }
        //         });
        //     })
        //     .catch((err: any) => console.log('failed: ', err));
        console.log('remove notification');
    });

    useEffect(() => {
        handleGetNoti();
    }, [notification]);

    const handleGetNoti = async () => {
        const { dataList, error, data } = await NotificationApi.list(DEFAULT_PAGINATION);
        if (!error) {
            const newData: any = _.orderBy(dataList, (item) => item.createdAt, ['desc']);
            setNoti(newData);
            setUnread(data.sumFalseStatus);
        }
    };

    const handleViewAll = () => {
        setViewall(true);
        navigatePath('/notification/all');
    };

    const handleReadNoti = async (item: any) => {
        if (!item.status) {
            NotificationApi.readNotification({
                status: true,
                idNotification: item.id
            });
        }

        if (
            userRole === Roles.Admin ||
            userRole === Roles.Principals ||
            userRole === Roles.HrAdmin ||
            userRole === Roles.Cluster ||
            userRole === Roles.HrIc
        ) {
            if (NavigatePath.includes(item.urlRef)) navigate('/staff/relief-list', { state: { idRef: item.idRef }, replace: true });
            else if (item.urlRef === 'Assign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
            else if (item.urlRef === 'Unassign') navigatePath(`/staff/cal-staff-ratio?idRatio=${item.idRef}&date=${item.fromDate}`);
            else if (item.urlRef === 'Add Absence') navigatePath(`/staff/absence-list?idLeave=${item.idRef}`);
        } else {
            navigatePath(`/user/my-plan?idClass=${item.idRef}&date=${item.fromDate}`);
        }
    };
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box
                sx={{
                    ml: 1,
                    mr: 1,
                    [theme.breakpoints.down('md')]: {
                        mr: 1
                    }
                }}
            >
                <Avatar
                    variant="circular"
                    sx={{
                        // ...theme.typography.largeAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#E8EAF6',
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        },
                        width: 48,
                        height: 48,
                        cursor: 'pointer'
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <Badge
                        badgeContent={unread}
                        color="error"
                        sx={{
                            span: {
                                height: 15,
                                width: 15,
                                top: 2,
                                right: 2,
                                minWidth: 15,
                                maxHeight: 15,
                                fontSize: '0.6rem'
                            }
                        }}
                    >
                        <IconBell stroke={1.5} size={20} color="#3F51B5" />
                    </Badge>
                </Avatar>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2} width={350}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">Notification</Typography>
                                                            <Chip
                                                                size="small"
                                                                label={unread}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar style={{ height: '100%', maxHeight: '300px', overflowX: 'hidden' }}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList
                                                        data={noti.slice(0, viewall ? noti.length : 10)}
                                                        onClickItem={handleReadNoti}
                                                        isViewAll={false}
                                                    />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation onClick={() => handleViewAll()}>
                                                {viewall ? 'Hide' : 'View All'}
                                            </Button>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
