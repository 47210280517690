// project imports
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthRoleGuard = ({ children }: GuardProps) => {
    return children;
};

export default AuthRoleGuard;
