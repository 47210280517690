import * as React from 'react';
import styles from './QualificationList.module.scss';
// material-ui
import {
    Box,
    Button,
    CardContent,
    Chip,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';

import { DEFAULT_REQUEST, Order } from 'config';

import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';

import AddIcon from '@mui/icons-material/Add';
import UIManager from 'core/UIManager';
import { qualificationApi } from 'services/api';

import { IFilterRequest } from 'types/query/filter';
import { AddQualificationModal, DeleteModal } from './components';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

import EmptyData from 'components/errors/empty-data';
import { QualificationKecho } from 'types/kecho/relief';
import { StyledTableCell } from 'components/Table/TableCustom';
import { LoadingButton } from '@mui/lab';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'title',
        label: 'Title',
        align: 'left'
    },
    {
        id: 'createdAt',
        label: 'Created At',
        align: 'left'
    },
    {
        id: 'certs',
        label: 'Diplomas',
        align: 'left'
    },
    {
        id: 'price',
        label: 'Price',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {/* {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </TableCell>
                )} */}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const QualificationList = () => {
    const theme = useTheme();

    const [selected] = React.useState<string[]>([]);

    const [qualificationList, setQualificationList] = React.useState<QualificationKecho[]>([]);

    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);

    React.useEffect(() => {
        handleGetList();
    }, []);

    const handleGetList = async () => {
        const { data } = await qualificationApi.listQualification();
        if (data) {
            setQualificationList(data);
        }
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleOpenModalAddQualification = (qualification?: QualificationKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <AddQualificationModal onClose={onClose} callback={handleGetList} qualificationEdit={qualification} />
            ),
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleOpenDeleteModal = (qualification: QualificationKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteModal onClose={onClose} callback={handleGetList} qualification={qualification} />
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Qualification List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={18} sm={18} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Add New Qualification" placement="top">
                                <LoadingButton
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={() => handleOpenModalAddQualification()}
                                    startIcon={<AddIcon sx={{ fontSize: '1.3rem' }} />}
                                >
                                    Add
                                </LoadingButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {qualificationList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={qualificationList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {qualificationList &&
                                    qualificationList.map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row?.id} style={{ width: 100 }}>
                                                <TableCell>{row?.title}</TableCell>

                                                <TableCell align="left" className={styles.cell} style={{ width: 150 }}>
                                                    {moment(row?.createdAt).format('DD/MM/YYYY')}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.qualificationCert?.length > 2 ? (
                                                        <>
                                                            {row?.qualificationCert?.slice(0, 2).map((item: any) => (
                                                                <Chip
                                                                    key={item.id}
                                                                    label={`${item?.cert?.title} - ${item?.cert?.diploma}`}
                                                                    style={{ marginRight: 8 }}
                                                                />
                                                            ))}
                                                            <Tooltip
                                                                title={row?.qualificationCert
                                                                    ?.slice(2)
                                                                    ?.map((item: any) => `${item?.cert?.title} - ${item?.cert?.diploma}`)
                                                                    .join(', ')}
                                                            >
                                                                <Chip
                                                                    label={`+${row?.qualificationCert?.length - 2} more`}
                                                                    style={{ marginRight: 8, cursor: 'help' }}
                                                                    size="small"
                                                                    variant="outlined"
                                                                />
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        row?.qualificationCert?.map((item: any) => (
                                                            <Chip
                                                                key={item.id}
                                                                label={`${item?.cert?.title} - ${item?.cert?.diploma}`}
                                                                style={{ marginRight: 8 }}
                                                            />
                                                        ))
                                                    )}
                                                </TableCell>

                                                <TableCell align="left" className={styles.cell} style={{ width: 150 }}>
                                                    <Chip
                                                        color="primary"
                                                        clickable
                                                        label={`$ ${row?.price}`}
                                                        sx={{
                                                            margin: '0 5px',
                                                            padding: '0 5px',
                                                            width: '100px',
                                                            fontWeight: 500
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" style={{ width: 300 }}>
                                                    <Tooltip title="Edit" placement="top">
                                                        <Button
                                                            variant="outlined"
                                                            style={{
                                                                color: '#3F51B5',
                                                                border: '1px solid #3F51B5',
                                                                marginRight: 10
                                                            }}
                                                            onClick={() => handleOpenModalAddQualification(row)}
                                                        >
                                                            <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} />{' '}
                                                            Edit
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete">
                                                        <Button
                                                            variant="outlined"
                                                            style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                                            onClick={() => handleOpenDeleteModal(row)}
                                                        >
                                                            <DeleteOutlineIcon
                                                                sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                            />{' '}
                                                            Remove
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default QualificationList;
