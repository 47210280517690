import { Roles, SIZING_CLASS_TYPE } from 'config';
import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest, IFilterRequestWithOutPaging } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { appendParamsArray, buildUrl } from '../../utils/buildApi';

const BaseApi = 'center/';

const centreApi = {
    listCentre(params?: IFilterRequestWithOutPaging): Promise<PromiseApi> {
        const base = `${BaseApi}list-center`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    getDetailsById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    listStaffOfCentre(id: string, params: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}${id}/list-staff`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },

    listSizingOfCenter(
        userRole: string,
        centerId: string,
        centerIds: string[] = [],
        classType: string,
        params: IFilterRequest
    ): Promise<PromiseApi> {
        let base = '';

        if (classType === SIZING_CLASS_TYPE.OVERALL) {
            base = `sizing/${BaseApi}${centerId}/${SIZING_CLASS_TYPE.OVERALL}`;
        }
        if (classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN) {
            base = `sizing/${SIZING_CLASS_TYPE.CLASS_BREAKDOWN}`;
        }

        let baseUrl = buildUrl(base, params);

        if (classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN) {
            if (userRole !== Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, centerIds);
            }
            if (userRole === Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, [centerId]);
            }
        }

        return axiosAuthClient.get(baseUrl);
    },

    exportSizing(
        userRole: string,
        centerId: string,
        centerIds: string[] = [],
        classType: string,
        params: { fromDate: string; toDate: string }
    ): Promise<PromiseApi> {
        let base = '';

        if (classType === SIZING_CLASS_TYPE.OVERALL) {
            base = `sizing/${BaseApi}${centerId}/${SIZING_CLASS_TYPE.OVERALL}/csv`;
        }

        if (classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN) {
            base = `sizing/${SIZING_CLASS_TYPE.CLASS_BREAKDOWN}/csv`;
        }

        let baseUrl = buildUrl(base, params);
        if (classType === SIZING_CLASS_TYPE.CLASS_BREAKDOWN) {
            if (userRole !== Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, centerIds);
            }
            if (userRole === Roles.Principals) {
                baseUrl = appendParamsArray(baseUrl, [centerId]);
            }
        }

        return axiosAuthClient.get(baseUrl);
    }
};
export default centreApi;
