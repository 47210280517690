import { lazy, useEffect } from 'react';

// project imports
import GuestGuard from 'core/route-guard/GuestGuard';
import MinimalLayout from 'core/layout/MinimalLayout';
import NavMotion from 'core/layout/NavMotion';
import Loadable from 'components/Loadable';
import { useSelector } from 'store';
import { addRoutes } from 'store/slices/kecho/core/system';
import { TypeRouter } from 'config';

// login routing
const AuthRegister = Loadable(lazy(() => import('template/pages/authentication/authentication-kecho/RegisterKecho')));
const AuthForgotPassword = Loadable(lazy(() => import('template/pages/authentication/authentication3/ForgotPassword3')));

// ==============================|| AUTH ROUTING ||============================== //

const routesAuthTemp = [
    {
        path: '/register',
        element: <AuthRegister />
    },
    {
        path: '/forgot',
        element: <AuthForgotPassword />
    }
];

const LoginRoutes = () => {
    const systemState = useSelector((state) => state.system);
    const { routesAuth } = systemState;

    useEffect(() => {
        addRoutes(routesAuthTemp, TypeRouter.auth);
    }, []);

    return {
        path: '/',
        element: (
            <NavMotion>
                <GuestGuard>
                    <MinimalLayout />
                </GuestGuard>
            </NavMotion>
        ),
        children: routesAuth
    };
};

export default LoginRoutes;
