// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';

// types

// ----------------------------------------------------------------------

const initialState: { filter: { centerId: string | null } } = {
    filter: {
        centerId: ''
    }
};

const selectCenter = createSlice({
    name: 'selectCenter',
    initialState,
    reducers: {
        setFilter(state, action) {
            state.filter = action.payload;
        }
    }
});

// Reducer
export default selectCenter.reducer;

export function setSelectCenter(step: { centerId: string | null }) {
    return () => {
        dispatch(selectCenter.actions.setFilter(step));
    };
}
