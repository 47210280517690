// import { lazy } from 'react';
import { useEffect } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

// routes
// import { useSelector } from 'store';
// import Loadable from 'components/Loadable';

import AuthenticationRoleRoutes from './AuthenicationRoleRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import TemplateRoutes from './TemplateRoutes';
import { AUTH_LOCAL_STORAGE_KEY } from 'config';

// const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
// const PagesLanding = Loadable(lazy(() => import('template/pages/landing-kecho')));
// const AppCentreList = Loadable(lazy(() => import('views/main/center/CenterList')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN) || '';
    useEffect(() => {
        const urlHref = window.location.href;
        if (!accessToken && urlHref.includes('/staff/attendance')) {
            navigate(urlHref);
        }
        if (!accessToken) navigate('/login');
        if (location?.pathname === '/' || location?.pathname === '/home') {
            accessToken ? navigate('/user/my-plan') : navigate('/login');
        }
    }, []);

    const routesTemp = [AuthenticationRoutes, AuthenticationRoleRoutes, TemplateRoutes()];
    const routesProd = [LoginRoutes(), MainRoutes()];

    // const { currentUser } = useSelector((state) => state.user);
    const routes = [
        // { path: '/', element: <AppCentreList /> },
        ...routesProd, // ---------------------routes for product -----------------------
        ...routesTemp // ---------------------router for template -----------------------
    ];
    return useRoutes(routes);
}
