import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';
import StaffPlanning from '../CalculationStaffRatio/List';
import StaffList from '../StaffList';
import ClassList from 'modules/main/class/ClassList';
import CentreList from 'modules/main/center/CenterList';
import './styles.scss';
import { AUTH_LOCAL_STORAGE_KEY, RedirectPath, Roles } from 'config';
import { useNavigate } from 'react-router-dom';
import AttendanceList from '../AttendanceList';

interface ReliefManagementTabsProps {
    tab?: string;
}
const StaffPlanningTabs = ({ tab }: ReliefManagementTabsProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const path = window.location.pathname;
    const navigate = useNavigate();
    const [value, setValue] = React.useState(tab || '1');

    useEffect(() => {
        if (path.includes(RedirectPath.STAFF_LIST)) {
            setValue('2');
        } else if (path.includes(RedirectPath.CENTRE_LIST)) {
            setValue('3');
        } else if (path.includes(RedirectPath.CLASS_LIST)) {
            setValue('4');
        } else if (path.includes(RedirectPath.STAFF_ATTENDANCE_LIST)) {
            setValue('5');
        } else {
            setValue('1');
        }
    }, [path]);
    const handleChangeTabRelief = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            navigate(RedirectPath.STAFF_DEPLOYMENT);
        } else if (newValue === '2') {
            navigate(RedirectPath.STAFF_LIST);
        } else if (newValue === '3') {
            navigate(RedirectPath.CENTRE_LIST);
        } else if (newValue === '4') {
            navigate(RedirectPath.CLASS_LIST);
        } else if (newValue === '5') {
            navigate(RedirectPath.STAFF_ATTENDANCE_LIST);
        }
    };

    const isShowTabStaff = () => {
        return userRole !== Roles.User;
    };
    const isShowTabCentreList = () => {
        return userRole === Roles.HrAdmin || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.Cluster;
    };
    return (
        <div className="staff-planning">
            <TabContext value={value}>
                <TabList onChange={handleChangeTabRelief} className="staff-planning-list">
                    {isShowTabStaff() && <Tab label="Staff Deployment Plan" value="1" />}
                    {isShowTabStaff() && <Tab label="Staff List" value="2" />}
                    {isShowTabCentreList() && <Tab label="Center List" value="3" />}
                    {isShowTabStaff() && <Tab label="Class List" value="4" />}
                    {isShowTabStaff() && <Tab label="Attendance List" value="5" />}
                </TabList>
                <TabPanel value="1" className="staff-planning-panel staff-deployment-panel">
                    <StaffPlanning />
                </TabPanel>
                <TabPanel value="2" className="staff-planning-panel staff-list-panel">
                    <StaffList />
                </TabPanel>
                <TabPanel value="3" className="staff-planning-panel centre-list-panel">
                    <CentreList />
                </TabPanel>
                <TabPanel value="4" className="staff-planning-panel class-list-panel">
                    <ClassList />
                </TabPanel>
                <TabPanel value="5" className="staff-planning-panel class-list-panel">
                    <AttendanceList />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default StaffPlanningTabs;
