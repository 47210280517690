import React, { useEffect, useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
// import icon
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CheckInOut from './CheckInOut';
import TimeSheet from './TimeSheet';

const AttendanceWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '0px',
    margin: '0px auto',
    textAlign: 'center',
    '& .title-info': {
        margin: '20px 0px',
        '& h1': {
            fontSize: '1.75rem',
            fontWeight: '400',
            marginBottom: '20px'
        },
        '& h4': {
            fontSize: '16px',
            fontWeight: 'normal',
            marginBottom: '20px'
        },
        '& .relief-id': {
            display: 'inline-block',
            color: 'white',
            padding: '10px',
            border: '1px solid transparent',
            background: '#2196f3',
            borderRadius: '20px'
        }
    },
    '& .actions-info': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        padding: '20px',
        marginTop: '40px',
        backgroundColor: '#f5f5f5',
        '& .check-in,.check-out,.timesheet': {
            width: 'calc(100%/3)',
            height: '80px',
            '& button': {
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                backgroundColor: '#3f51b5',
                textTransform: 'none',
                '& svg': {
                    width: '100%'
                }
            }
        }
    }
}));

interface IAttendance {
    typeLoadPage: string;
}

const Attendance = ({ typeLoadPage }: IAttendance) => {
    const { reliefName, centerName, reliefId } = useParams();
    const hrefAttendance = window.location.href;
    const reliefIdUrl = hrefAttendance.split('/').pop();
    const reliefNameUrl = decodeURIComponent(hrefAttendance.split('/')[5]);
    const centerNameUrl = decodeURIComponent(hrefAttendance.split('/')[6]);
    const [reliefIdAttendance, setReliefId] = useState('');
    const [reliefNameAttendance, setReliefName] = useState('');
    const [centerNameAttendance, setCenterName] = useState('');
    useEffect(() => {
        if (typeLoadPage === 'public') {
            setReliefId(reliefIdUrl || '');
            setReliefName(reliefNameUrl || '');
            setCenterName(centerNameUrl || '');
        } else {
            setReliefId(reliefId || '');
            setReliefName(reliefName || '');
            setCenterName(centerName || '');
        }
    }, [typeLoadPage]);

    const [step, setStep] = useState(0);
    const [typeCheck, setTypeCheck] = useState('check-in');

    const handleCheckInOut = (type: string) => {
        setStep(1);
        setTypeCheck(type);
    };

    const handleTimeSheet = () => {
        setStep(2);
    };

    const handleRedirectStep = (back: number) => {
        setStep(back);
    };
    return (
        <>
            {step === 0 && (
                <AttendanceWrapper>
                    <div className="title-info">
                        <Typography variant="h1">Kecho Attendance</Typography>
                        <Typography variant="h4">
                            Welcome <strong>{reliefNameAttendance}</strong>, you have been assigned as a relief for center{' '}
                            <strong>{centerNameAttendance}</strong>
                        </Typography>
                        <span className="relief-id">Relief ID: {reliefIdAttendance}</span>
                    </div>
                    <div className="actions-info">
                        <div className="check-in">
                            <Button variant="contained" onClick={() => handleCheckInOut('check-in')}>
                                <EventAvailableIcon />
                                Check-in
                            </Button>
                        </div>
                        <div className="check-out">
                            <Button variant="contained" onClick={() => handleCheckInOut('check-out')}>
                                <EventBusyIcon />
                                Check-out
                            </Button>
                        </div>
                        <div className="timesheet">
                            <Button variant="contained" onClick={handleTimeSheet}>
                                <EventNoteIcon />
                                Timesheet
                            </Button>
                        </div>
                    </div>
                </AttendanceWrapper>
            )}
            {step === 1 && (
                <>
                    <CheckInOut typeCheck={typeCheck} handleRedirectStep={handleRedirectStep} reliefId={reliefIdAttendance || ''} />
                </>
            )}
            {step === 2 && <TimeSheet handleRedirectStep={handleRedirectStep} reliefId={reliefIdAttendance || ''} />}
        </>
    );
};

export default Attendance;
