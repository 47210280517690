import { useEffect, useState } from 'react';
// project imports

// assets

import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
// third party
import { Formik } from 'formik';
// import * as lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

// project imports
// import AnimateButton from 'components/extended/AnimateButton';

// assets
// import { AxiosError } from 'axios';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { CentreApi } from 'services/api';
import staffApi from 'services/api/staffApi';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { StaffKecho } from 'types/kecho/staff-kecho';
// import useScriptRef from 'services/hooks/useScriptRef';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    staffEdit?: StaffKecho;
}

const Sources = [
    { value: 'FB group', label: 'FB group' },
    { value: 'Neighbourhood', label: 'Neighbourhood' },
    { value: 'Previous contacts', label: 'Previous contacts' },
    { value: 'Others', label: 'Others' }
];

const StaffModal = (props: IProps) => {
    const { onClose, callback, staffEdit } = props;
    // return (
    //     <ModalCustom
    //         onSave={() => {}}
    //         onClose={onClose}
    //         onCancel={() => {}}
    //         title={!staffEdit ? 'Create Staff' : 'Edit Staff'}
    //         contentSave={!staffEdit ? 'Create' : 'Save'}
    //     >
    //         <StaffKechoEdit callback={callback} onClose={onClose} staffEdit={staffEdit} />
    //     </ModalCustom>
    // );

    const theme = useTheme();
    // const scriptedRef = useScriptRef();
    const role = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [startDate, setStartDate] = useState(staffEdit?.startDate || moment().format());
    const [certData, setCertData] = useState([]);
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);

    useEffect(() => {
        handleGetCertList();
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    const handleChangeDate = (newValue: any) => {
        setStartDate(newValue);
    };

    const handleGetCertList = async () => {
        const { dataList } = await staffApi.listCert();
        if (dataList) setCertData(dataList);
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) setCentreData(dataList);
    };

    const handleSubmitStaff = async (obj: any, config: any) => {
        if (!startDate) return;

        const payload = {
            name: obj.name,
            email: obj.email,
            phoneNumber: obj.phoneNumber,
            diplomaIds: obj.Diplomas,
            startDate,
            centerId: obj.centerId,
            sources: obj.sources,
            cost: obj.cost,
            description: obj.description
        };

        if (staffEdit?.id) {
            try {
                const res = await staffApi.updateStaff(staffEdit?.id, payload);
                if (res) {
                    UIManager.showAlert({
                        message: 'Update staff successfully!',
                        alert: {
                            color: 'success'
                        }
                    });
                    callback();
                    onClose();
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const res: any = await staffApi.createStaff(payload);
                if (res?.error?.response?.data?.message === 'email has existed') {
                    UIManager.showAlert({
                        message: 'Email has already existed',
                        alert: {
                            color: 'error'
                        }
                    });
                    return;
                }
                UIManager.showAlert({
                    message: 'Create staff successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            } catch (error) {
                console.log('error: ', error);
            }
        }
    };

    const isShowCenterFilter = () => {
        return role !== Roles.Principals && role !== Roles.User;
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    return (
        <>
            <Formik
                initialValues={{
                    name: staffEdit?.nameStaff,
                    email: staffEdit?.email,
                    phoneNumber: staffEdit?.phoneNumber,
                    Diplomas: staffEdit?.staffCert?.map((item: any) => item.cert.id),
                    startDate,
                    centerId: staffEdit?.center?.id,
                    sources: staffEdit?.sources,
                    cost: staffEdit?.cost,
                    description: staffEdit?.description,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    centerId: isShowCenterFilter() ? Yup.string().max(255).required('Center is required') : Yup.string().max(255),
                    name: Yup.string().max(255).required('Name is required'),
                    email: Yup.string().max(255).email('Email must be a valid email').required('Email is required'),
                    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                    Diplomas: Yup.array().min(1, 'Diplomas is required').required('Diplomas is required'),
                    startDate: Yup.string().required('Start Date is required'),
                    sources: Yup.string().max(255).required('Sources is required'),
                    cost: Yup.number()
                        .max(30, 'Cost must be less than or equal to 30')
                        .min(1, 'Cost must be greater than or equal to 1')
                        .required('Cost per hour is required')
                })}
                onSubmit={handleSubmitStaff}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => {
                    const isDisableSave = () => {
                        return (
                            startDate === '' ||
                            values.name === '' ||
                            values.email === '' ||
                            values.Diplomas === 0 ||
                            values.sources === '' ||
                            !values.cost ||
                            (isShowCenterFilter() && values.centerId === '') ||
                            isSubmitting
                        );
                    };
                    return (
                        <ModalCustom
                            onSave={submitForm}
                            onClose={onClose}
                            onCancel={() => {}}
                            title={!staffEdit ? 'Create Staff' : 'Edit Staff'}
                            contentSave={!staffEdit ? 'Create' : 'Save'}
                        >
                            <form noValidate onSubmit={handleSubmit}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.name && errors.name)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-name-register"> Name</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-name-register"
                                        type="text"
                                        value={values.name}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.name && errors.name && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.name}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-email-register"> Email</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-register"
                                        type="text"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel id="demo-simple-select-label">Phones</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-register"
                                        type="text"
                                        value={values.phoneNumber}
                                        name="phoneNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                        autoComplete="off"
                                    />
                                    {touched.phoneNumber && errors.phoneNumber && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.phoneNumber}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth error={Boolean(touched.Diplomas && errors.Diplomas)} style={{ margin: '8px 0' }}>
                                    <InputLabel id="demo-simple-select-label">Diplomas</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        className="w-100 diplomas-select"
                                        name="Diplomas"
                                        multiple
                                        value={Array.isArray(values?.Diplomas) ? values?.Diplomas : []}
                                        label="Diplomas"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {certData?.map((item: any) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.diploma}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.Diplomas && errors.Diplomas && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.Diplomas}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth error={!startDate} style={{ margin: '8px 0' }}>
                                    <LocalDesktopDatePicker
                                        size="large"
                                        sx={{
                                            maxWidth: 'unset',
                                            width: '100%',
                                            '&.MuiFormControl-root.MuiTextField-root': { width: '100%' }
                                        }}
                                        errorTextSx={{
                                            position: 'relative',
                                            top: '0px',
                                            left: '10px',
                                            marginTop: '3px',
                                            fontSize: '0.75rem'
                                        }}
                                        isRequired
                                        value={startDate}
                                        onChange={handleChangeDate}
                                    />
                                </FormControl>

                                {role !== Roles.Principals && role !== Roles.User && (
                                    <Grid sx={{ mt: 1 }} container justifyContent="space-between">
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <FormControl fullWidth error={Boolean(touched.centerId && errors.centerId)}>
                                                <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="centerId"
                                                    value={values.centerId}
                                                    label="Center"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {centreData?.map((centre) => (
                                                        <MenuItem key={uuidv4()} value={centre.id}>
                                                            {centre.nameCenter}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.centerId && errors.centerId && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.centerId}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                                <FormControl fullWidth error={Boolean(touched.sources && errors.sources)} style={{ margin: '8px 0' }}>
                                    <InputLabel id="demo-simple-select-label">Sources</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="sources"
                                        value={values.sources}
                                        label="sources"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        {Sources?.map((item: any) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.sources && errors.sources && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.sources}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.cost && errors.cost)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-cost-register"> Cost per hour</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-cost-register"
                                        type="number"
                                        value={values.cost}
                                        name="cost"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.cost && errors.cost && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cost}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.description && errors.description)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-desc-register"> Description</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-desc-register"
                                        type="text"
                                        value={values.description}
                                        name="description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.description && errors.description && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.description}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                {/* <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isDisableSave()}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {!staffEdit ? 'Create' : 'Save'}
                                    </Button>
                                </AnimateButton>
                            </Box> */}
                            </form>
                        </ModalCustom>
                    );
                }}
            </Formik>
        </>
    );
};

export default StaffModal;
