import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_LOCAL_STORAGE_KEY, ConfigAzure, ErrRefreshToken, MethodShowAlert, REACT_APP_API_URL } from 'config';
import UIManager from 'core/UIManager';
import authApi from 'services/api/authApi';
import azureClientApplication from 'services/auth/Azure';
import { IErrorResponse } from 'types/kecho/api-promise';

const axiosAuthClient: AxiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*/*',
        accept: '*/*'
        // Authorization: `bearer ${token}`
    }
});

function getLocalRefreshToken() {
    return localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.REFRESH_TOKEN);
}

function handleLogout() {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.ACC_AZURE);
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.TYPE_LOGIN);
    window.location.href = '/login';
}

// Add a request interceptor
axiosAuthClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
        config.headers!.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => ({
        error
    })
);
// refreshTokenNormal if you don't use normal login

const refreshTokenNormal = async (res: AxiosError) => {
    const refreshToken = getLocalRefreshToken();
    if (refreshToken) {
        try {
            const rs = await authApi.refreshToken(refreshToken);
            if (rs.statusCode === 500 || rs.data.statusCode === 401) handleLogout();
            const { accessToken, refreshToken: newRefreshToken } = rs.data;
            if (accessToken) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN, accessToken);
            if (newRefreshToken) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.REFRESH_TOKEN, newRefreshToken);
            const config = res.config;
            config.headers!.Authorization = `Bearer ${accessToken}`;
            return await axiosAuthClient(config);
        } catch (error) {
            handleLogout();
        }
    }

    handleLogout();
    return res;
};

const refreshTokenAzure = async (res: AxiosError) => {
    const refreshToken = getLocalRefreshToken();
    const acc = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACC_AZURE) || '');
    try {
        const resAzure = await azureClientApplication.acquireTokenSilent({
            scopes: ConfigAzure.scopes,
            account: acc
        });
        if (refreshToken && resAzure.accessToken) {
            return await authApi.refreshTokenAzure(refreshToken, resAzure.accessToken).then((rs) => {
                const { accessToken, refreshToken: newRefreshToken } = rs.data;
                if (accessToken) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN, accessToken);
                if (newRefreshToken) localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.REFRESH_TOKEN, newRefreshToken);

                const config = res.config;
                config.headers!.Authorization = `Bearer ${accessToken}`;
                return axiosAuthClient(config);
            });
        }
        handleLogout();
    } catch (error) {
        handleLogout();
    }
    return res;
};

// Add a response interceptor
axiosAuthClient.interceptors.response.use(
    (response: AxiosResponse) => {
        // if (MethodShowAlert.indexOf(response.config.method || '') >= 0)
        //     UIManager.showAlert({
        //         message: 'Response data success',
        //         alert: {
        //             color: 'success'
        //         }
        //     });
        return { ...response, data: response.data, dataList: response.data.data, meta: response.data.meta };
    },
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    (error: AxiosError) => {
        const dataError = error.response?.data as IErrorResponse;
        if (dataError?.code === 401 && dataError?.message === ErrRefreshToken.normal) {
            return refreshTokenNormal(error);
        }
        if (dataError?.code === 401 && dataError?.message === ErrRefreshToken.azure) {
            return refreshTokenAzure(error);
        }

        if (MethodShowAlert.indexOf(error.config.method || '') >= 0)
            UIManager.showAlert({
                message: dataError?.message || 'Connection error',
                alert: {
                    color: 'error'
                }
            });

        return {
            error
        };
    }
);

export default axiosAuthClient;
