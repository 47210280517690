import { PublicClientApplication } from '@azure/msal-browser';
import { ConfigAzure as config } from 'config';

const azureClientApplication = new PublicClientApplication({
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUrl,
        authority: config.authority
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
});

export default azureClientApplication;
