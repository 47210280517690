// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { ReliefStatus } from 'config';
import UIManager from 'core/UIManager';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    reliefId: string;
}

const AskHrIcModal = (props: IProps) => {
    const { onClose, reliefId } = props;
    const navigate = useNavigate();

    const handleOk = async () => {
        const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.PENDING_ASSIGNATION);
        if (res) {
            UIManager.showAlert({
                message: 'Ask HR-IC successfully',
                alert: {
                    color: 'success'
                }
            });
            onClose();
            navigate('/staff/relief-list', { replace: true });
        }
    };

    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h3" gutterBottom component="div" mb={0} ml={6}>
                        Sending relief to HR-IC
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer', position: 'relative', top: '-20px', right: '-20px' }}>
                    <CloseIcon />
                </Grid>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Typography gutterBottom component="div">
                        I'm looking for staff from outside your cluster, the relief will submit to the HR-IC to support
                    </Typography>
                </Grid>
                <Grid container xs={12} justifyContent="center">
                    <Button variant="contained" color="error" onClick={onClose} style={{ marginRight: 20 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleOk} style={{ backgroundColor: '#3F51B5' }}>
                        OK
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default AskHrIcModal;
