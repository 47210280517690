import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'leave/';

const leaveApi = {
    listLeave(params?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}list-leave`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    addAbsence(body?: any): Promise<PromiseApi> {
        const url = `${BaseApi}add-absence`;
        return axiosAuthClient.post(url, body);
    },
    checkAbsence(body?: any): Promise<PromiseApi> {
        const url = `${BaseApi}check-add-absence`;
        return axiosAuthClient.post(url, body);
    },
    deleteAbsence(id?: string): Promise<PromiseApi> {
        const url = `${BaseApi}delete-leave/${id}`;
        return axiosAuthClient.delete(url);
    },
    importApproveLeaveExcel(body: FormData) {
        const base = `${BaseApi}import-approve-leave-excel`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    importPendingLeaveExcel(body: FormData) {
        const base = `${BaseApi}import-pending-leave-excel`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    }
};
export default leaveApi;
