import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './styles.scss';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AUTH_LOCAL_STORAGE_KEY, formatDate } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import CenterSelection from 'assets/images/pages/center-selection.svg';
import EmptyCenter from 'assets/images/pages/error-icon-circle.svg';
import { UserApi } from 'services/api';
import azureClientApplication from 'services/auth/Azure';

const SelectCenter = () => {
    const dataCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center ?? [];
    dataCenters.sort((a: any, b: any) => a.nameCenter.localeCompare(b.nameCenter));
    const [center, setCenter] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCenter(event.target.value as string);
    };

    const handleContinue = () => {
        if (!center) {
            UIManager.showAlert({
                message: 'Please select one center',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID, center);
        setTimeout(() => {
            window.location.href = `/staff/cal-staff-ratio?centreId=${center}&date=${moment().format(formatDate.DATE_DEFAULT)}`;
        }, 1000);
    };

    const handleLogout = async () => {
        try {
            await UserApi.logout();
            await azureClientApplication.logoutPopup();
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.REFRESH_TOKEN);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.ACC_AZURE);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.TYPE_LOGIN);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID);
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.MY_PLAN_DEFAULT_CENTRE);
            window.location.href = '/login';
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <div className="selection-content">
            <div className="content-top">
                <Typography sx={{ fontSize: 36, fontWeight: 700, color: '#3F51B5' }}>KECHO</Typography>
            </div>
            {dataCenters.length > 1 && (
                <Box className="select-center">
                    <Card variant="outlined">
                        <CardContent className="select-center__card-content select-center__card-content__title">
                            <div className="icon">
                                <img src={CenterSelection} alt="center selection" />
                            </div>
                            <div className="title">
                                <Typography sx={{ fontSize: 20, fontWeight: 600, color: '#222222' }}>Center Selection</Typography>
                                <Typography variant="body2" sx={{ mb: 0, color: '#555555', fontWeight: 400 }}>
                                    You are assigned to more than 1 center
                                </Typography>
                            </div>
                        </CardContent>
                        <CardContent className="select-center__card-content">
                            <Typography variant="body2" sx={{ pl: 1, mb: 2, color: '#222222', fontWeight: 500 }}>
                                Please specify which center you want to proceed with
                            </Typography>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel size="small" id="demo-simple-select-label">
                                    Center
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={center}
                                    label="Center"
                                    onChange={handleChange}
                                >
                                    {dataCenters?.map((item: any) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.nameCenter}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'end' }}>
                            <Button variant="contained" onClick={handleContinue} style={{ backgroundColor: '#3f51b5' }}>
                                Continue
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            )}
            {dataCenters.length === 0 && (
                <Box className="empty-center">
                    <Card variant="outlined">
                        <CardContent className="select-center__card-content select-center__card-content__title">
                            <div className="icon">
                                <img src={EmptyCenter} alt="center selection" />
                            </div>
                            <div className="title">
                                <Typography sx={{ fontSize: 20, fontWeight: 600, color: '#222222' }}>Error</Typography>
                                <Typography variant="body2" sx={{ mb: 0, color: '#555555', fontWeight: 400 }}>
                                    You have not been assigned to any center
                                </Typography>
                            </div>
                        </CardContent>
                        <CardContent className="select-center__card-content">
                            <Typography variant="body2" sx={{ mb: 2, color: '#222222', fontWeight: 500 }}>
                                Please login to another account in order to access KECHO
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'end' }}>
                            <Button variant="contained" onClick={handleLogout} style={{ backgroundColor: '#3f51b5' }}>
                                Logout
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            )}
        </div>
    );
};

export default SelectCenter;
