import * as React from 'react';

import './styles.scss';
// material-ui
import {
    Button,
    Box,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';
import { DefaultRootStateProps, EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { StaffKecho } from 'types/kecho/staff-kecho';
import EmptyData from 'components/errors/empty-data';
import UIManager from 'core/UIManager';
import { StaffModal, DeleteStaffModal } from './components';
import { CentreApi, StaffApi } from 'services/api';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import { v4 as uuidv4 } from 'uuid';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { useDispatch, useSelector } from 'store';
import { setStaffListByCenter } from 'store/slices/template/staffList';
import VisibilityModal from './components/VisibilityModal';
import { StyledTableCell } from 'components/Table/TableCustom';

// const Option = { Select };
// table header options
const headCells: HeadCell[] = [
    {
        id: 'nameStaff',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'email',
        numeric: true,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'phone',
        numeric: true,
        label: 'Phone',
        align: 'left'
    },
    {
        id: 'diploma',
        numeric: false,
        label: 'Diplomas',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //
const roles = [Roles.HrIc, Roles.HrAdmin];
const StaffList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const centerID = useSelector((state: DefaultRootStateProps) => state.staffList.filter.centerId);
    const [selected] = React.useState<string[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const [staffList, setStaffList] = React.useState<StaffKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, isTemporary: '' });
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetStaffList();
        }, 300);
        return () => clearTimeout(searchInputTimer);
    }, [filter, centerID, userCenterID]);

    const handleGetStaffList = async () => {
        const { dataList, meta } = await StaffApi.list({ ...filter, centreId: centerID || userCenterID || userSelectionCenterID });
        if (dataList) setStaffList(dataList);
        if (meta) setMetaData(meta);
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            dispatch(setStaffListByCenter({ centerId: centerID || userCenterID || dataList[0]?.id }));
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenModalEditStaff = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <StaffModal onClose={onClose} callback={handleGetStaffList} staffEdit={staff} />
        });
    };

    const handleOpenCreateModalStaff = () => {
        UIManager.showModal({
            content: (onClose: any) => <StaffModal onClose={onClose} callback={handleGetStaffList} />
        });
    };

    const handleOpenModalVisibility = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <VisibilityModal
                    onClose={onClose}
                    callback={handleGetStaffList}
                    centerId={centerID || userCenterID || userSelectionCenterID}
                    isTemporary={filter.isTemporary}
                />
            ),
            stylesModal: {
                minWidth: '900px'
            }
        });
    };

    const handleOpenDeleteModalStaff = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteStaffModal onClose={onClose} callback={handleGetStaffList} staffEdit={staff} />
        });
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Staff List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 10 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder="Search by name"
                                value={filter.query}
                                size="small"
                                style={{ marginRight: '8px' }}
                            />
                            {isShowCenterFilter() ? (
                                <FormControl style={{ width: 400, marginRight: 8 }}>
                                    <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="centerId"
                                        label="Center"
                                        value={String(centerID)}
                                        onChange={(e) => {
                                            dispatch(setStaffListByCenter({ centerId: e.target.value }));
                                            setFilter({ ...filter, page: 1 });
                                        }}
                                        size="small"
                                        MenuProps={{
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }}
                                    >
                                        {centreData?.map((centre) => (
                                            <MenuItem key={uuidv4()} value={centre.id}>
                                                {centre.nameCenter}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                ''
                            )}
                            <FormControl style={{ width: 200 }}>
                                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="isTemporary"
                                    value={filter.isTemporary || 'all'}
                                    label="Filter"
                                    style={{ height: '40px' }}
                                    onChange={(e) => {
                                        setFilter({ ...filter, isTemporary: e.target.value, page: 1 });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="true">Temporary staff</MenuItem>
                                    <MenuItem value="false">Official staff</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }} style={{ display: roles.includes(userRole) ? 'none' : 'flex' }}>
                        <Grid container justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                                onClick={handleOpenModalVisibility}
                            >
                                <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Visibility
                            </Button>
                            <Tooltip title="Create a temporary staff" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenCreateModalStaff}
                                >
                                    <AddIcon sx={{ fontSize: '1.3rem' }} /> Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {staffList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={staffList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {staffList &&
                                    staffList.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.name);
                                        // const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell>{row?.nameStaff || '-'}</TableCell>
                                                <TableCell align="left">{row?.email || '-'}</TableCell>
                                                <TableCell align="left">{row?.phoneNumber || '-'}</TableCell>
                                                <TableCell align="left">
                                                    {row?.staffCert?.map((item: any) => {
                                                        return <Chip key={item?.id} label={item?.cert?.diploma} />;
                                                    })}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Chip
                                                        style={{
                                                            backgroundColor: row?.isHidden ? '#FCE3E1' : '#D4FADE',
                                                            color: row?.isHidden ? '#F44336' : '#09B54E'
                                                        }}
                                                        label={row?.isHidden ? 'Hidden' : 'Visible'}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="end" alignItems="center">
                                                        <Tooltip
                                                            title="Edit detail"
                                                            placement="top"
                                                            style={{
                                                                display:
                                                                    roles.includes(userRole) || !row?.isTemporary ? 'none' : 'inline-flex'
                                                            }}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                style={{
                                                                    color: '#3F51B5',
                                                                    border: '1px solid #3F51B5',
                                                                    marginRight: 10,
                                                                    display:
                                                                        roles.includes(userRole) || !row?.isTemporary
                                                                            ? 'none'
                                                                            : 'inline-flex'
                                                                }}
                                                                onClick={() => handleOpenModalEditStaff(row)}
                                                            >
                                                                <EditIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                Edit
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="View detail" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                                                onClick={() => {
                                                                    window.location.href = `/staff/staff-details/${row?.id}`;
                                                                }}
                                                            >
                                                                <VisibilityTwoToneIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                View
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Plan Of Staff" placement="top">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                                                onClick={() => {
                                                                    window.location.href = `/staff/plan-staff/${row?.id}/${
                                                                        centerID || userCenterID
                                                                    }`;
                                                                }}
                                                            >
                                                                <PreviewIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }}
                                                                />{' '}
                                                                Plan
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip
                                                            placement="top"
                                                            title="Remove"
                                                            style={{ display: roles.includes(userRole) ? 'none' : 'inline-flex' }}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                                                onClick={() => handleOpenDeleteModalStaff(row)}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                                />{' '}
                                                                Remove
                                                            </Button>
                                                        </Tooltip>
                                                        {/* <DeleteStaffKechoModal pagination={pagination} name={row?.nameStaff} staffId={row?.id} /> */}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* table pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default StaffList;
