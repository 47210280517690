import { NavItemType } from 'types';
import application, { dashboard } from './application';
// import dashboard from './dashboard';
// import widget from './widget';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';
// import menuTemp from './menuTemp';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, application]
    // items: [application, dashboard, widget, forms, elements, pages, utilities, support, other, menuTemp]
};

export default menuItems;
