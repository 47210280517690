// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, CardContent, Grid, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import UIManager from 'core/UIManager';

// project imports
import { LeaveApi } from 'services/api';
import { LeaveKecho } from 'types/kecho/leave-kecho';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    leave?: LeaveKecho;
}

const DeleteModal = (props: IProps) => {
    const { onClose, callback, leave } = props;

    const handleDeleteStaff = async () => {
        if (leave?.id) {
            const { data } = await LeaveApi.deleteAbsence(leave?.id);
            if (data) {
                UIManager.showAlert({
                    message: 'Delete successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteStaff}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure you want to delete absence &quot;{leave?.staff?.nameStaff}&quot;?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteModal;
