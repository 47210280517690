import React, { useEffect, useState } from 'react';
import { Grid, Box, CircularProgress, styled, CardContent } from '@mui/material';
// import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone';
import CardTotal from '../components/CardTotal';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import ListClass from '../components/ListClass';
// import ListLeave from '../components/ListLeave';
import ChartCard from '../components/Chart';
import CentreList from '../components/CentreList';
import DashboardApi from 'services/api/dashboardApi';

const LoadingWrapper = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '50%',
    zIndex: 10000,
    transform: 'translateX(-50%)',
    backgroundColor: 'rgb(229 229 229 / 50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

function Principal() {
    const [respected, setRespected] = useState<any>();
    const [rankBrand, setrankBrand] = useState<any>([]);
    // const [listClassDashBoard2, setListClassDashBoard2] = useState<any>([]);
    // const [leaveDashBoard, setLeaveDashBoard] = useState<any>([]);
    // const [nextweekrespected, setnextweekrespected] = useState<any>([]);
    const [rankBrandRatioCluster, setRankBrandRatioCluster] = useState<any>([]);
    const [chartOver, setChartOver] = useState<any>([]);
    const [chartUnder, setChartUnder] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loading2, setLoading2] = useState<boolean>(true);
    const [loading3, setLoading3] = useState<boolean>(true);
    const [loading4, setLoading4] = useState<boolean>(true);
    const [loading5, setLoading5] = useState<boolean>(true);

    useEffect(() => {
        handleGetTotal();
        handleGetRankBrand();
        handleGetChartOver();
        handleGetChartUnder();
        handleGetCRankBrandRatioCluster();
    }, []);

    const handleGetRankBrand = async () => {
        const { data } = await DashboardApi.rankBrandRatio();
        if (data) {
            setrankBrand(data);
            setLoading(false);
        }
    };

    const handleGetTotal = async () => {
        const { data } = await DashboardApi.respectedCenter();
        if (data) {
            setRespected(data);
            setLoading2(false);
        }
    };

    const handleGetChartOver = async () => {
        const { data } = await DashboardApi.chartCenterOverStaff();
        if (data) {
            const dt = data.sort(
                (a: any, b: any) => b?.sumOverStaff - a?.sumOverStaff || b?.numberRatioOverStaff - a?.numberRatioOverStaff
            );
            setChartOver(dt);
            setLoading3(false);
        }
    };

    const handleGetChartUnder = async () => {
        const { data } = await DashboardApi.chartCenterUnderStaff();
        if (data) {
            const dt = data.sort(
                (a: any, b: any) => b?.sumUnderStaff - a?.sumUnderStaff || b?.numberRatioUnderStaff - a?.numberRatioUnderStaff
            );
            setChartUnder(dt);
            setLoading4(false);
        }
    };
    const handleGetCRankBrandRatioCluster = async () => {
        const { data } = await DashboardApi.rankBrandCluster();
        if (data) {
            setRankBrandRatioCluster(data);
            setLoading5(false);
        }
    };

    const getMonth = () => {
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const d = new Date();
        return month[d.getMonth()];
    };

    // const aa

    return (
        <>
            {(loading || loading2 || loading3 || loading4 || loading5) && (
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>
            )}
            <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
                <CardContent style={{ paddingTop: 0 }}>
                    <Box>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} lg={2} sm={3}>
                                <CardTotal
                                    primary="Brand"
                                    secondary={Number(respected?.ratioCenterBrand || '0').toFixed(2)}
                                    content="May 23 - June 01 (2018)"
                                    iconPrimary={AssignmentTurnedInIcon}
                                    day={respected?.sumNumberDayBrand || '0'}
                                    type={getMonth()}
                                    color="primary.main"
                                    bgcolor="primary.light"
                                />
                            </Grid>
                            <Grid item xs={12} lg={2} sm={3}>
                                <CardTotal
                                    primary="ECDA"
                                    secondary={Number(respected?.ratioCenterECDA || '0').toFixed(2)}
                                    content="May 23 - June 01 (2018)"
                                    day={respected?.sumNumberDayECDA || '0'}
                                    type={getMonth()}
                                    iconPrimary={AssignmentTurnedInIcon}
                                    color="primary.main"
                                    bgcolor="primary.light"
                                />
                            </Grid>
                            <Grid item xs={12} lg={3.8} sm={3}>
                                <CentreList data={rankBrand} title="Centre Ranking" type="Centre" />
                            </Grid>
                            <Grid item xs={12} lg={4} sm={3}>
                                <CentreList data={rankBrandRatioCluster} title="Cluster Ranking" type="Cluster" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={5.9} sm={6}>
                                <ChartCard title="Number of HRS (OverStaff)" data={chartOver} month={getMonth()} height={400} />
                            </Grid>
                            <Grid item xs={12} lg={5.9} sm={6}>
                                <ChartCard
                                    title="Number of HRS (UnderStaff)"
                                    data={chartUnder}
                                    month={getMonth()}
                                    type="under"
                                    height={400}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </div>
        </>
    );
}

export default Principal;
