import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface IHeaderModalProps {
    title: string;
    onClose: any;
}
const HeaderModal = ({ title, onClose }: IHeaderModalProps) => {
    return (
        <Grid
            container
            sx={{
                padding: '8px 20px 20px 20px',
                borderBottom: '1px solid #E0E0E0',
                width: 'auto'
            }}
            py={2}
        >
            <Grid item xs={11} textAlign="left">
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ padding: 0, fontSize: '18px' }}
                    fontSize="18px"
                    component="div"
                    mb={0}
                    ml={0}
                    pl={3}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={1} onClick={onClose} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'end' }}>
                <CloseIcon />
            </Grid>
        </Grid>
    );
};

export default HeaderModal;
