import { useEffect, useState } from 'react';
// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useTheme,
    Grid,
    Typography,
    CardContent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormLabel,
    InputAdornment
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// third party
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik } from 'formik';
// import * as lodash from 'lodash';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

// project imports
import AnimateButton from 'components/extended/AnimateButton';

// assets
import staffApi from 'services/api/staffApi';
import reliefTicketApi from 'services/api/reliefTicketApi';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import moment from 'moment';
import { RELIEF_AGENT } from 'config';
import ModalCustom from 'components/Modal/ModalCustom';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    reliefTicketEdit?: ReliefTicketKecho;
}

const ReliefTicketForm = (props: IProps) => {
    const { reliefTicketEdit, callback, onClose } = props;
    const theme = useTheme();

    const [interviewDate, setInterviewDate] = useState(reliefTicketEdit?.interviewDate || moment().format());
    const [cessationDate, setCessationDate] = useState(reliefTicketEdit?.cessationDate || moment().format());
    const [commencementDate, setCommencementDate] = useState(reliefTicketEdit?.commencementDate || moment().format());
    const [certData, setCertData] = useState([]);
    const [checkDocuments, setCheckDocuments] = useState({
        NRIC: reliefTicketEdit?.NRIC || false,
        bank: reliefTicketEdit?.bank || false,
        applicationForm: reliefTicketEdit?.applicationForm || false,
        educationalCertificates: reliefTicketEdit?.educationalCertificates || false,
        medical: reliefTicketEdit?.medical || false,
        contractSigned: reliefTicketEdit?.contractSigned || false,
        declarationForm: reliefTicketEdit?.declarationForm || false
    });

    useEffect(() => {
        handleGetCertList();
    }, []);

    const handleChangeInterviewDate = (newValue: any) => {
        if (newValue) setInterviewDate(newValue);
    };
    const handleChangeCessationDate = (newValue: any) => {
        if (newValue) setCessationDate(newValue);
    };
    const handleChangeCommencementDate = (newValue: any) => {
        if (newValue) setCommencementDate(newValue);
    };

    const handleGetCertList = async () => {
        const { dataList } = await staffApi.listCert();
        if (dataList) setCertData(dataList);
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckDocuments({
            ...checkDocuments,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmitForm = async (dataPayload: any) => {
        const params = {
            ...dataPayload,
            interviewDate,
            cessationDate,
            commencementDate,
            NRIC: checkDocuments.NRIC,
            bank: checkDocuments.bank,
            applicationForm: checkDocuments.applicationForm,
            educationalCertificates: checkDocuments.educationalCertificates,
            medical: checkDocuments.medical,
            contractSigned: checkDocuments.contractSigned,
            declarationForm: checkDocuments.declarationForm
        };
        if (reliefTicketEdit?.id) {
            const { data } = await reliefTicketApi.UpdateReliefTicketById(reliefTicketEdit?.id, params);
            if (data) {
                callback();
                onClose();
            }
        } else {
            const { data } = await reliefTicketApi.createReliefTicket(params);
            if (data) {
                callback();
                onClose();
            }
        }
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    return (
        <>
            <Formik
                initialValues={{
                    name: reliefTicketEdit?.name,
                    email: reliefTicketEdit?.email,
                    certification: reliefTicketEdit?.certification?.id,
                    status: reliefTicketEdit?.status || 'Pending',
                    interviewDate: reliefTicketEdit?.interviewDate,
                    phoneNumber: reliefTicketEdit?.phoneNumber,
                    reliefType: reliefTicketEdit?.reliefType,
                    availability: reliefTicketEdit?.availability,
                    area: reliefTicketEdit?.area,
                    preferWorkLocation: reliefTicketEdit?.preferWorkLocation,
                    note: reliefTicketEdit?.note,
                    salary: reliefTicketEdit?.salary,
                    commencementDate: reliefTicketEdit?.commencementDate,
                    cessationDate: reliefTicketEdit?.cessationDate,
                    deployedTo: reliefTicketEdit?.deployedTo,
                    deloymentPeriod: reliefTicketEdit?.deloymentPeriod,
                    agent: reliefTicketEdit?.agent
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    email: Yup.string().max(255).email().required('Email is required'),
                    certification: Yup.string().required('Diploma is required'),
                    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
                    salary: Yup.number().min(0).required('Salary is required'),
                    agent: Yup.string().required('Agent is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmitForm(values);
                    setSubmitting(false);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                    <ModalCustom
                        onSave={submitForm}
                        disabled={isSubmitting}
                        onClose={onClose}
                        onCancel={() => {}}
                        title={!reliefTicketEdit ? 'Create Relief Pool' : 'Edit Relief Pool'}
                        contentSave={!reliefTicketEdit ? 'Create' : 'Save'}
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <FormControl fullWidth error={Boolean(touched.name && errors.name)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                                <OutlinedInput
                                    id="demo-simple-select"
                                    type="text"
                                    value={values.name}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.name && errors.name && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.name}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel id="demo-simple-select-label">Email address</InputLabel>
                                <OutlinedInput
                                    id="demo-simple-select"
                                    type="text"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.certification && errors.certification)}
                                style={{ margin: '15px 0' }}
                            >
                                <InputLabel id="demo-simple-select-label">Diploma</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="certification"
                                    value={values.certification}
                                    label="certification"
                                    onChange={handleChange}
                                >
                                    {certData?.map((item: any) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.diploma}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.certification && errors.certification && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.certification}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel id="demo-simple-select-label">Phones</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.phoneNumber && errors.phoneNumber && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.phoneNumber}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Grid sx={{ mt: 1 }} container justifyContent="space-between" spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth error={Boolean(touched.status && errors.status)}>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            value={values.status}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem key={uuidv4()} value="No-active">
                                                Non-active
                                            </MenuItem>
                                        </Select>
                                        {touched.status && errors.status && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.status}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth error={Boolean(touched.reliefType && errors.reliefType)}>
                                        <InputLabel id="demo-simple-select-label">Relief Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="reliefType"
                                            value={values.reliefType}
                                            label="reliefType"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="RT">RT</MenuItem>
                                            <MenuItem value="RTA">RTA</MenuItem>
                                        </Select>
                                        {touched.reliefType && errors.reliefType && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.reliefType}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Interview Date"
                                            inputFormat="dd/MM/yyyy"
                                            value={interviewDate}
                                            onChange={handleChangeInterviewDate}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.availability && errors.availability)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.availability}
                                    name="availability"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.availability && errors.availability && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.availability}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.area && errors.area)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel id="demo-simple-select-label">Area</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.area}
                                    name="area"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.area && errors.area && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.area}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.preferWorkLocation && errors.preferWorkLocation)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel id="demo-simple-select-label">Prefer work location</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.preferWorkLocation}
                                    name="preferWorkLocation"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.preferWorkLocation && errors.preferWorkLocation && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.preferWorkLocation}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Grid sx={{ mt: 1 }} container justifyContent="space-between" spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth error={Boolean(touched.salary && errors.salary)}>
                                        <InputLabel id="demo-simple-select-label">Salary (per hour)</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            label="Salary (per hour)"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            type="number"
                                            value={values.salary}
                                            name="salary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            autoComplete="off"
                                        />
                                        {touched.salary && errors.salary && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.salary}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Commencement Date"
                                            inputFormat="dd/MM/yyyy"
                                            value={commencementDate}
                                            onChange={handleChangeCommencementDate}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Cessation Date"
                                            inputFormat="dd/MM/yyyy"
                                            value={cessationDate}
                                            onChange={handleChangeCessationDate}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.deployedTo && errors.deployedTo)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel id="demo-simple-select-label">Deployed To</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.deployedTo}
                                    name="deployedTo"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.deployedTo && errors.deployedTo && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.deployedTo}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.deloymentPeriod && errors.deloymentPeriod)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel id="demo-simple-select-label">Deployment Period</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.deloymentPeriod}
                                    name="deloymentPeriod"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.deloymentPeriod && errors.deloymentPeriod && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.deloymentPeriod}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.note && errors.note)} sx={{ ...theme.typography.customInput }}>
                                <InputLabel id="demo-simple-select-label">Note</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="text"
                                    value={values.note}
                                    name="note"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    autoComplete="off"
                                />
                                {touched.note && errors.note && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.note}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth error={Boolean(touched.agent && errors.agent)} style={{ marginTop: 10 }}>
                                <InputLabel id="demo-simple-select-label">Agent</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="agent"
                                    value={values.agent}
                                    label="Agent"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={RELIEF_AGENT.DIRECT_STAFF}>Direct Staffing</MenuItem>
                                    <MenuItem value={RELIEF_AGENT.BABILOU}>Babilou Family</MenuItem>
                                    <MenuItem value={RELIEF_AGENT.SEMIKO}>Semiko</MenuItem>
                                    <MenuItem value={RELIEF_AGENT.INTELLISEARCH}>Intellisearch</MenuItem>
                                    <MenuItem value={RELIEF_AGENT.WE_GOT_THIS}>We got this</MenuItem>
                                </Select>
                                {touched.agent && errors.agent && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.agent}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                <FormLabel>Documents</FormLabel>
                                <Grid sx={{ mt: 1 }} container justifyContent="space-between" spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={checkDocuments.NRIC} onChange={handleChangeCheckbox} name="NRIC" />
                                                }
                                                label="NRIC"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={checkDocuments.bank} onChange={handleChangeCheckbox} name="bank" />
                                                }
                                                label="Bank"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkDocuments.applicationForm}
                                                        onChange={handleChangeCheckbox}
                                                        name="applicationForm"
                                                    />
                                                }
                                                label="Application Form"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkDocuments.medical}
                                                        onChange={handleChangeCheckbox}
                                                        name="medical"
                                                    />
                                                }
                                                label="Medical"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkDocuments.contractSigned}
                                                        onChange={handleChangeCheckbox}
                                                        name="contractSigned"
                                                    />
                                                }
                                                label="Contract Signed"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkDocuments.declarationForm}
                                                        onChange={handleChangeCheckbox}
                                                        name="declarationForm"
                                                    />
                                                }
                                                label="Declaration Form"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkDocuments.educationalCertificates}
                                                        onChange={handleChangeCheckbox}
                                                        name="educationalCertificates"
                                                    />
                                                }
                                                label="Educational Certificates"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </>
    );
};

export default ReliefTicketForm;
