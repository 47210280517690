// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { AUTH_LOCAL_STORAGE_KEY, ReliefStatus, Roles } from 'config';
import { StaffKecho } from 'types/kecho/staff-kecho';
import UIManager from 'core/UIManager';
import { useEffect, useState } from 'react';
import { formatDateOnly } from 'utils/common/formatDate';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    staff: StaffKecho;
    relief: string;
}

const ChooseStaffModal = (props: IProps) => {
    const { onClose, staff, relief } = props;
    const navigate = useNavigate();
    const [reliefData, setReliefData] = useState<any>();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefApi.getReliefDetail(relief);
            if (res) {
                setReliefData(res.data);
            }
        };

        getRelief();
    }, []);

    const handleOk = async () => {
        onClose();
        const res =
            userRole === Roles.Principals
                ? await reliefApi.UpdateReliefById(relief, ReliefStatus.PRIN_COMPLETED, staff)
                : await reliefApi.UpdateReliefById(relief, ReliefStatus.APPROVED, staff);
        if (res) {
            copyToClipBoard(staff?.email || '');
            navigate('/staff/relief-list', { replace: true });
        }
    };

    const copyToClipBoard = (text: string) => {
        navigator.clipboard.writeText(text);
        UIManager.showAlert({
            message: 'Copy to clipboard success',
            alert: {
                color: 'success'
            }
        });
    };

    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div" style={{ paddingLeft: 20 }}>
                        Confirm optimization
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer', position: 'relative', top: '-20px', right: '-20px' }}>
                    <CloseIcon />
                </Grid>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Typography gutterBottom component="div">
                        Staff <b>{staff.email}</b> will support center <b>{reliefData?.center?.nameCenter}</b> from
                        <br />
                        <b>
                            {formatDateOnly(reliefData?.startDate)}{' '}
                            {reliefData?.startHour < 10 ? `0${reliefData?.startHour}:00` : `${reliefData?.startHour}:00`}
                        </b>{' '}
                        to{' '}
                        <b>
                            {formatDateOnly(reliefData?.endDate)}{' '}
                            {reliefData?.endHour < 10 ? `0${reliefData?.endHour}:00` : `${reliefData?.endHour}:00`}
                        </b>
                    </Typography>
                </Grid>
                <Grid container xs={12} justifyContent="center">
                    <Button variant="outlined" onClick={onClose} style={{ border: '1px solid #3f51b5', color: '#3f51b5', marginRight: 10 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleOk} style={{ backgroundColor: '#3f51b5' }}>
                        OK
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default ChooseStaffModal;
