import axios from 'axios';
import { REACT_APP_API_URL } from 'config';
import { PromiseApi } from 'types/kecho/api-promise';
import { LoginAzure, LoginForm, RegisterForm } from 'types/authTypes/auth-form';
import axiosClient from 'utils/axios/axiosFileUpload';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'auth/';

const authApi = {
    resgister(params: RegisterForm): Promise<PromiseApi> {
        const base = `${BaseApi}register`;
        const url = buildUrl(base, params);

        return axiosClient.post(url, params);
    },
    login(params: LoginForm): Promise<PromiseApi> {
        const base = `${BaseApi}login`;
        const url = buildUrl(base);
        return axiosClient.post(url, params);
    },
    loginAzure(params: LoginAzure): Promise<PromiseApi> {
        const base = `${BaseApi}login-azure`;
        const url = buildUrl(base);
        return axiosClient.post(url, params);
    },
    refreshToken(refreshToken: string): Promise<PromiseApi> {
        const base = `${BaseApi}refresh-token`;
        const url = buildUrl(base);
        return axios.get(url, {
            baseURL: REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken}`
            }
        });
    },
    refreshTokenAzure(refreshToken: string, token: string): Promise<PromiseApi> {
        const base = `${REACT_APP_API_URL}${BaseApi}refresh-token-azure`;
        const url = buildUrl(base);
        return axios.post(
            url,
            { token },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken}`
                }
            }
        );
    }
};
export default authApi;
