// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';

// types

// ----------------------------------------------------------------------

const initialState: { filter: { centerId: string | null } } = {
    filter: {
        centerId: ''
    }
};

const classList = createSlice({
    name: 'classList',
    initialState,
    reducers: {
        setFilter(state, action) {
            state.filter = action.payload;
        }
    }
});

// Reducer
export default classList.reducer;

export function setClassListByCenter(step: { centerId: string | null }) {
    return () => {
        dispatch(classList.actions.setFilter(step));
    };
}
