// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Input, Typography } from '@mui/material';
import { reliefApi } from 'services/api';
import { ReliefStatus } from 'config';
import { useEffect, useState } from 'react';
import UIManager from 'core/UIManager';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    relief: string;
}

const RejectReliefModal = (props: IProps) => {
    const { onClose, relief, callback } = props;
    const [reliefData, setReliefData] = useState<any>();
    const [reasons, setReasons] = useState<string>('');
    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefApi.getReliefDetail(relief);
            if (res) {
                setReliefData(res.data);
            }
        };

        getRelief();
    }, []);

    const handleOk = async () => {
        const res = await reliefApi.UpdateReliefById(relief, ReliefStatus.CM_REJECT, undefined, reasons);
        if (res) {
            UIManager.showAlert({
                message: `Relief status updated to "CM reject"`,
                alert: {
                    color: 'success'
                }
            });
            onClose();
            callback();
        }
    };
    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div" style={{ paddingLeft: 20 }}>
                        Rejecting relief sent by principal of {reliefData?.center?.nameCenter}
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer', position: 'relative', top: '-20px', right: '-20px' }}>
                    <CloseIcon />
                </Grid>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Typography gutterBottom component="div">
                        A notification will be sent to the principal. Please leave some reasons for rejecting this relief
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Input style={{ width: '400px' }} placeholder="Reasons" onChange={(e) => setReasons(e.target.value)} />
                </Grid>
                <Grid container xs={12} justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleOk} style={{ marginRight: 20 }}>
                        OK
                    </Button>
                    <Button variant="contained" color="error" onClick={onClose} style={{ marginRight: 20 }}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default RejectReliefModal;
