import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { MethodShowAlert, REACT_APP_API_URL } from 'config';
import UIManager from 'core/UIManager';
import { IErrorResponse } from 'types/kecho/api-promise';

const axiosFileUpload = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
    }
});
// Add a request interceptor
axiosFileUpload.interceptors.request.use(
    // Do something before request is sent
    (config: AxiosRequestConfig) => config,
    // Do something with request error
    (error) => ({
        error
    })
);

// Add a response interceptor
axiosFileUpload.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    (response: AxiosResponse) => {
        if (MethodShowAlert.indexOf(response.config.method || '') >= 0)
            UIManager.showAlert({
                message: 'Success',
                alert: {
                    color: 'success'
                }
            });
        return response;
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    (error) => {
        const dataError = error.response?.data as IErrorResponse;
        if (MethodShowAlert.indexOf(error.config.method || '') >= 0)
            UIManager.showAlert({
                message: dataError?.message || 'Error',
                alert: {
                    color: 'error'
                }
            });
        return {
            error
        };
    }
);

export default axiosFileUpload;
