import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { createContext } from 'react';
import { IAttendance } from '..';
import { CollapseWrapper } from '../../CalculationStaffRatio/DetailPickerManual';
import TableAttendance from './TableAttendance';

export const AttendanceContext = createContext<IAttendance | null>(null);

interface IProps {
    attendance: IAttendance;
}

const CollapseAttendance = ({ attendance }: IProps) => {
    return (
        <AttendanceContext.Provider value={attendance}>
            <CollapseWrapper>
                <Accordion defaultExpanded>
                    <div className="collapse-header">
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            style={{ width: 'auto' }}
                        />
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginRight: 10 }}>
                            <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#3F51B5' }}>{attendance.date}</Typography>
                        </div>
                    </div>

                    <AccordionDetails style={{ padding: 0 }}>
                        <TableAttendance />
                    </AccordionDetails>
                </Accordion>
            </CollapseWrapper>
        </AttendanceContext.Provider>
    );
};

export default CollapseAttendance;
