import { Checkbox, TextField } from '@mui/material';
import { useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { ClassKecho } from 'types/kecho/class-kecho';
// import { set } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import UIManager from 'core/UIManager';

interface IProps {
    onClose: any;
    onSave: any;
    listClass: ClassKecho[];
    callback?: () => void;
}

type Row = {
    idClass?: string;
    name?: string;
    nameClass?: string;
    nameLevel?: string;
    select?: any;
};
export default function PickTime({ onClose, onSave, listClass, callback }: IProps) {
    const [fromDate, setFromDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [endDate, setEndDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [msg, setMsg] = useState<string>('');
    const [isSave, setIsSave] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<Row[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const col: IColumnTable<Row>[] = [
        {
            field: 'nameClass',
            headerName: 'Name'
        },
        {
            field: 'nameLevel',
            headerName: 'Level'
        },
        {
            field: 'select',
            headerName: 'Select',
            render: (row) => {
                return <Checkbox checked={isRowSelected(row)} onChange={(event) => handleCheckboxChange(event, row)} />;
            }
        }
    ];

    const listClassMapper: Row[] = listClass.map((item) => {
        return {
            idClass: item.idClass,
            nameClass: item.nameClass,
            nameLevel: item.level?.nameLevel,
            name: '',
            select: ''
        };
    });

    const handleCheckDuplicate = async () => {
        setLoading(true);
        if (isError || !fromDate || !endDate) return;
        try {
            const check = await onSave(fromDate, endDate, true, selectedRows);
            setLoading(false);
            if (!check.data) {
                setMsg('Conflict(s) detected. The conflicted data will not be replicated. Do you want to continue?');
                setIsError(true);
                setIsSave(true);
            } else {
                setMsg('Do you want to continue?');
                setIsError(false);
                setIsSave(true);
            }
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const handleSaveDuplicate = async () => {
        try {
            setLoading(true);
            const res = await onSave(moment(fromDate).format('YYYY/MM/DD'), moment(endDate).format('YYYY/MM/DD'), false, selectedRows);
            if (res?.error) {
                UIManager.showAlert({
                    message: res.error.response.data.message,
                    alert: {
                        color: 'error'
                    }
                });
                setLoading(false);
                return;
            }
            UIManager.showAlert({
                message: 'Replicate successfully',
                alert: {
                    color: 'success'
                }
            });
            setLoading(false);
            if (callback) callback();
            onClose();
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
            setLoading(false);
        }
    };

    const [step, setStep] = useState<number>(1);

    const handleStep = () => {
        if (!selectedRows.length) {
            UIManager.showAlert({
                message: 'Please choose at least one class',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        if (step === 1) setStep(2);
    };
    const handleCheckboxChange = (event: any, row: any) => {
        const { checked } = event.target;

        setSelectedRows((prevSelectedRows) => {
            if (checked) {
                // Add the row to the selectedRows array if it's not already present
                if (!prevSelectedRows.find((selectedRow) => selectedRow.idClass === row.idClass)) {
                    return [...prevSelectedRows, row];
                }
            } else {
                // Remove the row from the selectedRows array
                return prevSelectedRows.filter((selectedRow) => selectedRow.idClass !== row.idClass);
            }

            return prevSelectedRows; // Return the previous state if no changes were made
        });
    };
    const isRowSelected = (row: any) => selectedRows.some((selectedRow) => selectedRow.idClass === row.idClass);
    const renderStep1 = () => {
        return (
            <TableCustom
                rows={listClassMapper}
                columns={col}
                onRowClick={(row) => {
                    isRowSelected(row)
                        ? handleCheckboxChange({ target: { checked: false } }, row)
                        : handleCheckboxChange({ target: { checked: true } }, row);
                }}
            />
        );
    };

    const renderStep2 = () => {
        return (
            <DateRangeLocalDesktopDatePicker
                views={['year', 'month', 'day']}
                disablePast
                isRequired
                isAllowEqual
                isShowErrorMessage={false}
                dateSx={{ maxWidth: 'unset', width: '100%' }}
                errorTextSx={{ top: '50px' }}
                fromDateValue={fromDate}
                toDateValue={endDate}
                renderInput={(params: any) => <TextField size="large" fullWidth {...params} />}
                disabled={isSave}
                onChange={(value) => {
                    if (value.errorText) {
                        setIsError(true);
                        setMsg(value.errorText);
                    } else {
                        setIsError(false);
                        setMsg('');
                    }
                    setFromDate(value.fromDate);
                    setEndDate(value.toDate);
                }}
                onError={(error) => {
                    setIsError(!!error);
                    setMsg('');
                }}
            />
        );
    };

    function renderButtonSaveContent() {
        if (step === 1 && listClass.length) {
            return 'Next';
        }

        if (step === 2 && !isSave) {
            return 'Check';
        }

        if (step === 2 && isSave) {
            return 'Yes';
        }
        return undefined;
    }

    function renderButtonCancelContent() {
        if (step === 1) {
            return undefined;
        }

        if (step === 2 && !isSave) {
            return 'Back';
        }

        if (step === 2 && isSave) {
            return 'No';
        }
        return undefined;
    }

    function renderButtonSaveFunc() {
        if (step === 1) {
            return handleStep();
        }

        if (step === 2 && !isSave) {
            return handleCheckDuplicate();
        }

        if (step === 2 && isSave) {
            return handleSaveDuplicate();
        }
        return undefined;
    }

    function renderButtonCancelFunc() {
        if (step === 1) {
            return undefined;
        }

        if (step === 2 && !isSave) {
            return setStep(1);
        }

        if (step === 2 && isSave) {
            return onClose();
        }
        return undefined;
    }

    return (
        <ModalCustom
            onSave={() => renderButtonSaveFunc()}
            onCancel={() => renderButtonCancelFunc()}
            contentSave={renderButtonSaveContent()}
            contentCancel={renderButtonCancelContent()}
            title={`Please select ${!isSave && step === 1 ? 'class(es) for replication' : 'deployment replication date range'}`}
            onClose={onClose}
            iconSave={step === 2 && !isSave ? CheckIcon : undefined}
            typeHelper={isError && !isSave ? 'error' : 'warning'}
            msgHelper={msg}
            sx={{ width: 600, maxHeight: '300px', overflowY: step === 1 ? 'scroll' : 'hidden' }}
            isLoading={loading}
        >
            <>
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
            </>
        </ModalCustom>
    );
}
