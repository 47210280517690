import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
    Typography,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    // TablePagination,
    TextField,
    Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { IFilterRequest } from 'types/query/filter';
import { DEFAULT_REQUEST, ReliefStatus } from 'config';
import EmptyData from 'components/errors/empty-data';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import reliefTicketApi from 'services/api/reliefTicketApi';
import UIManager from 'core/UIManager';
import { reliefApi } from 'services/api';
import { debounce } from 'lodash';

interface IProps {
    onClose: any;
    callback: any;
    reliefId: string;
}

export default function AssignRelieftModal(props: IProps) {
    const { reliefId, onClose, callback } = props;
    const [selectTicket, setSelectTicket] = useState<ReliefTicketKecho>();

    const [reliefTickets, setReliefTicket] = useState<ReliefTicketKecho[]>([]);
    // const [metaData, setMetaData] = useState<IMetaData>({});
    // const [limitData] = useState<number>(10);
    const [filter, setFilter] = useState<IFilterRequest>(DEFAULT_REQUEST);

    // const { page, take } = filter;

    useEffect(() => {
        handleGetClassData();
    }, [filter]);

    const handleGetClassData = async () => {
        const { dataList } = await reliefTicketApi.getReliefTicketList(filter);
        if (dataList) {
            setReliefTicket(dataList);
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchStaff = debounce(onChange, 500);

    const handleAssignRelief = async () => {
        if (!selectTicket) {
            UIManager.showAlert({
                message: 'Please select tickets to assign',
                alert: {
                    color: 'error'
                }
            });

            return;
        }

        const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.RELIEF_ASSIGNED, undefined, undefined, selectTicket);
        // thieu thong bao
        if (res) {
            UIManager.showAlert({
                message: `Relief status updated to "Relief assigned"`,
                alert: {
                    color: 'success'
                }
            });
            callback();
            onClose();
        }
    };

    const renderTableStaff = () => {
        return (
            <Paper>
                {reliefTickets.length ? (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ minWidth: 70 }}>Name</TableCell>
                                        <TableCell style={{ width: 50 }}>Email Address</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Mobile</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Qualification</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Availability</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Preferred Working Location</TableCell>
                                        <TableCell style={{ minWidth: 50 }}>Salary(Per hour)</TableCell>
                                        <TableCell style={{ minWidth: 50 }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reliefTickets
                                        // .slice(page * take, page * take + take)
                                        .map((row: ReliefTicketKecho) => {
                                            // const isArrange = row.isArrange;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={uuidv4()}
                                                    style={{ backgroundColor: row?.isDisabled ? '#e1e1e1' : '', cursor: 'pointer' }}
                                                    onClick={() => setSelectTicket((prev) => (prev?.id === row?.id ? undefined : row))}
                                                >
                                                    <TableCell>{row?.name}</TableCell>
                                                    <TableCell>{row?.email}</TableCell>
                                                    <TableCell>{row?.phoneNumber}</TableCell>
                                                    <TableCell>{row?.certification?.diploma}</TableCell>
                                                    <TableCell>{row?.availability}</TableCell>
                                                    <TableCell>{row?.preferWorkLocation}</TableCell>
                                                    <TableCell>{row?.salary}</TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={row?.id === selectTicket?.id}
                                                            onChange={(e) =>
                                                                e.target.checked ? setSelectTicket(row) : setSelectTicket(undefined)
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    return (
        <Paper>
            <Grid container p={2}>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h3" gutterBottom component="div" mb={0} ml={6}>
                        Please select staff
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                    <CloseIcon />
                </Grid>
            </Grid>

            <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />
            <div style={{ padding: 20 }}>
                <Grid item xs={4}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearchStaff}
                        placeholder="Search by name"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </div>

            {renderTableStaff()}

            <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />
            <Grid item xs={11} textAlign="center" style={{ padding: 10 }}>
                <Button variant="contained" style={{ marginRight: 10, backgroundColor: '#3f51b5' }} onClick={handleAssignRelief}>
                    Assign Relief
                </Button>
            </Grid>
        </Paper>
    );
}
