// project imports
import services from 'utils/mockAdapter';

// types
import { ChatHistory, ChatOptions, OptionMessage } from 'types/template/chat';

// chat constant
const text1 = `Hi, I am WAKYU I am here to help. I have well received you request of hiring relief from [EXPECTED START DATE] TO [EXPECTED END DATE] it’s going to cost money FUIYO ! It’s going to cost up-to [AMOUNT] let’s see if we can optimize.`;
const text2 = `Do you want to ask for relief teacher? Tell me by what criteria you are looking for `;
const text3 = `You want to find staff inside your cluster, here is some staff: `;
const text4 = `You want to find staff outside your cluster, wait me a minute: `;

const text5 = `Hey man`;
const text6 = `Hi, Wats up?`;
const text7 = `Need your minute. are you available?`;
const text8 = `Sure. Let's meet.`;

const d = new Date();
// user list
const users = [
    {
        id: 1,
        name: 'Wakyu',
        company: 'ABC Pvt Ltd',
        role: 'Sr. Customer Manager',
        work_email: 'alene_work@company.com',
        personal_email: 'alene@company.com',
        work_phone: '380-293-0177',
        personal_phone: '380-293-0177',
        location: 'Port Narcos',
        avatar: 'avatar-1.png',
        status: 'Technical Department',
        lastMessage: '2h ago',
        birthdayText: 'happy Birthday Alene',
        unReadChatCount: 2,
        online_status: 'available'
    }
];

// chat history
const chatHistories: ChatHistory[] = [
    { id: 1, from: 'Wakyu', text: text1, time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), type: 'text' },
    { id: 2, from: 'Wakyu', text: text2, time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), type: 'text' }
];

const optionFirstStep: ChatOptions[] = [{ label: OptionMessage.PLEASE_HELP, value: OptionMessage.PLEASE_HELP, type: 'text' }];
const optionAskCluster: ChatOptions[] = [
    { label: OptionMessage.ASK_CLUSTER_MANAGER, value: OptionMessage.ASK_CLUSTER_MANAGER, type: 'text' }
];
const optionAskCHrIC: ChatOptions[] = [{ label: OptionMessage.ASK_HR_IC, value: OptionMessage.ASK_HR_IC, type: 'text' }];

const optionSecondStepForCM: ChatOptions[] = [{ label: OptionMessage.ASK_HR_IC, value: OptionMessage.ASK_HR_IC, type: 'text' }];
const optionSecondStep: ChatOptions[] = [
    { label: OptionMessage.FIND_INSIDE, value: OptionMessage.FIND_INSIDE, type: 'text' },
    { label: OptionMessage.FIND_OUTSIDE, value: OptionMessage.FIND_OUTSIDE, type: 'text' },
    { label: OptionMessage.FIND_All, value: OptionMessage.FIND_All, type: 'text' }
];
const optionsInsideCluster: ChatHistory[] = [
    { id: 3, from: 'Wakyu', text: text3, time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
];
const optionsOutInsideCluster: ChatHistory[] = [
    { id: 2, from: 'Wakyu', text: text4, time: d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
];

export const chatOptions = {
    optionFirstStep,
    optionAskCluster,
    optionAskCHrIC,
    optionSecondStepForCM,
    optionSecondStep,
    optionsInsideCluster,
    optionsOutInsideCluster
};
// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/chat/users').reply(200, { users });

services.onPost('/api/chat/users/id').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const index = users.findIndex((x) => x.id === id);
        return [200, users[index]];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/chat/filter').reply(async (config) => {
    try {
        const { user } = JSON.parse(config.data);
        const result = chatHistories.filter((item) => item.from === user || item.to === user);
        return [200, result];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/chat/insert').reply((config) => {
    try {
        const { from, to, text, time } = JSON.parse(config.data);
        const result = chatHistories.push({ from, to, text, time });
        return [200, result];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/chat/users/modify').reply((config) => {
    try {
        const user = JSON.parse(config.data);
        if (user.id) {
            const index = users.findIndex((u) => u.id === user.id);
            if (index > -1) {
                users[index] = { ...users[index], ...user };
            }
        } else {
            users.push({ ...user, id: users.length + 1 });
        }
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});
