import React from 'react';
import { styled } from '@mui/material';

const PublicLayoutWrapper = styled('div')(({ theme }) => ({
    padding: '30px 0px',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default
}));
const PublicLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <PublicLayoutWrapper>{children}</PublicLayoutWrapper>
        </>
    );
};

export default PublicLayout;
