import { useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import styles from '../header.module.scss';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// icons
import { IconLayoutDashboard, IconChevronRight, IconChevronDown, IconTableOptions } from '@tabler/icons';
// import HQIcon from 'assets/images/icons/topbars/HQ.svg';
import { ReactComponent as HQIcon } from 'assets/images/icons/topbars/HQ.svg';
import { ReactComponent as PrincipalIcon } from 'assets/images/icons/topbars/Principal.svg';
import { ReactComponent as SizingPage } from 'assets/images/icons/topbars/SizingPage.svg';
import { RedirectPath, Roles } from 'config';

// ==============================|| SEARCH INPUT - MEGA MENu||============================== //
interface IProps {
    path: string;
    userRole?: string;
}

const DashboardSection = (props: IProps) => {
    const { userRole, path } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    const isCheckRole = () =>
        userRole === Roles.Cluster || userRole === Roles.Principals || userRole === Roles.User || userRole === Roles.HrIc;

    return (
        <>
            <div className="wrapper-menu">
                <div className="menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className="group-menu">
                        <IconLayoutDashboard stroke={1.5} size="20px" />
                        <span style={{ display: 'inline-block', margin: '0px 5px' }}>Dashboard</span>{' '}
                        {isDropdownVisible ? <IconChevronDown stroke={1.5} size="20px" /> : <IconChevronRight stroke={1.5} size="20px" />}
                    </div>
                    {isDropdownVisible && userRole !== Roles.User && (
                        <div className="dropdown-menu">
                            <ul className={styles.dropdown_list}>
                                <List>
                                    <ListItemButton
                                        component={Link}
                                        to="/dashboard/principal"
                                        className={path === RedirectPath.DASHBOARD_PRINCIPAL ? styles.activeItem : styles.item}
                                    >
                                        <ListItemIcon>
                                            <PrincipalIcon className={styles.item} />
                                        </ListItemIcon>
                                        <ListItemText primary="Principal" />
                                    </ListItemButton>
                                    {!isCheckRole() && (
                                        <ListItemButton
                                            component={Link}
                                            to="/dashboard/hqadmin"
                                            className={path === RedirectPath.DASHBOARD_HQADMIN ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <HQIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="HQ Admin" />
                                        </ListItemButton>
                                    )}
                                    <ListItemButton
                                        disableRipple
                                        component={Link}
                                        to="/dashboard/sizing"
                                        className={path === RedirectPath.DASHBOARD_SIZING ? styles.activeItem : styles.item}
                                    >
                                        <ListItemIcon>
                                            <SizingPage className={styles.item} />
                                        </ListItemIcon>
                                        <ListItemText primary="Sizing page" />
                                    </ListItemButton>
                                </List>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DashboardSection;
