import { FIREBASE_API } from 'config';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseApp = initializeApp(FIREBASE_API);
const messaging = getMessaging(firebaseApp);

export const getTokenfb = () => {
    return getToken(messaging, {
        vapidKey: 'BBK65HsRyNRs_Dr_JuuukhDd6EuBezhQyLnrlxcScf2U9m47VY7bGy4A54JxCQTYS4qnT7XvbQLbzx-ISPVNCNE'
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                // setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });
};

export const getTokenfb2 = () => {
    return getToken(messaging, {
        vapidKey: 'BBK65HsRyNRs_Dr_JuuukhDd6EuBezhQyLnrlxcScf2U9m47VY7bGy4A54JxCQTYS4qnT7XvbQLbzx-ISPVNCNE'
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                return currentToken;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            }
            console.log('No registration token available. Request permission to generate one.');
            return 'no token';
            // shows on the UI that permission is required
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            return 'no error';
            // catch error while creating client token
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
