// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, Grid, Typography, CardContent } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import UIManager from 'core/UIManager';

// project imports

import { clusterApi } from 'services/api';
import { ClusterKecho } from 'types/kecho/centre-kecho';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    clusterEdit?: ClusterKecho;
}

const DeleteClusterModal = (props: IProps) => {
    const { onClose, callback, clusterEdit } = props;

    const handleDeleteCluster = async () => {
        if (clusterEdit?.id) {
            const { data } = await clusterApi.deleteCluster(clusterEdit?.id);
            if (data) {
                UIManager.showAlert({
                    message: 'Delete successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteCluster}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure you want to delete cluster &quot;{clusterEdit?.nameCluster}&quot;?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteClusterModal;
