import { DesktopDatePickerProps } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDate } from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDebounce } from 'services/hooks/useDebounce';

interface ILocalDesktopDatePicker extends DesktopDatePickerProps<Date> {
    value: string | null;
    onChange: (e: any) => void;
    label?: string;
    sx?: Object;
    errorTextSx?: Object;
    isRequired?: boolean;
    disablePast?: boolean;
    size?: string;
    errorText?: string;
    onError?: (error: string | null) => void;
}

const LocalDesktopDatePicker = ({
    value = null,
    label: datePickerLabel = '',
    sx = {},
    size = 'small',
    errorTextSx = {},
    isRequired = false,
    disablePast = false,
    onChange,
    onError,
    errorText = 'Invalid date',
    ...props
}: ILocalDesktopDatePicker) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputErrorMessage, setInputErrorMessage] = useState<string>('');
    const [inputDate, setInputDate] = useState(value);

    useEffect(() => {
        const newInputDate = moment(inputDate).format(formatDate.DATE_DEFAULT);
        if (value === newInputDate) return;
        setInputDate(value);
    }, [value]);

    useEffect(() => {
        onError && onError(errorText);
    }, [errorMessage]);

    useEffect(() => {
        debounceOnChange(inputDate);
    }, [inputDate, errorMessage]);

    const handleOnChange = (inputValue: any) => {
        let newInputValue: any = inputValue;

        if (!errorMessage) {
            if (!inputDate && isRequired) {
                setInputErrorMessage('This field is required');
                newInputValue = '';
            } else {
                setInputErrorMessage('');
            }
        }

        if (errorMessage) {
            newInputValue = '';
        }
        onChange && onChange(newInputValue);
    };

    const debounceOnChange = useDebounce(handleOnChange, 250);

    const handleOnError = (error: string | null) => {
        if (error) {
            if (error === 'disablePast') {
                setErrorMessage("Can't select pass date");
                return;
            }
            setErrorMessage(errorText);
        } else {
            setErrorMessage('');
        }
    };

    const handleInputChange = (e: any) => {
        setInputDate(e);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box component="div" sx={{ position: 'relative', maxWidth: '160px', width: '100%', ...sx }}>
                <DesktopDatePicker
                    label={datePickerLabel}
                    inputFormat="dd/MM/yyyy"
                    disablePast={disablePast}
                    value={inputDate}
                    onError={handleOnError}
                    onChange={handleInputChange}
                    renderInput={(params: any) => <TextField size={size} sx={{ width: '100%' }} {...params} />}
                    {...props}
                />
                {(errorMessage || inputErrorMessage) && (
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: '40px',
                            left: '5px',
                            ...errorTextSx
                        }}
                        variant="body2"
                        color="error"
                    >
                        {errorMessage || inputErrorMessage}
                    </Typography>
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default LocalDesktopDatePicker;
