import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import {
    Box,
    Button,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';

import SearchIcon from '@mui/icons-material/Search';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';
import { DefaultRootStateProps, EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { ClassKecho } from 'types/kecho/class-kecho';
import { upperFirst } from 'lodash';
import { upperCaseTextName } from 'utils/common/upperCaseText';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import classApi from 'services/api/classApi';
import { v4 as uuidv4 } from 'uuid';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { CentreApi } from 'services/api';
import EmptyData from 'components/errors/empty-data';
import { useDispatch, useSelector } from 'store';
import { setClassListByCenter } from 'store/slices/template/classList';
import { StyledTableCell } from 'components/Table/TableCustom';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';

// table header options
const headCells: HeadCell[] = [
    // {
    //     id: 'name',
    //     numeric: false,
    //     label: 'Id',
    //     align: 'left'
    // },
    {
        id: 'nameClass',
        numeric: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'location',
        numeric: true,
        label: 'Location',
        align: 'left'
    },
    {
        id: 'description',
        numeric: true,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Level',
        align: 'left'
    },
    {
        id: 'session',
        numeric: false,
        label: 'Session',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const ClassList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const centerID = useSelector((state: DefaultRootStateProps) => state.classList.filter.centerId);
    const [classList, setClassList] = React.useState<ClassKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [selected] = React.useState<string[]>([]);
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, centreId: centerID || '' });

    React.useEffect(() => {
        if (isCheckFilterCenter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetClassList();
        }, 500);
        return () => clearTimeout(searchInputTimer);
    }, [filter, centerID]);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList) {
            dataList?.unshift({ id: 'All', nameCenter: 'All' });
            dispatch(setClassListByCenter({ centerId: centerID || '' }));
            setFilter({ ...DEFAULT_REQUEST, centreId: dataList[0]?.id || '' });
        }
        setCentreData(dataList);
    };

    const handleGetClassList = async () => {
        const { dataList, meta } = await classApi.list({ ...filter, centreId: centerID || '' });
        if (dataList) setClassList(dataList);
        if (meta) setMetaData(meta);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, page: 1, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {};

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const isCheckFilterCenter = () => {
        return userRole !== Roles.Principals;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Class List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                            style={{ marginRight: '8px', marginBottom: '20px' }}
                        />
                        {isCheckFilterCenter() && (
                            <FormControl style={{ width: 400 }}>
                                <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="centerId"
                                    label="Center"
                                    value={String(centerID) || 'All'}
                                    defaultValue="All"
                                    onChange={(e) => {
                                        setFilter({ ...filter, centreId: e.target.value === 'All' ? '' : e.target.value, page: 1 });
                                        dispatch(setClassListByCenter({ centerId: e.target.value === 'All' ? '' : e.target.value }));
                                    }}
                                    size="small"
                                    MenuProps={{
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }}
                                >
                                    {centreData?.map((centre) => (
                                        <MenuItem key={uuidv4()} value={centre.id}>
                                            {centre.nameCenter}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {classList.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={classList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {/* stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                                {classList &&
                                    classList.map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;
                                        const isItemSelected = isSelected(row?.nameClass);
                                        // const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell>{upperCaseTextName(row?.nameClass)}</TableCell>
                                                <TableCell>{row?.year}</TableCell>
                                                <TableCell align="left">{upperFirst(row?.description)}</TableCell>
                                                <TableCell align="left">{upperFirst(row?.level?.nameLevel)}</TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    {upperFirst(row?.session)}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    {upperFirst(row?.type)}
                                                </TableCell>
                                                <TableCell align="left" sx={{ pr: 3 }}>
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip placement="top" title="View detail">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                                                                onClick={() => {
                                                                    navigate(`/class/class-details/${row?.id}`);
                                                                }}
                                                                // href={`/centre/centre-details/${row?.id}`}
                                                            >
                                                                <img
                                                                    src={ViewIcon}
                                                                    alt="view"
                                                                    style={{ width: '22px', height: '22px', marginRight: '5px' }}
                                                                />{' '}
                                                                View
                                                            </Button>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* table pagination */}

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take || 10}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ClassList;
