// import { upperCase } from 'lodash';
import { PromiseApi } from 'types/kecho/api-promise';
import { ListUserPagination } from 'types/authTypes/auth-form';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
// import axiosAuthClient from 'utils/axios/axiosAuthClient';
import axiosFileUpload from 'utils/axios/axiosFileUpload';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'user/';

const userApi = {
    currentUser(): Promise<PromiseApi> {
        const base = `${BaseApi}me`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },

    infoUserAzure(): Promise<PromiseApi> {
        const base = `user/get-info-user-azure`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    listUser(params?: ListUserPagination): Promise<PromiseApi> {
        if (params) {
            params.centreId = params?.centreId === 'All' ? undefined : params?.centreId;
        }

        const base = `${BaseApi}list-user`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    deleteUser(id: number): Promise<PromiseApi> {
        const base = `${BaseApi}delete-user/${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },

    listRole(): Promise<PromiseApi> {
        const base = `${BaseApi}list-role`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    importLeaveExcel(body: FormData) {
        const base = `${BaseApi}import-leave-excel`;
        const url = buildUrl(base);
        return axiosFileUpload.post(url, body);
    },
    logout() {
        const base = `${BaseApi}log-out`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url);
    }
};
export default userApi;
