import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';
import { CreateReliefBody, ReliefFilter } from 'types/kecho/relief';

const BaseApi = 'relief/';

const reliefApi = {
    createRelief(body: CreateReliefBody): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    getReliefById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}chat-bot/${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    getReliefDetail(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    UpdateReliefById(id: string, status: string, reliefStaff?: any, rejectReason?: string, reliefTicket?: any): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, { status, reliefStaff, rejectReason, reliefTicket });
    },
    getReliefList(filter: ReliefFilter): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, filter);
        return axiosAuthClient.get(url);
    },
    deleteRelief(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    }
};
export default reliefApi;
