// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { ReliefStatus } from 'config';
import UIManager from 'core/UIManager';
import ModalCustom from 'components/Modal/ModalCustom';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    reliefId: string;
}

const AskClusterModal = (props: IProps) => {
    const { onClose, reliefId } = props;
    const navigate = useNavigate();

    const handleConfirmAskCM = async () => {
        try {
            const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.OPEN);
            if (!res.error) {
                UIManager.showAlert({
                    message: 'Ask Cluster manager successfully',
                    alert: {
                        color: 'success'
                    }
                });
                onClose();
                navigate('/staff/relief-list', { replace: true });
            } else {
                UIManager.showAlert({
                    message: res?.error?.response?.data?.message,
                    alert: {
                        color: 'error'
                    }
                });
                onClose();
            }
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    return (
        <ModalCustom
            onSave={handleConfirmAskCM}
            onCancel={() => {}}
            onClose={onClose}
            title="Sending relief to Cluster manager"
            contentSave="OK"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography gutterBottom component="div">
                    I'm looking for staff in your cluster, the relief will submit to the cluster manager for approval.
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default AskClusterModal;
