import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import StaffPlanningTabs from '../staff/StaffPlanningTabs';

// const CenterList = Loadable(lazy(() => import('./CenterList')));
const CenterDetails = Loadable(lazy(() => import('./CenterDetails')));

class CenterModule {
    constructor() {
        addRoutes([
            {
                path: '/centre/centre-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster]}>
                        <StaffPlanningTabs tab="3" />
                        {/* <CenterList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/centre/centre-details/:id',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster]}>
                        <CenterDetails />
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new CenterModule();
