import React, { useEffect } from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import TimeSheetSubmiting from './TimeSheetSubmiting';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import defaultApi from 'services/api/defaultApi';
import UIManager from 'core/UIManager';
import { TIMESHEET_STATUS } from 'config';
import moment from 'moment';
import EmptyData from 'components/errors/empty-data';

const ToptitleWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '50px',
    padding: '0px 15px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    '& button': {
        width: 'auto',
        justifyContent: 'flex-start',
        padding: '0px',
        border: '1px solid transparent',
        color: '#3f51b5',
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid transparent'
        }
    },
    '& h1': {
        width: '50%',
        marginLeft: '-16px',
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '1.25rem',
        fontWeight: '400',
        textTransform: 'capitalize'
    }
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    padding: '0px 15px',
    backgroundColor: '#fff',
    '& .timesheet-content': {
        width: '100%',
        height: 'auto',
        padding: '20px 0px',
        '& .status-content': {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            padding: '20px 0px',
            '& .status': {
                padding: '5px 15px',
                borderRadius: '15px',
                '&.Open': {
                    border: '1px solid #3f51b5',
                    color: '#3f51b5'
                },
                '&.Waiting': {
                    border: '1px solid orange',
                    color: 'orange'
                },
                '&.Approved': {
                    border: '1px solid green',
                    color: 'green'
                },
                '&.Rejected': {
                    border: '1px solid red',
                    color: 'red'
                }
            }
        },
        '& .table-content': {
            '& .MuiTableCell-head': {
                borderRight: '1px solid #eeeeee'
            },
            '& .MuiTableCell-body': {
                borderRight: '1px solid #eeeeee'
            }
        }
    },
    '& .action': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0px',
        '& .submit-button': {
            backGroundColor: '#3f51b5',
            color: '#fff',
            '&.disabled': {
                backgroundColor: '#ccc',
                color: '#fff'
            }
        }
    }
}));

interface ICheckInOut {
    handleRedirectStep: (step: number) => void;
    reliefId: string;
}

const TimeSheet = ({ handleRedirectStep, reliefId }: ICheckInOut) => {
    const [timeSheets, setTimeSheets] = React.useState<any>();
    const [reliefAttendances, setReliefAttendances] = React.useState<any>([]);
    const [reason, setReason] = React.useState('');

    const handleBack = () => {
        handleRedirectStep(0);
    };

    useEffect(() => {
        getTimeSheet();
    }, []);

    const getTimeSheet = async () => {
        try {
            const response: any = await defaultApi.getTimeSheetRelief(reliefId);
            if (response) {
                setTimeSheets(response?.data);
                setReliefAttendances(response?.data?.reliefAttendances);
                setReason(response?.data?.rejectReason);
            }
        } catch (errors) {
            console.log(errors);
            UIManager.showAlert({
                message: 'Failed to reload this page!',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const handleSubmiting = async () => {
        UIManager.showModal({
            content: (onClose: any) => <TimeSheetSubmiting onClose={onClose} reliefId={reliefId} getTimeSheet={getTimeSheet} />
        });
    };

    return (
        <>
            <ToptitleWrapper>
                <Button variant="outlined" onClick={handleBack}>
                    <ArrowBackIcon />
                </Button>
                <h1>Timesheet</h1>
            </ToptitleWrapper>
            <ContentWrapper>
                <div className="timesheet-content">
                    <div className="status-content">
                        <div
                            className={`status ${
                                timeSheets?.status === TIMESHEET_STATUS?.WAITING_APPROVAL ? 'Waiting' : timeSheets?.status
                            }`}
                        >
                            <span style={{ marginRight: 8 }}>Status:</span>
                            <span>{timeSheets?.status}</span>
                        </div>
                    </div>
                    <div className="table-content">
                        {reliefAttendances?.length ? (
                            <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                                <Table sx={{ border: '1px solid #eeeeee' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="25%" sx={{ borderRight: '1px solid #eeeeee' }}>
                                                Date
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                In time
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                Out time
                                            </TableCell>
                                            <TableCell width="25%" align="left">
                                                Total hours
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reliefAttendances?.length &&
                                            reliefAttendances?.map((row: any) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell width="25%" style={{ padding: '5px' }}>
                                                        {moment(row?.date).format('DD-MM-YYYY') || '-'}
                                                    </TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {row?.checkIn || '-'}
                                                    </TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {row?.checkOut || '-'}
                                                    </TableCell>
                                                    <TableCell width="25%" align="left">
                                                        {row?.total || '-'}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <EmptyData />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
                {timeSheets?.status === TIMESHEET_STATUS.REJECTED && reason && (
                    <div className="reason" style={{ color: 'red' }}>
                        <span style={{ fontWeight: 600 }}>Rejection Reasons:</span> {reason}
                    </div>
                )}
                <div className="action">
                    <Button
                        variant="contained"
                        className={timeSheets?.status !== TIMESHEET_STATUS?.OPEN ? 'submit-button disabled' : 'submit-button'}
                        onClick={handleSubmiting}
                        disabled={timeSheets?.status !== TIMESHEET_STATUS?.OPEN}
                        style={{ backgroundColor: '#3F51B5' }}
                    >
                        Submit
                    </Button>
                </div>
            </ContentWrapper>
        </>
    );
};

export default TimeSheet;
