// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';

// types

// ----------------------------------------------------------------------

const initialState: { filter: { centerId: string | null; date: any } } = {
    filter: {
        centerId: '',
        date: ''
    }
};

const slice = createSlice({
    name: 'staffByCenter',
    initialState,
    reducers: {
        // HAS ERROR
        setFilter(state, action) {
            state.filter = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function setFilterStaffByCenter(step: { centerId: string | null; date: string }) {
    return () => {
        dispatch(slice.actions.setFilter(step));
    };
}
