import { useEffect, useState } from 'react';
// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    useTheme,
    Grid,
    Typography,
    CardContent
} from '@mui/material';
import { Formik } from 'formik';
import * as lodash from 'lodash';
import * as Yup from 'yup';

// project imports
import useScriptRef from 'services/hooks/useScriptRef';
import AnimateButton from 'components/extended/AnimateButton';

// assets
import { AxiosError } from 'axios';
import { GET_ALL_REQUEST } from 'config';
import staffApi from 'services/api/staffApi';
import { CentreApi } from 'services/api';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { StaffKecho } from 'types/kecho/staff-kecho';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    hricEdit?: StaffKecho;
}

const HricKechoEdit = (props: IProps) => {
    const { hricEdit, callback, onClose } = props;
    const theme = useTheme();
    const centerIds = hricEdit
        ? hricEdit.centers.map((el: any) => {
              return el.id;
          })
        : [];
    const scriptedRef = useScriptRef();
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const [selectedNames, setSelectedNames] = useState(centerIds);

    useEffect(() => {
        handleGetCentreList();
    }, []);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) setCentreData(dataList);
    };

    return (
        <>
            <Formik
                initialValues={{
                    name: hricEdit?.nameStaff,
                    email: hricEdit?.email,
                    centers: selectedNames,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    email: Yup.string().max(255).email().required('Email is required')
                })}
                onSubmit={async ({ name, email, centers }, { setErrors, setStatus, setSubmitting }) => {
                    if (hricEdit?.id)
                        staffApi
                            .updateStaff(hricEdit.id, {
                                name,
                                email,
                                centerIds: selectedNames
                            })
                            .then(() => {
                                callback();
                                onClose();
                            })
                            .catch((err) => {
                                if (scriptedRef.current && err instanceof AxiosError) {
                                    const errorMessage = lodash.isArray(err?.response?.data?.message)
                                        ? err?.response?.data?.message[0]
                                        : err?.response?.data?.message;
                                    setStatus({ success: false });
                                    setErrors({ submit: errorMessage });
                                    setSubmitting(false);
                                }
                            });
                    else
                        staffApi
                            .createStaff({
                                name,
                                email,
                                centerIds: selectedNames
                            })
                            .then(() => {
                                callback();
                                onClose();
                            })
                            .catch((err) => {
                                if (scriptedRef.current && err instanceof AxiosError) {
                                    const errorMessage = lodash.isArray(err?.response?.data?.message)
                                        ? err?.response?.data?.message[0]
                                        : err?.response?.data?.message;
                                    setStatus({ success: false });
                                    setErrors({ submit: errorMessage });
                                    setSubmitting(false);
                                }
                            });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl fullWidth error={Boolean(touched.name && errors.name)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-name-register"> Name</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-name-register"
                                type="text"
                                value={values.name}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.name && errors.name && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register"> Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="text"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth error={Boolean(touched.centers && errors.centers)}>
                            <InputLabel id="demo-simple-select-label" style={{ top: '15px' }}>
                                Center
                            </InputLabel>
                            <Select
                                style={{ lineHeight: '1.4375em', minHeight: '62px', height: 'auto', marginTop: '8px' }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="centers"
                                value={selectedNames}
                                label="Center"
                                multiple
                                onChange={(e: any) => {
                                    setSelectedNames(e.target.value);
                                }}
                            >
                                {centreData.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.nameCenter}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting || selectedNames.length === 0}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    {!hricEdit ? 'Create' : 'Save'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

const HrIcModal = (props: IProps) => {
    const { onClose, callback, hricEdit } = props;
    return (
        <CardContent>
            <Grid container>
                <Grid item xs={11} textAlign="center">
                    <Typography variant="h4" gutterBottom component="div">
                        {!hricEdit ? 'Create' : 'Edit'} HR-IC
                    </Typography>
                </Grid>
                <Grid item xs={1} onClick={onClose} style={{ cursor: 'pointer' }}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <HricKechoEdit callback={callback} onClose={onClose} hricEdit={hricEdit} />
        </CardContent>
    );
};

export default HrIcModal;
