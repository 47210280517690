import { useState, useEffect } from 'react';
import { Grid, Box, FormControl, InputLabel, MenuItem, Select, CardContent } from '@mui/material';
import CardTotal from '../components/CardTotal';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListClass from '../components/ListClass';
import ListLeave from '../components/ListLeave';
import ChartCard from '../components/Chart';
import NextWeek from '../components/NextWeek';
import DashboardApi from 'services/api/dashboardApi';
import { v4 as uuidv4 } from 'uuid';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles, useStyles } from 'config';
import { CentreApi } from 'services/api';
import { CentreKecho } from 'types/kecho/centre-kecho';

function Principal() {
    const classes = useStyles();
    const [respected, setRespected] = useState<any>();
    const [listClassDashBoard1, setListClassDashBoard1] = useState<any>([]);
    const [listClassDashBoard2, setListClassDashBoard2] = useState<any>([]);
    const [leaveDashBoard, setLeaveDashBoard] = useState<any>([]);
    const [nextweekrespected, setnextweekrespected] = useState<any>([]);
    const [chartDashboard, setchartDashboard] = useState<any>([]);
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const [centerId, setCenterId] = useState<string | undefined>();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;

    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;

    useEffect(() => {
        if (isShowCenterFilter()) {
            handleGetCentreList();
        } else {
            setCenterId(userCenterID || userSelectionCenterID);
        }
    }, []);
    useEffect(() => {
        if (centerId || !isShowCenterFilter()) {
            handleGetTotal();
            handleGetListClassDashBoard1();
            handleGetListClassDashBoard2();
            handleGetLeaveDashBoard();
            handleGetchartDashboard();
            handleGetnextweekrespected();
        }
    }, [centerId]);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            setCenterId(dataList[0]?.id);
        }
    };
    const handleGetListClassDashBoard1 = async () => {
        const { data } = await DashboardApi.listClassDashBoard1(centerId);
        if (data) {
            setListClassDashBoard1(data);
        }
    };
    const handleGetListClassDashBoard2 = async () => {
        const { data } = await DashboardApi.listClassDashBoard2(centerId);
        if (data) {
            setListClassDashBoard2(data);
        }
    };

    const handleGetTotal = async () => {
        const { data } = await DashboardApi.respected(centerId);
        if (data) {
            setRespected(data);
        }
    };

    const handleGetLeaveDashBoard = async () => {
        const { data } = await DashboardApi.leaveDashBoard(centerId);
        if (data) {
            setLeaveDashBoard(data);
        }
    };

    const handleGetnextweekrespected = async () => {
        const { data } = await DashboardApi.nextweekrespected(centerId);
        if (data) {
            setnextweekrespected(data);
        }
    };

    const handleGetchartDashboard = async () => {
        const { data } = await DashboardApi.chartDashboard(centerId);
        if (data) {
            setchartDashboard(data);
        }
    };
    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const getMonth = () => {
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const d = new Date();
        return month[d.getMonth()];
    };
    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            <CardContent style={{ paddingTop: 0 }}>
                <Box>
                    {isShowCenterFilter() ? (
                        <FormControl style={{ width: 120, marginBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Center</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ width: 320 }}
                                name="centerId"
                                label="Center"
                                value={String(centerId)}
                                onChange={(e) => {
                                    setCenterId(e.target.value);
                                }}
                                size="small"
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }}
                            >
                                {centreData?.map((centre) => (
                                    <MenuItem key={uuidv4()} value={centre.id}>
                                        {centre.nameCenter}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        ''
                    )}

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} lg={3} sm={3}>
                            <CardTotal
                                primary="Brand"
                                secondary={Number(respected?.ratioBrand || '0').toFixed(2)}
                                content="May 23 - June 01 (2018)"
                                iconPrimary={AssignmentTurnedInIcon}
                                day={respected?.numberDayBrand || '0'}
                                type={getMonth()}
                                color="primary.main"
                                bgcolor="primary.light"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4.5} sm={4.5}>
                            <ListClass
                                title="30 Days - Forecast (UnderStaff)"
                                data={listClassDashBoard1}
                                type="under"
                                centerId={centerId || userCenterID || userSelectionCenterID}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4.5} sm={4.5}>
                            <ListLeave title="Leave in a glance (7 days)" data={leaveDashBoard} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} lg={3} sm={4}>
                            <CardTotal
                                primary="ECDA"
                                secondary={Number(respected?.ratioECDA || '0').toFixed(2)}
                                content="May 23 - June 01 (2018)"
                                day={respected?.numberDayECDA || '0'}
                                type={getMonth()}
                                iconPrimary={AssignmentTurnedInIcon}
                                color="primary.main"
                                bgcolor="primary.light"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4.5} sm={4.5}>
                            <ListClass
                                title="30 Days - Forecast (OverStaff)"
                                data={listClassDashBoard2 || []}
                                type="over"
                                centerId={centerId || userCenterID || userSelectionCenterID}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4.5} sm={4.5}>
                            <NextWeek title="New week in a glance" data={nextweekrespected} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} sm={6}>
                            <ChartCard title="Number of HRS (OverStaff)" data={chartDashboard || []} month={getMonth()} />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={6}>
                            <ChartCard title="Number of HRS (UnderStaff)" data={chartDashboard || []} month={getMonth()} type="under" />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </div>
    );
}

export default Principal;
