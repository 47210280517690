// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, Grid, Typography, CardContent } from '@mui/material';

// project imports
import { StaffKecho } from 'types/kecho/staff-kecho';
import { StaffApi } from 'services/api';
import UIManager from 'core/UIManager';
import ModalCustom from 'components/Modal/ModalCustom';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    staffEdit?: StaffKecho;
}

const DeleteStaffModal = (props: IProps) => {
    const { onClose, callback, staffEdit } = props;

    const handleDeleteStaff = async () => {
        if (staffEdit?.id) {
            const { data } = await StaffApi.deleteStaff(staffEdit?.id);
            if (data) {
                UIManager.showAlert({
                    message: 'Delete staff successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteStaff}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentCancel="Cancel"
            contentSave="Yes"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure to delete staff &quot;{staffEdit?.nameStaff}&quot;?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteStaffModal;
