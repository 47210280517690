// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, CardContent, Grid, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import UIManager from 'core/UIManager';

// project imports
import QualificationApi from 'services/api/qualificationApi';
import { QualificationKecho } from 'types/kecho/relief';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    qualification?: QualificationKecho;
}

const DeleteModal = (props: IProps) => {
    const { onClose, callback, qualification } = props;

    const handleDeleteStaff = async () => {
        if (qualification?.id) {
            const { data } = await QualificationApi.deleteQualification(qualification?.id);
            if (data) {
                UIManager.showAlert({
                    message: 'Delete successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteStaff}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure you want to delete this qualification ?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteModal;
