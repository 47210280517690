// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// template imports
import snackbarReducer from './slices/template/snackbar';
import customerReducer from './slices/template/customer';
import contactReducer from './slices/template/contact';
import productReducer from './slices/template/product';
import chatReducer from './slices/template/chat';
import calendarReducer from './slices/template/calendar';
import mailReducer from './slices/template/mail';
import userReducer from './slices/template/user';
import cartReducer from './slices/template/cart';
import kanbanReducer from './slices/template/kanban';
// kecho imports
import menuReducer from './slices/kecho/core/menu';
import systemReducer from './slices/kecho/core/system';
import center from './slices/template/center';
import staffList from './slices/template/staffList';
import classList from './slices/template/classList';
import selectCenter from './slices/template/selectCenter';

// ==============================|| COMBINE REDUCER ||============================== //
const reducerTemplate = {
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    center,
    staffList,
    classList,
    selectCenter
};

const reducerKecho = {
    menu: menuReducer,
    system: systemReducer
};

const reducer = combineReducers({
    ...reducerTemplate,
    ...reducerKecho
});

export default reducer;
