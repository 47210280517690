import * as React from 'react';
import styles from './AbsentList.module.scss';
// material-ui
import {
    Box,
    Button,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';
import { upperFirst, debounce } from 'lodash';
import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import UIManager from 'core/UIManager';
import { CentreApi, LeaveApi, RatioApi } from 'services/api';
import { LeaveKecho } from 'types/kecho/leave-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { UploadFileModal, DeleteModal } from './components';
import AddAbsenceModal from '../CalculationStaffRatio/List/Components/ClassTable/components/AbsenceModal';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { v4 as uuidv4 } from 'uuid';
import EmptyData from 'components/errors/empty-data';
import { StyledTableCell } from 'components/Table/TableCustom';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'nameStaff',
        label: 'Staff Name',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left'
    },
    {
        id: 'dateApproved',
        label: 'Date Approved',
        align: 'left'
    },
    {
        id: 'dateLeave',
        label: 'Date Leave',
        align: 'left'
    },
    {
        id: 'typeLeave',
        label: 'Absence Type',
        align: 'left'
    },
    {
        id: 'remark',
        label: 'Remark',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {/* {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </TableCell>
                )} */}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const AbsenceList = () => {
    const classes = useStyles();
    const [params] = useSearchParams();
    const idParam: any = params.get('idLeave');
    const centerId: any = params.get('centerId');
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [selected] = React.useState<string[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const [leaveList, setLeaveList] = React.useState<LeaveKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
        else setFilter({ ...filter, centreId: userCenterID || userSelectionCenterID || centerId });
    }, [centerId]);
    React.useEffect(() => {
        handleGetList();
    }, [filter]);

    const handleGetList = async () => {
        const payload = { ...filter, idRef: idParam, order: 'DESC' };

        const { dataList, meta } = await LeaveApi.listLeave(payload);
        if (dataList) {
            setLeaveList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query, page: 1 });
    };
    const handleSearch = debounce(onChange, 500);

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenModalAddAbsence = () => {
        UIManager.showModal({
            content: (onClose: any) => <AddAbsenceModal onClose={onClose} callback={handleGetList} centreId={filter.centreId} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleUploadFileModal = () => {
        UIManager.showModal({
            content: (onClose: any) => <UploadFileModal onClose={onClose} onUpload={handleUploadFile} />
        });
    };

    const handleUploadFile = async (formData: FormData, isApprove = false) => {
        if (isApprove) {
            const { data } = await LeaveApi.importApproveLeaveExcel(formData);
            if (data) {
                RatioApi.checkLeave();
                UIManager.showAlert({
                    message: 'Import file successfully',
                    alert: {
                        color: 'success'
                    }
                });
                handleGetList();
                return true;
            }
            return false;
        }
        const { data } = await LeaveApi.importPendingLeaveExcel(formData);
        if (data) {
            UIManager.showAlert({
                message: 'Import file successfully',
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
            return true;
        }
        return false;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const handleOpenDeleteModal = (leave: LeaveKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteModal onClose={onClose} callback={handleGetList} leave={leave} />
        });
    };
    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            if (isShowCenterFilter()) {
                if (centerId) {
                    setFilter({ ...filter, centreId: centerId });
                } else {
                    setFilter({ ...filter, centreId: dataList[0]?.id });
                }
            }
        }
    };
    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Absence List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            size="small"
                            style={{ marginRight: 12 }}
                        />
                        {isShowCenterFilter() ? (
                            <FormControl style={{ width: 'auto', minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Center</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="centerId"
                                    label="Center"
                                    value={String(filter.centreId)}
                                    onChange={(e) => {
                                        setFilter({ ...filter, centreId: e.target.value, page: 1 });
                                    }}
                                    size="small"
                                    MenuProps={{
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }}
                                >
                                    {centreData?.map((centre) => (
                                        <MenuItem key={uuidv4()} value={centre.id}>
                                            {centre.nameCenter}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            ''
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Import File Absence" placement="top" style={{ marginRight: 8 }}>
                                <Button
                                    variant="outlined"
                                    style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginLeft: 10 }}
                                    onClick={handleUploadFileModal}
                                    startIcon={<UploadFileIcon sx={{ fontSize: '1.3rem' }} />}
                                >
                                    Import
                                </Button>
                            </Tooltip>
                            {/* <Tooltip title="Create New Absence" placement="top">
                                <Button variant="contained" color="secondary" size="large" onClick={handleOpenModalAddAbsence}>
                                    <AddIcon sx={{ fontSize: '1.3rem' }} />
                                </Button>
                            </Tooltip> */}

                            <Tooltip title="Add new absence" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenModalAddAbsence}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {leaveList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={leaveList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {leaveList &&
                                    leaveList.map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row?.id}>
                                                <TableCell>{row?.staff?.nameStaff}</TableCell>
                                                <TableCell>{upperFirst(row?.status)}</TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    {row?.dateApproved ? moment(row?.dateApproved).format('DD/MM/YYYY') : '---'}
                                                </TableCell>

                                                <TableCell align="left" className={styles.cell}>
                                                    {row?.dateLeave ? moment(row?.dateLeave).format('DD/MM/YYYY') : '---'}
                                                </TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    {row?.typeLeave}
                                                </TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    <Tooltip placement="bottom" title={row?.remark}>
                                                        <span>{row?.remark}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {!row?.isInfoTech && (
                                                        <Tooltip placement="top" title="Remove">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                                                onClick={() => handleOpenDeleteModal(row)}
                                                            >
                                                                <DeleteOutlineIcon
                                                                    sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }}
                                                                />{' '}
                                                                Remove
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* table pagination */}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default AbsenceList;
