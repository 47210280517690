// material-ui
import { Chip, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';
import { v4 as uuidv4 } from 'uuid';

// assets
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { convertTime, formatDate } from 'config';

// =========================|| DATA WIDGET - INCOMING REQUESTS CARD ||========================= //

interface IProps {
    title: string;
    data: any;
    type?: string;
    centerId?: string;
}

const IncomingRequests = ({ title, data, type, centerId }: IProps) => {
    const navigate = useNavigate();
    const rangerTime = (times: string[]) => {
        return `${convertTime(times[0])} - ${convertTime(times.pop())}`;
    };

    function formatTimeRange(times: string[]): string {
        const formattedTimes = times.map((time) => {
            const [hours, minutes] = time.includes('.') ? time.split('.') : [time, '0'];
            return `${hours.padStart(2, '0')}:${minutes === '5' ? '30' : '00'}`;
        });

        let result = '';
        let rangeStart = formattedTimes[0];

        for (let i = 1; i < formattedTimes.length; i += 1) {
            const prev = parseFloat(times[i - 1]);
            const curr = parseFloat(times[i]);

            if (curr - prev > 0.5) {
                result += `${rangeStart} - ${formattedTimes[i - 1]}, `;
                rangeStart = formattedTimes[i];
            }
        }

        result += `${rangeStart} - ${formattedTimes[formattedTimes.length - 1]}`;
        return result;
    }

    return (
        <MainCard title={title} content={false} style={{ height: 250 }} className="box-tag-staff">
            <PerfectScrollbar>
                <List component="nav" aria-label="main mailbox folders">
                    {data?.map((item: any) => {
                        let timeUnderEcda: string[] = [];
                        let timeUnderBrand: string[] = [];
                        let timeOverEcda: string[] = [];
                        let timeOverBrand: string[] = [];

                        let listUnderStaff: any[] = [];
                        let listOverStaff: any[] = [];
                        if (type === 'under') {
                            timeUnderBrand = item.underStaff?.filter((elem: any) => elem.type === 'brand')?.map((elem: any) => elem.time);
                            timeUnderEcda = item.underStaff?.filter((elem: any) => elem.type === 'ecda')?.map((elem: any) => elem.time);
                            listUnderStaff = item.underStaff;
                        }
                        if (type === 'over') {
                            timeOverBrand = item.overStaff?.filter((elem: any) => elem.type === 'brand')?.map((elem: any) => elem.time);
                            timeOverEcda = item.overStaff?.filter((elem: any) => elem.type === 'ecda')?.map((elem: any) => elem.time);
                            listOverStaff = item.overStaff;
                        }
                        return (
                            <>
                                {((timeUnderBrand && timeUnderBrand.length > 0) || (timeOverBrand && timeOverBrand.length > 0)) && (
                                    <>
                                        <ListItemButton
                                            key={uuidv4()}
                                            onClick={() =>
                                                navigate(
                                                    `/staff/cal-staff-ratio?idRatio=${item.id}&centreId=${centerId}&date=${moment(
                                                        item.date
                                                    ).format(formatDate.DATE_DEFAULT)}`
                                                )
                                            }
                                        >
                                            <ListItemIcon style={{ marginRight: 10, width: '15%' }}>
                                                <Chip label="Brand" color="error" variant="outlined" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`In class ${item.class.nameClass} you are ${
                                                    type === 'under' ? 'under' : 'over'
                                                }staffed${
                                                    type === 'under'
                                                        ? // ? `: ${timeUnderBrand?.map((time: string) => convertTime(time)).join(', ')}`
                                                          `: ${formatTimeRange(timeUnderBrand)}`
                                                        : `: ${rangerTime(timeOverBrand)}`
                                                }`}
                                                style={{ width: '90%' }}
                                            />
                                            <ListItemText
                                                primary={moment(item.date).format('DD-MM-YYYY')}
                                                style={{ textAlign: 'end', width: '30%' }}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                )}
                                {((timeUnderEcda && timeUnderEcda.length > 0) || (timeOverEcda && timeOverEcda.length > 0)) && (
                                    <>
                                        <ListItemButton
                                            key={uuidv4()}
                                            onClick={() =>
                                                navigate(
                                                    `/staff/cal-staff-ratio?idRatio=${item.id}&centreId=${centerId}&date=${moment(
                                                        item.date
                                                    ).format(formatDate.DATE_DEFAULT)}`
                                                )
                                            }
                                        >
                                            <ListItemIcon style={{ marginRight: 10, width: '15%' }}>
                                                <Chip label="ECDA" color="info" variant="outlined" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`In class ${item.class.nameClass} you are ${
                                                    type === 'under' ? 'under' : 'over'
                                                }staffed${
                                                    type === 'under'
                                                        ? // ? `: ${timeUnderEcda?.map((time: string) => convertTime(time)).join(', ')}`
                                                          `: ${formatTimeRange(timeUnderEcda)}`
                                                        : `: ${rangerTime(timeOverEcda)}`
                                                }`}
                                                style={{ width: '90%' }}
                                            />
                                            <ListItemText
                                                primary={moment(item.date).format('DD-MM-YYYY')}
                                                style={{ textAlign: 'end', width: '30%' }}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                )}
                                {(!listUnderStaff || !listOverStaff) && (
                                    <>
                                        <ListItemButton
                                            key={uuidv4()}
                                            onClick={() =>
                                                navigate(
                                                    `/staff/cal-staff-ratio?idRatio=${item.id}&centreId=${centerId}&date=${moment(
                                                        item.date
                                                    ).format(formatDate.DATE_DEFAULT)}`
                                                )
                                            }
                                        >
                                            <ListItemIcon style={{ marginRight: 10, width: '15%' }}>
                                                <Chip label="All" color="default" variant="outlined" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`In class ${item.class.nameClass} you are ${
                                                    type === 'under' ? 'under' : 'over'
                                                }staffed`}
                                                style={{ width: '90%' }}
                                            />
                                            <ListItemText
                                                primary={moment(item.date).format('DD-MM-YYYY')}
                                                style={{ textAlign: 'end', width: '30%' }}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                )}
                            </>
                        );
                    })}
                </List>
            </PerfectScrollbar>
        </MainCard>
    );
};

export default IncomingRequests;
