import * as React from 'react';
import styles from './reliefList.module.scss';
// material-ui
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import RejectIcon from 'assets/images/icons/page-actions/tabler-icon-ban.svg';
import AcceptIcon from 'assets/images/icons/page-actions/tabler-icon-check.svg';
import DenyIcon from 'assets/images/icons/page-actions/tabler-icon-circle-minus.svg';
import LateIcon from 'assets/images/icons/page-actions/tabler-icon-clock-cancel.svg';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import OnHoldIcon from 'assets/images/icons/page-actions/tabler-icon-hold.svg';
import AskWakyuIcon from 'assets/images/icons/page-actions/tabler-icon-message.svg';
import AssignTicketIcon from 'assets/images/icons/page-actions/tabler-icon-plus.svg';
import DeleteRedIcon from 'assets/images/icons/page-actions/tabler-icon-trash.svg';
import MissingIcon from 'assets/images/icons/page-actions/tabler-icon-warning.svg';
// import AssignmentIcon from '@mui/icons-material/Assignment';

import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, Order, RELIEF_FROM, ReliefStatus, Roles, SEARCH_SCOPE } from 'config';

import { EnhancedTableHeadProps, EnhancedTableToolbarProps, HeadCell } from 'types';
import { upperCaseTextName } from 'utils/common/upperCaseText';

import { IFilterRequest, IMetaData } from 'types/query/filter';

import { useLocation, useSearchParams } from 'react-router-dom';

import EmptyData from 'components/errors/empty-data';

import { IconCurrencyDollarSingapore } from '@tabler/icons';
import { StyledTableCell } from 'components/Table/TableCustom';
import UIManager from 'core/UIManager';
import QRCode from 'react-qr-code';
import reliefApi from 'services/api/reliefApi';
import { calculateTotalPriceRelief } from 'utils/common/calculateTotalPrice';
import { formatDateCustomFormat, formatDateOnly } from 'utils/common/formatDate';
import { navigatePath } from 'utils/utils';
import AddReliefModal from '../CalculationStaffRatio/List/Components/ClassTable/components/ReliefModal/ReliefModal';
import AssignRelieftModal from './components/AssignReliefModal';
import AssignTemporaryRelieftModal from './components/AssignTemporaryReliefModal';
import DeleteReliefModal from './components/DeleteReliefModal';
import DetailReliefModal from './components/DetailReliefModal';
import RejectReliefModal from './components/RejectReliefModal';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'centerName',
        label: 'Center Name',
        align: 'left'
    },
    {
        id: 'startDate',
        label: 'Start Date',
        align: 'left'
    },
    {
        id: 'endDate',
        label: 'End Date',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left'
    },
    {
        id: 'certificate',
        label: 'Certifications',
        align: 'left'
    },
    {
        id: 'candidate',
        label: 'Candidate',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, selected }: CustomerListEnhancedTableHeadProps) {
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ p: 3 }}>
                {numSelected > 0 && (
                    <StyledTableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </StyledTableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                {/* {numSelected <= 0 && (
                    <StyledTableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        Action
                    </StyledTableCell>
                )} */}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Nutrition
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

// ==============================|| CUSTOMER LIST ||============================== //

const ReliefList = () => {
    const location = useLocation();
    const idRef = location.state?.idRef;
    const [selected] = React.useState<string[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [reliefList, setReliefList] = React.useState<any[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, order: 'DESC', sortColumn: 'createdAt' });
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;

    const [searchParams] = useSearchParams();
    const centreId: any = searchParams.get('centreId');
    // React.useEffect(() => {
    //     handleGetList();
    // }, []);

    React.useEffect(() => {
        handleGetList();
    }, [filter]);

    const handleGetList = async () => {
        const payload = {
            ...filter,
            page: filter.page,
            limit: filter.limit,
            sortColumn: filter.sortColumn,
            order: filter.order,
            scope: SEARCH_SCOPE.IN_CLUSTER,
            from: RELIEF_FROM.MY_CLUSTER
        };
        const { data } = await reliefApi.getReliefList(payload);
        if (data) {
            setReliefList(data.data);
            setMetaData(data.meta);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const handleApproveRelief = (relief: any) => async () => {
        navigatePath(
            `/ask-wakyu?idRelief=${relief.id}&startDate=${formatDateCustomFormat(
                relief.startDate,
                'yyyy-MM-dd'
            )}&endDate=${formatDateCustomFormat(relief.endDate, 'yyyy-MM-dd')}`
        );
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const handleRejectRelief = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <RejectReliefModal onClose={onClose} relief={relief.id} callback={handleGetList} />
        });
    };

    const handleDeleteRelief = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteReliefModal onClose={onClose} relief={relief} callback={handleGetList} />
        });
    };

    const handleAcceptRelief = (relief: any) => async () => {
        const { data } = await reliefApi.UpdateReliefById(relief.id, ReliefStatus.COMPLETED);
        if (data) {
            // hien thong bao Relief accepted
            UIManager.showAlert({
                message: 'Relief accepted',
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
            // navigatePath(`/staff/cal-staff-ratio`);
            // copyToClipBoard(relief?.staff?.email || '');
        }
    };

    const handleUpdateStateRelief = (relief: any, status: ReliefStatus) => async () => {
        let statusUpdate = '';
        if (status === ReliefStatus.RELIEF_ON_HOLD) {
            statusUpdate = 'On-hold';
        } else if (status === ReliefStatus.MISSING_ACTION) {
            statusUpdate = 'Missing in action';
        } else if (status === ReliefStatus.RESOLVED_LATE) {
            statusUpdate = 'Resolve late';
        } else if (status === ReliefStatus.READY_FOR_ASSIGNMENT) {
            statusUpdate = 'Ready for assignment';
        } else if (status === ReliefStatus.CM_REJECT) {
            statusUpdate = 'Reject';
        }
        const res = await reliefApi.UpdateReliefById(relief.id, status);
        if (res) {
            UIManager.showAlert({
                message: `Relief status updated to "${statusUpdate}"`,
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
        }
    };

    const handleDenyRelief = (relief: any) => async () => {
        const res = await reliefApi.UpdateReliefById(relief.id, ReliefStatus.PRIN_REJECT);
        if (res) {
            UIManager.showAlert({
                message: `Relief status updated to "Principal reject"`,
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
        }
    };

    const handleAssignRelief = (reliefId: string) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <AssignRelieftModal reliefId={reliefId} onClose={onClose} callback={handleGetList} />,
            stylesModal: {
                minWidth: '1200px'
            }
        });
    };

    const handleAssignTemporaryRelief = (reliefId: string) => async () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <AssignTemporaryRelieftModal reliefId={reliefId} onClose={onClose} callback={handleGetList} roleAction="principal" />
            ),
            stylesModal: {
                minWidth: '900px'
            }
        });
    };

    const handleOpenModalDetail = (relief: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DetailReliefModal onClose={onClose} relief={relief.id} />
        });
    };

    const copyToClipBoard = (text: string) => {
        navigator.clipboard.writeText(text);
        UIManager.showAlert({
            message: 'Copy to clipboard success',
            alert: {
                color: 'success'
            }
        });
    };

    const handleOpenModalAskRelief = () => {
        UIManager.showModal({
            content: (onClose: any) => <AddReliefModal onClose={onClose} centerId={centreId || userCenterID} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const statusColors: { [key: string]: 'default' | 'primary' | 'error' | 'success' | 'info' | 'warning' | 'secondary' } = {
        Open: 'primary',
        Completed: 'secondary',
        'CM rejected': 'error',
        Approved: 'info',
        'Relief on-hold': 'warning',
        'Resolved late': 'error',
        'Missing in action': 'error',
        'Pending assignation': 'secondary',
        'Relief assigned': 'success',
        'Principal completed': 'success',
        Draft: 'warning'
    };

    const renderButtonAction = (relief: any) => {
        if (relief?.status === ReliefStatus.DRAFT && userRole === Roles.Principals) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleApproveRelief(relief)}
                    >
                        <img src={AskWakyuIcon} alt="ask wakyu" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Ask Wakyu
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleDeleteRelief(relief)}
                    >
                        <img src={DeleteRedIcon} alt="delete" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Delete
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.OPEN && userRole === Roles.Cluster) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleApproveRelief(relief)}
                    >
                        <img src={AskWakyuIcon} alt="ask wakyu" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Ask Wakyu
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleRejectRelief(relief)}
                    >
                        <img src={RejectIcon} alt="reject" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Reject
                    </Button>
                </>
            );
        }
        if (
            (relief?.status === ReliefStatus.APPROVED ||
                relief?.status === ReliefStatus.RELIEF_ASSIGNED ||
                relief?.status === ReliefStatus.CM_ASSIGNED_RELIEF) &&
            userRole === Roles.Principals
        ) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleAcceptRelief(relief)}
                    >
                        <img src={AcceptIcon} alt="accept" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Accept
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleDenyRelief(relief)}
                    >
                        <img src={DenyIcon} alt="deny" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Deny
                    </Button>
                </>
            );
        }
        if (
            (relief?.status === ReliefStatus.PENDING_ASSIGNATION || relief?.status === ReliefStatus.RESOLVED_LATE) &&
            (userRole === Roles.HrAdmin || userRole === Roles.HrIc)
        ) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', whiteSpace: 'nowrap', marginBottom: 10 }}
                        onClick={handleAssignRelief(relief.id)}
                    >
                        <img src={AssignTicketIcon} alt="assign ticket" style={{ width: '22px', height: '22px', marginRight: '5px' }} />{' '}
                        Assign ticket
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.COMPLETED && (userRole === Roles.HrAdmin || userRole === Roles.HrIc)) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#555555', border: '1px solid #555555', marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.RELIEF_ON_HOLD)}
                    >
                        <img src={OnHoldIcon} alt="on hold" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> On Hold
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.RELIEF_ON_HOLD && (userRole === Roles.HrAdmin || userRole === Roles.HrIc)) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#555555', border: '1px solid #555555', marginRight: 10, marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.MISSING_ACTION)}
                    >
                        <img src={MissingIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Missing
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#555555', border: '1px solid #555555', marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.RESOLVED_LATE)}
                    >
                        <img src={LateIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Late
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.PENDING_APPROVAL && userRole === Roles.Cluster) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.READY_FOR_ASSIGNMENT)}
                    >
                        <img src={AcceptIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Approve
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.CM_REJECT)}
                    >
                        <img src={RejectIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Reject
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.READY_FOR_ASSIGNMENT && userRole === Roles.Principals) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleAssignTemporaryRelief(relief.id)}
                    >
                        <img src={AssignTicketIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Assign
                        Relief
                    </Button>
                </>
            );
        }
        return <></>;
    };

    const renderAction = (relief: any) => {
        return (
            <>
                <div className="group-button-actions">
                    {userRole === Roles.Principals && (
                        <Button
                            variant="contained"
                            style={
                                !relief?.reliefStaff?.isTemporary
                                    ? { background: '#e1e1e1', marginRight: 10, marginBottom: 10 }
                                    : { background: '#3f51b5', marginRight: 10, marginBottom: 10 }
                            }
                            onClick={() => handleCreateQrcode(relief)}
                            disabled={!relief?.reliefStaff?.isTemporary}
                        >
                            Create QR
                        </Button>
                    )}

                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={() => handleOpenModalDetail(relief)}
                    >
                        <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                    </Button>
                    {renderButtonAction(relief)}
                </div>
            </>
        );
    };

    const handleCreateQrcode = (relief: any) => {
        if (userRole !== Roles.Principals) {
            UIManager.showAlert({
                message: 'You are not allowed to create QR code',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        const urlOrigin = window.location.origin;
        const reliefEmail = relief?.reliefTicket?.email || relief?.reliefStaff?.email || '';
        const reliefId = relief?.id || '';
        const centerName = encodeURIComponent(relief?.center?.nameCenter) || '';
        const reliefName = encodeURIComponent(relief?.reliefStaff?.nameStaff) || '';
        UIManager.showModal({
            content: (onClose: any) => (
                <div
                    style={{
                        width: '500px',
                        height: '500px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Typography fontSize={20} fontWeight={600} mb={1}>
                            Scan the QR to access Kecho Attendance
                        </Typography>
                        <Typography fontSize={16} fontWeight={400}>
                            Candidate: {reliefEmail}
                        </Typography>
                    </div>
                    <QRCode
                        value={`${urlOrigin}/staff/attendance/${reliefName}/${centerName}/${reliefId}`}
                        style={{ width: '300px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    />
                    <div className="redirect-attendance" style={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            style={{ background: '#3f51b5' }}
                            onClick={() => {
                                navigatePath(`/staff/attendance/${reliefName}/${centerName}/${reliefId}`);
                            }}
                        >
                            Go to Attendance
                        </Button>
                    </div>
                </div>
            )
        });
    };

    return (
        <Box sx={{ minHeight: '80vh', height: 'auto', padding: '0px 24px', backgroundColor: 'white' }}>
            {/* <MainCard title="Relief Ticket" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            {isShowCenterFilter() ? null : (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '12px' }}>
                    <Button
                        variant="outlined"
                        sx={{ color: '#3F51B5', border: '1px solid #3F51B5', position: 'relative', right: 0 }}
                        onClick={handleOpenModalAskRelief}
                    >
                        Ask a relief
                    </Button>
                </Box>
            )}
            {reliefList.length ? (
                <Box>
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={filter.order}
                                orderBy={filter.sortColumn}
                                onSelectAllClick={() => {}}
                                onRequestSort={handleRequestSort}
                                rowCount={reliefList.length}
                                selected={selected}
                            />
                            <TableBody>
                                {reliefList &&
                                    reliefList.map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row?.id} selected={row?.id === idRef}>
                                                <TableCell>{upperCaseTextName(row?.center?.nameCenter)}</TableCell>

                                                <TableCell align="left" className={styles.cell}>
                                                    {formatDateOnly(row?.startDate)}
                                                </TableCell>

                                                <TableCell align="left" className={styles.cell}>
                                                    {formatDateOnly(row?.endDate)}
                                                </TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    <Chip
                                                        key={index}
                                                        color={statusColors[row?.status] || 'default'}
                                                        style={{ marginRight: 8 }}
                                                        label={row?.status}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row?.qualification?.qualificationCert?.length > 2 ? (
                                                        <>
                                                            {row?.qualification?.qualificationCert?.slice(0, 2).map((item: any) => (
                                                                <Chip
                                                                    key={item.id}
                                                                    label={item?.cert?.diploma}
                                                                    style={{ marginRight: 8 }}
                                                                />
                                                            ))}
                                                            <Tooltip
                                                                title={row?.qualification?.qualificationCert
                                                                    ?.slice(2)
                                                                    ?.map((item: any) => item?.cert?.diploma)
                                                                    .join(', ')}
                                                            >
                                                                <Chip
                                                                    label={`+${row?.qualification?.qualificationCert?.length - 2} more`}
                                                                    style={{ marginRight: 8, cursor: 'help' }}
                                                                    size="small"
                                                                    variant="outlined"
                                                                />
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        row?.qualification?.qualificationCert?.map((item: any) => (
                                                            <Chip key={item.id} label={item?.cert?.diploma} style={{ marginRight: 8 }} />
                                                        ))
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" className={styles.cell}>
                                                    {row?.reliefStaff && (
                                                        <div style={{ marginTop: 5 }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 10,
                                                                    fontWeight: 500
                                                                }}
                                                            >
                                                                <IconCurrencyDollarSingapore style={{ width: 20 }} />
                                                                {calculateTotalPriceRelief(
                                                                    row?.startDate,
                                                                    row?.endDate,
                                                                    row?.workHour,
                                                                    row?.qualification?.price
                                                                )}
                                                            </div>
                                                            <Typography
                                                                style={{ cursor: 'copy', color: '#5D8BF4' }}
                                                                onClick={() => copyToClipBoard(row?.reliefStaff?.email)}
                                                            >
                                                                {row?.reliefStaff?.email}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {row?.reliefTicket && (
                                                        <div style={{ marginTop: 5 }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 10,
                                                                    fontWeight: 500
                                                                }}
                                                            >
                                                                <IconCurrencyDollarSingapore style={{ width: 20 }} />
                                                                {calculateTotalPriceRelief(
                                                                    row?.startDate,
                                                                    row?.endDate,
                                                                    row?.workHour,
                                                                    row?.reliefTicket?.salary
                                                                )}
                                                            </div>
                                                            <Typography
                                                                style={{ cursor: 'copy', color: '#5D8BF4' }}
                                                                onClick={() => copyToClipBoard(row?.reliefTicket?.email)}
                                                            >
                                                                {row?.reliefTicket?.email}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">{renderAction(row)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </Box>
    );
};

export default ReliefList;
