import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StatusAttendaceEnum } from 'config';
import React from 'react';

interface Props {
    status: any;
}

const StatusWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'status'
})<Props>(({ status }) => ({
    width: status ? '68px' : '64px',
    height: '22px',
    padding: '0px 10px',
    gap: '10px',
    textAlign: 'center',
    borderRadius: '99px',
    background: status === StatusAttendaceEnum.PRESENT ? '#D4FADE' : '#FCE3E1'
}));

const StatusAttendance: React.FC<Props> = ({ status }) => {
    return (
        <StatusWrapper status={status}>
            <Typography
                variant="caption"
                align="center"
                color={status === StatusAttendaceEnum.PRESENT ? '#09B54E' : '#F44336'}
                fontSize="13px"
            >
                {status === StatusAttendaceEnum.PRESENT ? 'Present' : 'Absent'}
            </Typography>
        </StatusWrapper>
    );
};

export default StatusAttendance;
