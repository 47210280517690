// project imports

// assets
import CloseIcon from '@mui/icons-material/Close';

import { Button, Grid, Typography, CardContent } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';

// project imports
import reliefTicketApi from 'services/api/reliefTicketApi';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    reliefTicketEdit?: ReliefTicketKecho;
}

const DeleteReliefTicletModal = (props: IProps) => {
    const { onClose, callback, reliefTicketEdit } = props;

    const handleDeleteStaff = async () => {
        if (reliefTicketEdit?.id) {
            const { data } = await reliefTicketApi.deleteReliefTicket(reliefTicketEdit?.id);
            if (data) callback();
            onClose();
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteStaff}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid container>
                <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                    <Typography variant="h4" gutterBottom component="div">
                        Are you sure you want to delete relief pool &quot;{reliefTicketEdit?.name}&quot;?
                    </Typography>
                </Grid>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteReliefTicletModal;
