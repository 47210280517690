import { PromiseApi } from 'types/kecho/api-promise';
import { IFilterRequest } from 'types/query/filter';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'notification/';

const NotificationApi = {
    list(params?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}list-notification`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    readNotification(body: any): Promise<PromiseApi> {
        const base = `${BaseApi}click-notification`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    readAllNotification(body: { idsNotification: string[] }): Promise<PromiseApi> {
        const base = `${BaseApi}read-all`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    }
};
export default NotificationApi;
